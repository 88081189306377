<template>
  <div class="model-view">
    <div class="top-bar">
      <div class="tablist">
        <div
          :class="['tab', activeTab == '1' ? 'active-tab' : '']"
          @click="changeTab('1')"
        >
          我的
        </div>
        <div
          :class="['tab', activeTab == '2' ? 'active-tab' : '']"
          @click="changeTab('2')"
        >
          项目组
        </div>
        <div
          :class="['tab', activeTab == '3' ? 'active-tab' : '']"
          @click="changeTab('3')"
        >
          公开
        </div>
      </div>
      <div class="right">
        <div class="search">
          <el-input
            v-model="search"
            placeholder="输入内容查询"
            @input="searchHandle(search)"
          />
        </div>
      </div>
    </div>
    <div v-loading="viewLoading" class="content">
      <template v-if="modelList.length > 0">
        <div
          v-for="item in modelList"
          :key="item.id"
          class="model-card"
          :style="{ background:item.id === selectModel.id ? '#eeeeee' : '#ffffff' }"
          @click="changeModel(item)"
        >
          <div
            :class="['modelBackground',item.openState === 1
              ? 'privateModelBackground'
              : item.openState === 2
                ? 'publicModelBackground'
                : 'shareModelBackground',
            ]"
          >
            <div
              class="model-icon"
            >
              {{
                item.openState === 1
                  ? "私有"
                  : item.openState === 2
                    ? "公开"
                    : "共享"
              }}
            </div>
            <div class="tag-box">
              <div
                v-for="(tag, index) in (item.label || '').split(',')"
                v-show="tag !== ''"
                :key="index"
                class="tag"
              >
                {{ tag }}
              </div>
            </div>
          </div>
          <div class="card-top">
            <div>
              <div class="title">
                <div class="title-text">{{ item.name }}</div>
                <div class="group" :style="{color:item.openState === 1? '#5A57F4' : item.openState === 2? '#165dff' : '#009463'}">
                  {{
                    groupOptions.find((i) => i.id === item.projectGroupId).name
                  }}
                </div>
              </div>

            </div>
            <div class="message">
              {{ item.description }}
            </div>
          </div>
          <div class="card-bottom">
            <span class="name">{{ item.createBy }}</span>
            <span class="time">更新时间： {{ item.createTime }}</span>
          </div>
        </div>
      </template>
      <div v-else style="text-align:center;padding-top:17px;width: 100%;">
        <img src="@/assets/icon/zanwu.png" alt="">
        <div style="padding-top:6px;color:#A8ABB2;font-size: 14px;">暂无数据</div>
      </div>

    </div>
    <div class="paginations">
      <div class="pagination">
        <div class="total">合计：{{ totalPage }}</div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          layout="sizes"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
        <div class="jumper">
          <div>{{ pageNum }}/{{ pagenumber }}</div>
          <div class="jumper-line" />
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="jumper"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          background
          layout="prev, next"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { selectModelPop, getAllProjectGroup } from '@/api/ai'
export default {
  name: 'ModelPushChooseModel',
  props: ['visible'],
  data() {
    return {
      modelList: [],
      activeTab: '1',
      groupId: '',
      groupOptions: [],
      search: '',
      timer: null,
      tagList: [],
      pageNum: 1,
      pageSize: 6,
      pageSizes: [6, 12, 24, 36, 48, 60],
      pagenumber: 0,
      totalPage: 0,
      // 选中的模型
      selectModel: {},
      viewLoading: false
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$emit('changeModel', this.selectModel)
      } else {
        this.getData()
      }
    }
  },
  created() {
    getAllProjectGroup()
      .then((groupRes) => {
        this.groupOptions = groupRes.data.content
        this.getData()
      })
  },
  methods: {
    getData() {
      this.viewLoading = true
      selectModelPop(
        this.pageNum,
        this.pageSize,
        this.search,
        this.activeTab
      ).then((res) => {
        this.viewLoading = false
        this.modelList = res.data.content.map((item) => {
          return {
            ...item,
            showMore: false
          }
        })
        this.totalPage = res.data.totalElements
        this.pagenumber =
          this.totalPage === 0 ? 1 : Math.ceil(this.totalPage / this.pageSize)
      })
    },
    changeTab(tab) {
      this.activeTab = tab
      this.getData()
    },
    handleGroupChange(val) {
      if (val) {
        this.getData()
      }
    },
    searchHandle(val) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.pageNum = 1
        this.search = val
        this.$nextTick(() => {
          this.getData()
        })
      }, 500)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    changeModel(item) {
      this.selectModel = item
    },
    clearChoose() {
      this.selectModel = {}
      this.pageNum = 1
      this.pageSize = 6
      this.search = ''
      this.activeTab = '1'
    }

  }
}
</script>

<style scoped lang="less">
.model-view {
  .top-bar {
      position: relative;
      display: flex;
      height: 68px;
      padding: 16px 24px 16px 40px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
      margin-bottom: 16px;
      .tablist {
        display: flex;
        margin-right: 40px;
        .tab {
          padding: 5px 24px 7px 24px;
          background-color: #fff;
          color: #303133;
          cursor: pointer;
          border-radius: 2px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        .active-tab {
          color: #165dff;
          background-color: #f2f3f5;
        }
      }
      .select {
        .el-select {
          width: 260px;
        }
        :deep(.el-input--suffix .el-input__inner) {
          height: 36px;
          background-color: #f2f3f5;
        }
      }
      .right {
        position: absolute;
        top: 16px;
        right: 40px;
        display: flex;
      }
      .search {
        margin-right: 40px;
        .el-input {
          width: 260px;
        }
        ::v-deep .el-input--suffix .el-input__inner {
          height: 36px;
          background-color: #f2f3f5;
        }
      }
    }
    padding-bottom: 50px;
    .content {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      padding: 0 24px;
      min-height: 300px;
      max-height: 500px;
      overflow: auto;
      .model-card {
        width: 392px;
        background: #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #dcdcdc;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          box-shadow: 2px 6px 12px 0px rgba(0, 0, 0, 0.16);
        }
        .modelBackground{
            height: 108px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 6px 7px;
            .model-icon{
              float: right;
              width: 42px;
              height: 22px;
              border-radius: 2px 2px 2px 2px;
              color: #fff;
              text-align: center;
            }
           .tag-box {
             margin-top: 16px;
             margin-bottom: 20px;
             display: flex;
             gap: 8px;
             overflow: auto;
             margin-top: 70px;

             .tag {
               background-color: #fff;
               border: 1px solid #DCDCDC;
               border-radius: 3px;
               font-weight: 400;
               font-size: 12px;
               color: #303133;
               line-height: 20px;
               padding: 0 4px;
               max-width: 160px;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
             }
           }
          }
          .publicModelBackground{
            background-image: url(../assets/publicModel.png);
            .model-icon{
              background-color: #165dff;
            }
          }
          .privateModelBackground{
            background-image: url(../assets/privateModel.png);
            .model-icon{
              background-color: #5A57F4;
            }
          }
          .shareModelBackground{
            background-image: url(../assets/sharedModel.png);
            .model-icon{
              background-color: #009463;
            }
          }
          .card-top {
          height: 120px;
          padding: 24px 16px 0 16px;

          .title {
            position: relative;
            display: flex;
            font-weight: bold;
            font-size: 16px;
            color: #303133;
            line-height: 24px;
            .title-text {
              width: 100px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .group {
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              margin-left: 16px;
            }
          }

          .message {
            // width: 352px;
            height: 66px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #606266;
            line-height: 22px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .card-bottom {
          height: 60px;
          padding: 0 24px;
          border-top: 1px solid #dcdcdc;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          .name {
            font-size: 14px;
            color: #303133;
            line-height: 22px;
          }
          .time {
            font-size: 12px;
            color: #909399;
            line-height: 20px;
          }
        }
      }
    }
    .paginations {
      padding: 24px;
      float: right;
    }
}
</style>
