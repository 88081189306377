<template>
  <div class="ai-upload-model">
    <el-form
      ref="form"
      v-loading="addFormLoading"
      :model="form"
      :rules="rules"
      label-width="8em"
      style="height: 100%;"
    >
      <div class="progress-container">
        <!-- 进度条 -->
        <div class="progress">
          <div class="progress-item-active">
            <div v-if="form.stepIndex === 0" class="progress-icon">1</div>
            <div
              v-else
              class="progress-icon"
              style="border-color: #0052d9; background-color: #fff"
            >
              <el-icon><Check /></el-icon>
            </div>
            创建模型
          </div>
          <div
            class="progress-line"
            :style="{
              backgroundColor: form.stepIndex > 0 ? '#0052D9' : '#DCDCDC',
            }"
          />
          <div
            :class="[
              form.stepIndex > 0 ? 'progress-item-active' : 'progress-item',
            ]"
          >
            <div class="progress-icon">2</div>
            选择文件
          </div>
        </div>
      </div>
      <!-- 第一步创建模型 -->
      <template v-if="form.stepIndex === 0">
        <el-form-item label="模型名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入模型名称" />
        </el-form-item>
        <el-form-item label="所属用户组" prop="groupId">
          <el-select
            v-model="form.groupId"
            placeholder="请选择"
            filterable
            @change="handleGroupChange"
          >
            <el-option
              v-for="item in groupUserOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="模型描述">
          <el-input
            v-model="form.description"
            placeholder="请输入模型描述"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="标签" class="tag-form-item">
          <el-tag
            v-for="tag in dynamicTags"
            :key="tag"
            closable
            :disable-transitions="false"
            size="large"
            @close="handleTagClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            v-if="inputVisible"
            ref="saveTagInput"
            v-model="inputValue"
            class="input-new-tag"
            size="small"
            @keyup.enter="handleInputConfirm"
            @blur="handleInputConfirm"
          />
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
          >+ 新增标签</el-button>
        </el-form-item>
        <el-form-item label="公开性" prop="openState">
          <el-radio-group ref="radio" v-model="form.openState">
            <el-radio label="1">私有</el-radio>
            <el-radio label="3">共享</el-radio>
            <el-radio label="2">公开</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="form.openState === '3'"
          label=""
          prop="modelShares"
        >
          <el-select
            v-model="form.modelShares"
            multiple
            placeholder="共享至"
          >
            <el-option
              v-for="item in groupUserOptions"
              :key="item.id"
              :label="item.name + '(' + item.nickname + ')'"
              :value="item.id"
              :disabled="item.id === form.groupId"
            />
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="模型镜像" prop="spaceName">
              <el-select
                v-model="form.spaceName"
                collapse-tags
                placeholder="请选择命名空间"
                clearable
                filterable
                @change="handleSpaceChange"
              >
                <el-option
                  v-for="item in spaceNameOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="imageName" label-width="0.5em">
              <el-select
                v-model="form.imageName"
                collapse-tags
                placeholder="请选择模型镜像"
                clearable
                filterable
                @change="handleImageChange"
              >
                <el-option
                  v-for="item in imageOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="imageVersion" label-width="0.5em">
              <el-select
                v-model="form.imageVersion"
                collapse-tags
                placeholder="请选择模型版本"
                clearable
                filterable
              >
                <el-option
                  v-for="item in versionOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="模型格式" prop="modelFormat">
          <el-select
            v-model="form.modelFormat"
            collapse-tags
            placeholder="请选择模型格式"
          >
            <el-option
              v-for="item in modelFormatOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </template>
      <!-- 第二步上传文件 -->
      <template v-if="form.stepIndex === 1">
        <el-form-item
          label=""
          prop="imageName"
          label-width="0em"
          class="is-required"
          style="height: calc(100% - 128px);"
        >
          <div class="upload-file">
            <div class="upload-box">
              <div class="choose-directory">
                <!-- <el-button @click="chooseDirectory">选择上传目录</el-button> -->
                <span
                  class="directory-info"
                >上传到：根目录/{{ uploadDirectory }}</span>
              </div>
              <el-upload
                ref="uploadFile"
                drag
                action=""
                multiple
                :file-list="fileList"
                :on-change="handleChange"
                :auto-upload="false"
              >
                <svg-icon icon-class="upload-mode" class="upload-icon" />
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
              </el-upload>
            </div>
            <div class="file-list">
              <ai-file-list ref="fileTreeList" type="dir" :model-id="modelId" :model-version-name="modelVersionName" @confirmDirs="confirmfiles" />

            </div>
          </div>

        </el-form-item>
      </template>

      <div class="drawer__footer">
        <el-button @click="cancelForm">
          {{
            form.stepIndex === 0
              ? "取消"
              : "上一步"
          }}
        </el-button>
        <el-button
          type="primary"
          :loading="addBtnLoading"
          @click="submitForm"
        >{{
          form.stepIndex === 0
            ? "下一步"
            : addBtnLoading
              ? "提交中 ..."
              : "确 定"
        }}</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getAllImageByNamespace, getArtifactVersions, uploadModelFile, deleteModel, updateModel, getAllNameSpace,
  getModelFormat, getUserProjectGroup, queryFileOrDirExist, uploadFile } from '@/api/ai'
import { ElMessage, ElMessageBox } from 'element-plus'
import AiFileList from '../components/AiFileList.vue'

export default {
  components: {
    AiFileList
  },
  data() {
    return {
      form: {
        stepIndex: 0,
        name: '',
        description: '',
        tags: [],
        openState: '1',
        modelShares: [],
        spaceName: '',
        imageName: '',
        imageVersion: '',
        modelFormat: '',
        uploadDirectory: '',
        fileList: [],
        groupId: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入模型名称', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            // 中英文、下划线、短横杠、点, 1-100个字符, 以中英文、数字开头
            const regex = /^[\u4E00-\u9FA5a-zA-Z0-9][\u4E00-\u9FA5a-zA-Z0-9_\-\.]{0,99}$/
            if (regex.test(this.form.name)) {
              return callback()
            } else if (!value) {
              return callback(new Error('输入不能为空'))
            } else if (value.length > 100) {
              return callback(new Error('模型名称长度在1-100个字符以内'))
            } else {
              return callback(new Error('模型名称只包含中英文、下划线、短横杠、点，且以中英文、数字开头'))
            }
          }, trigger: 'blur' }
        ],
        openState: [
          { required: true, message: '请选择公开性', trigger: 'change' }
        ],
        groupId: [
          {
            required: true,
            message: '请选择用户组',
            trigger: 'change'
          }
        ]
      },
      addFormLoading: false,
      addBtnLoading: false,
      inputVisible: false,
      inputValue: '',
      dynamicTags: [],
      groupUserOptions: [],
      spaceNameOptions: [],
      imageOptions: [],
      versionOptions: [],
      modelFormatOptions: [],
      uploadDirectory: '',
      fileList: [],
      groupUserOptionsCopy: [],
      modelId: '',
      modelVersionName: 'v1',
      // 创建完成
      createEND: false
    }
  },
  mounted() {
    this.addFormLoading = true
    this.form.groupId = Number(this.$store.state.ai.uploadProjectId)
    this.form.modelShares = [this.form.groupId]
    Promise.all([
      // 查命名空间
      getAllNameSpace(),
      // 查模型格式
      getModelFormat(),
      getUserProjectGroup()
    ]).then(([spaceNameRes, modelFormat, groupRes]) => {
      this.addFormLoading = false
      this.spaceNameOptions = spaceNameRes.data
      this.modelFormatOptions = modelFormat
      this.groupUserOptions = groupRes.data.content
      // 留一个groupUserOptions的副本
      this.groupUserOptionsCopy = Object.assign([], this.groupUserOptions)
    })
  },
  beforeUnmount() {
    if (!this.createEND && this.modelId) {
      deleteModel(this.modelId).then(() => {
        this.$store.commit('ai/SET_MODEL_CREATE')
      })
    }
  },
  methods: {
    handleTagClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        if (inputValue.length <= 20) {
          this.dynamicTags.push(inputValue)
          this.inputVisible = false
          this.inputValue = ''
        } else {
          return ElMessage.warning('标签长度不能超过20个字符')
        }
      } else {
        this.inputVisible = false
        this.inputValue = ''
      }
    },
    handleChange(file, fileList) {
      this.fileList = fileList
      this.uploadFile(fileList)
    },
    uploadFile(fileList) {
      if (fileList.length === 0) {
        return
      }
      // 未上传的文件
      const notUploadFiles = fileList.filter((file) => {
        return file.status !== 'success'
      })
      this.fileList.forEach((file) => {
        file.status = 'success'
      })
      if (notUploadFiles.length === 0) {
        return
      }
      this.addFormLoading = true
      this.uploadLoading = true
      this.concurRequest(notUploadFiles, 1).then((res) => {
        this.uploadLoading = false
        this.addFormLoading = false
        this.$refs.fileTreeList.refreshTreeNode('')
      })
    },
    concurRequest(files, maxNum) {
      return new Promise(resolve => {
        if (files.length === 0) {
          resolve([])
          return
        }
        const results = []
        let index = 0
        const that = this
        async function request() {
          if (index >= files.length) {
            return
          }
          const i = index
          const file = files[index]
          index++
          const params = {
            modelId: that.modelId,
            modelVersionName: that.modelVersionName,
            dirPath: that.uploadDirectory + '/' + file.name
          }
          const exist = await queryFileOrDirExist(params)
          if (exist.data) {
            // ElMessageBox打开后查找el-overlay is-message-box元素，只保留z-index最小的元素

            const overlay = document.querySelector('.el-overlay.is-message-box')
            if (overlay) {
              that.$nextTick(() => {
                const overlays = document.getElementsByClassName('el-overlay is-message-box')
                console.log(overlays, 'overlays')
                // 删除除z-index最小的元素外的所有元素
                for (let i = 0; i < overlays.length; i++) {
                  if (overlays[i] !== overlay) {
                    overlays[i].style.backgroundColor = 'rgba(0,0,0,0)'
                  }
                }
              })
            }

            ElMessageBox.confirm(file.name + '文件已存在，是否覆盖？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '跳过'
            }).then(async() => {
              const files = that.fileList.filter(item => item.name === file.name)
              if (files.length > 1) {
                const index = that.fileList.findIndex(item => item.name === file.name)
                that.fileList.splice(index, 1)
              }
              const formData = new FormData()
              formData.append('file', file.raw)
              const data = {
                modelId: that.modelId,
                modelVersionName: that.modelVersionName,
                dirPath: that.uploadDirectory
              }
              const resp = await uploadFile(formData, data)
              results[i] = resp
            }).catch(() => {
              // 从fileList中找到uid === file.uid的对象，将其删掉
              const index = that.fileList.findIndex(item => item.uid === file.uid)
              that.fileList.splice(index, 1)
            })
          } else {
            const formData = new FormData()
            formData.append('file', file.raw)
            const data = {
              modelId: that.modelId,
              modelVersionName: that.modelVersionName,
              dirPath: that.uploadDirectory
            }
            const resp = await uploadFile(formData, data)
            results[i] = resp
          }
          // results的长度等于files的长度并且所有值都不为空时，才resolve。转换成json判断
          const jsonResults = JSON.parse(JSON.stringify(results))
          // 判断长度并且不为空字符串
          if (jsonResults.every(item => item !== '' && item !== null)) {
            resolve(results)
          }
          request()
        }
        for (let index = 0; index < maxNum; index++) {
          request()
        }
      })
    },
    // 命名空间变化时
    handleSpaceChange(val) {
      this.versionOptions = []
      this.imageOptions = []
      this.form.imageName = ''
      this.form.imageVersion = ''
      if (val) {
        // 查镜像

        this.addFormLoading = true
        getAllImageByNamespace(val).then((res) => {
          this.imageOptions = res.data
          this.addFormLoading = false
        })
      } else {
        this.imageOptions = []
      }
    },
    // 镜像变化时
    handleImageChange(val) {
      this.versionOptions = []
      this.form.imageVersion = ''
      if (val) {
        // 查版本
        this.addFormLoading = true

        getArtifactVersions('', '', this.form.spaceName + '/' + val).then(
          (res) => {
            this.versionOptions = res.data
            this.addFormLoading = false
          }
        )
      } else {
        this.versionOptions = []
      }
    },
    // 选择目录
    confirmfiles(dir) {
      this.uploadDirectory = dir
    },
    // 提交上传模型
    submitForm() {
      /** 如果 stepIndex为0则创建模型执行下一步*/
      if (this.form.stepIndex === 0 && !this.modelId) {
        this.createModel()
      } else if (this.form.stepIndex === 0 && this.modelId) {
        this.updateModel()
      } else if (this.form.stepIndex === 1) {
        // 判断是否有文件
        if (this.fileList.length === 0) {
          return ElMessage.warning('请上传文件')
        }
        this.createEND = true
        this.$store.commit('ai/SET_MODEL_CREATE')
        this.$store.dispatch('core/close', 2154413087280450)
      }
    },
    createModel() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 创建模型
          this.addBtnLoading = true
          this.addFormLoading = true
          const data = {
            name: this.form.name,
            description: this.form.description,
            openState: this.form.openState,
            imageRepository: this.form.spaceName,
            imageName: this.form.imageName,
            imageVersion: this.form.imageVersion,
            modelFormat: this.form.modelFormat,
            projectGroupId: this.form.groupId
          }
          const params = {
            labels: this.dynamicTags.join(','),
            modelShares: this.form.modelShares.join(',')
          }
          uploadModelFile(data, params).then((res) => {
            this.modelId = res.data.modelId
            this.modelVersionName = res.data.modelVersionName
            this.form.stepIndex = 1
          }).finally(() => {
            this.addBtnLoading = false
            this.addFormLoading = false
          })
        }
      })
    },
    updateModel() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 创建模型
          this.addBtnLoading = true
          this.addFormLoading = true
          const data = {
            name: this.form.name,
            description: this.form.description,
            openState: this.form.openState,
            imageRepository: this.form.spaceName,
            imageName: this.form.imageName,
            imageVersion: this.form.imageVersion,
            modelFormat: this.form.modelFormat,
            projectGroupId: this.form.groupId,
            id: this.modelId
          }
          const params = {
            labels: this.dynamicTags.join(','),
            modelShares: this.form.modelShares.join(',')
          }
          updateModel(data, params).then((res) => {
            this.form.stepIndex = 1
          }).finally(() => {
            this.addBtnLoading = false
            this.addFormLoading = false
          })
        }
      })
    },
    cancelForm() {
      if (this.form.stepIndex === 1) {
        this.form.stepIndex = 0
      } else {
        this.$store.dispatch('core/close', 2154413087280450)
      }
      // this.imageOptions = []
      // this.versionOptions = []
      // this.form = {
      //   name: '',
      //   description: '',
      //   openState: '1',
      //   modelShares: [],
      //   spaceName: '',
      //   imageName: '',
      //   modelFormat: '',
      //   stepIndex: 0
      // }
      // this.currentGroupId = ''
      // this.dynamicTags = []
      // this.fileList = []
      // this.uploadDirectory = ''
      // this.$refs.form.resetFields()
      this.addFormLoading = false
      this.addBtnLoading = false
    },
    handleGroupChange() {
      this.form.modelShares = [this.form.groupId]
    }
  }
}
</script>

<style scoped lang="less">
  .ai-upload-model {
    padding: 16px;
    min-width: 711px;
    height: 100%;
    .el-select {
      flex:1
    }
    :deep(.el-upload) {
      width: 100%;
    }
    .progress-container{
      display: flex;
      justify-content: center;
      margin-bottom: 41px;
    }
    .progress {
      display: flex;
      align-items: center;
      .progress-item-active {
        color: rgba(0, 0, 0, 0.9);
        .progress-icon {
          display: inline-block;
          margin-right: 16px;
          border-radius: 50%;
          line-height: 24px;
          text-align: center;
          color: #fff;
          width: 24px;
          height: 24px;
          background: #0052d9;
          border: 1px solid #0052d9;
          .el-icon {
            transform: translateX(-2px) translateY(-2px);
            color: #0052d9 !important;
          }
        }
      }
      .progress-item {
        color: rgba(0, 0, 0, 0.4);
        .progress-icon {
          display: inline-block;
          margin-right: 16px;
          border-radius: 50%;
          line-height: 24px;
          text-align: center;
          color: rgba(0, 0, 0, 0.4);
          width: 24px;
          height: 24px;
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.4);
        }
      }
      .progress-line {
        margin: 0 16px;
        width: 164px;
        height: 2px;
        background: #dcdcdc;
      }
    }
    :deep(.el-dialog) {
      max-height: 800px;
    }
    .el-tag {
      margin-right: 10px;
      background-color: #fff;
      border: 1px solid #DCDCDC;
      line-height: 5;
      :deep(.el-tag__close){
        &:hover{
          background-color: #fff;
        }
      }
    }
    .button-new-tag {
      margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }
    .choose-directory {
      height: 120px;
      padding: 12px 10px;
      .directory-info {
        font-weight: 400;
        font-size: 14px;
        color: #606266;
        line-height: 22px;
        margin-left: 16px;
      }
    }
    .upload-file{
      display: flex;
      width: 100%;
      height: 100%;
      gap:16px;
    }
    .upload-box {
      border: 1px dashed #dcdcdc;
      border-radius: 6px;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .file-list {
      height: 100%;
      flex: 1;
      border: 1px dashed #DCDCDC;
     :deep(.choice-file){
        height: 100%;
        .dialog-container{
           height: 100%;
        }
        .choice-dialog-content{
          height: 100%;
          .left{
            height: 100%;
          }
        }
      }
    }

    :deep(.el-upload-dragger) {
      border: none;
      border-radius: 0 0 6px 6px;
      width: 100%;
      // aspect-ratio: 1 / 1;
      // aspect-ratio: 1 / 0.3;
      height: auto;

      // min-height: 275px;
      .upload-icon {
        width: 52px;
        height: 68px;
      }
    }
    :deep(.el-upload-list){
      .el-icon--close{
        display: none;
      }
    }
    .drawer__footer {
      text-align: right;
    }
    :deep(.el-button--default) {
      color: #4e5969;
      background-color: #f2f3f5;
      border: none;
    }
    :deep(.el-button--primary) {
      color: #fff;
    }
    :deep(.left){
      min-height: 360px;
    }
  }
</style>
