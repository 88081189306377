<template>
  <div v-loading="isDialogLoading" class="dev-environment-dialog">
    <div class="tabs">
      <div
        v-for="item in tabList"
        :key="item.id"
        class="tab-box"
        :class="{ active: item.id === activeColor }"
        @click="tabsChange(item.id, item)"
      >{{ item.name }}</div>
    </div>
    <!-- shell -->
    <div v-show="activeColor == 0" class="base-style shell">
      <div class="shell-list">
        <div
          v-for="(item, i) in sshList"
          :key="i"
          class="shell-box"
          :class="{ activeSsh: i === activeSshColor }"
        ><el-tooltip :content="item.podName" placement="top" effect="light">
          <div v-if="item.status=='true'" @click="sshChange(i, item)">{{ item.podName }}</div>
          <div v-else class="shell-disabled">
            {{ item.podName }}
          </div>
        </el-tooltip>
        </div>
      </div>
      <div v-loading="terminalLoading" class="terminal-box" style="flex: 1;">
        <div id="xtermAi" class="xtermAi" style="width: 100%; height: 100%;" @contextmenu.prevent="rightShow">
          <div v-if="waitLoading" class="wait-loading">
            <div style="text-align:center;padding-top:100px">
              <img src="@/assets/icon/terminal-loading.png" alt="">
              <div style="padding-top:10px">等待连接...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 容器实例 -->
    <div v-show="activeColor == 1" class="content">
      <ai-box :title="'容器列表'" :is-title="isTitle" class="content-box">
        <div class="container">
          <div class="container-box">
            <el-table
              :data="containerData"
              style="width: 100%; height: auto"
            >
              <el-table-column prop="podName" label="名称" show-overflow-tooltip />
              <el-table-column
                prop="status"
                label="状态"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <div
                    v-if="scope.row.status === 'true'"
                    style="color: #165DFF"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                  <div
                    v-else
                    style="color: #00A870"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="hostIP"
                label="节点IP"
                show-overflow-tooltip
              />
              <el-table-column
                prop="podIP"
                label="容器IP"
                show-overflow-tooltip
              />
            </el-table>
            <div class="pagination">
              <div class="total">合计：{{ totalPage }}</div>
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                layout="sizes"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
              <div class="jumper">
                <div>{{ pageNum }}/{{ pagenumber }}</div>
                <div class="jumper-line" />
                <el-pagination
                  v-model:currentPage="pageNum"
                  :page-size="pageSize"
                  :page-count="pagenumber"
                  :page-sizes="pageSizes"
                  background
                  layout="jumper"
                  :total="totalPage"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                />
              </div>
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                background
                layout="prev, next"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
      </ai-box>
      <ai-box :title="'CPU利用率'" :is-title="isTitle" class="content-box line-charts">
        <div v-if="cpuChartData.length !== 0">
          <div id="lineEcharts" style="width:968px;height:200px;" />
        </div>
        <div v-else>
          <div style="text-align:center;padding-top:17px">
            <img src="@/assets/icon/charts-no-data.png" alt="">
            <div style="padding-top:6px;color:#A8ABB2;font-size: 14px;">暂无数据</div>
          </div>
        </div>
      </ai-box>
      <ai-box :title="'内存利用率'" :is-title="isTitle" class="content-box line-charts">
        <div v-if="memChartData.length !== 0">
          <div id="lineEcharts1" style="width:968px;height:200px;" />
        </div>
        <div v-else>
          <div style="text-align:center;padding-top:17px">
            <img src="@/assets/icon/charts-no-data.png" alt="">
            <div style="padding-top:6px;color:#A8ABB2;font-size: 14px;">暂无数据</div>
          </div>
        </div>
      </ai-box>
    </div>
    <!-- 基本信息 -->
    <div v-show="activeColor == 2" class="basic-info">
      <el-form ref="basicForm" :rules="basicRules" :model="basicForm" size="small" label-width="7em">
        <el-row>
          <el-col :span="12" class="left-col">
            <el-form-item prop="name" label="环境名称">
              <el-input v-model="basicForm.name" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="createTime" label="创建时间">
              <el-input v-model="basicForm.createTime" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="left-col">
            <el-form-item prop="image" label="镜像">
              <el-input v-model="basicForm.image" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="shmSize" label="shm_size">
              <el-input v-model="basicForm.shmSize" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="left-col">
            <el-form-item prop="src" label="资源组">
              <el-input v-model="basicForm.src" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="cpu" label="CPU">
              <el-input v-model="basicForm.cpu" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="left-col">
            <el-form-item prop="state" label="状态">
              <el-input v-model="basicForm.state" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="copyNum" label="副本数">
              <el-input v-model="basicForm.copyNum" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 容器日志 -->
    <div v-show="activeColor == 3" class="shell basic-log" style="min-height: 500px">
      <div class="shell-list">
        <div
          v-for="(item, i) in sshList"
          :key="i"
          class="shell-box"
          :class="{ activeSsh: i === activeLogColor }"
          @click="changeLog(item,i)"
        ><el-tooltip :content="item.podName" placement="top" effect="light">
          <div>{{ item.podName }}</div>
        </el-tooltip>
        </div>
      </div>
      <div v-loading="terminalLoading" class="terminal-box" style="flex:1">
        <AiDevLog ref="devLog" name-space="ai-model-publish-namespace" :name="currentName" />
      </div>
    </div>
    <!-- 详情 -->
    <div v-show="activeColor == 4" class="base-style detail">
      <div class="shell-list">
        <div
          v-for="(item, i) in sshList"
          :key="i"
          class="shell-box"
          :class="{ activeSsh: i === activeDetailColor }"
          @click="changeDetail(item,i)"
        ><el-tooltip :content="item.podName" placement="top" effect="light">
          <div>{{ item.podName }}</div>
        </el-tooltip>
        </div>
      </div>
      <div class="detail-box">
        <div v-if="detailtotalPage !== 0" class="content">
          <el-table
            v-loading="isLoading"
            :data="detailTable"
            tooltip-effect="dark"
            @sort-change="sortChange"
          >
            <el-table-column
              label="Type"
              prop="type"
              show-overflow-tooltip
            />
            <el-table-column
              label="Reason"
              prop="reason"
              show-overflow-tooltip
            />
            <el-table-column
              label="Age"
              prop="age"
              show-overflow-tooltip
            />
            <el-table-column
              label="Message"
              prop="message"
            >
              <template #default="scope">
                <el-tooltip placement="top">
                  <template #content>  {{ scope.row.message }} </template>
                  <div class="detail-message">
                    {{ scope.row.message }}
                  </div>
                </el-tooltip>
                <!--  -->

              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <div class="total">合计：{{ detailtotalPage }}</div>
            <el-pagination
              v-model:currentPage="detailPageNum"
              :page-size="detailPageSize"
              :page-count="detailPageNumber"
              :page-sizes="detailPageSizes"
              layout="sizes"
              :total="detailtotalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <div class="jumper">
              <div>{{ detailPageNum }}/{{ detailPageNumber }}</div>
              <div class="jumper-line" />
              <el-pagination
                v-model:currentPage="detailPageNum"
                :page-size="detailPageSize"
                :page-count="detailPageNumber"
                :page-sizes="detailPageSizes"
                background
                layout="jumper"
                :total="detailtotalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
            <el-pagination
              v-model:currentPage="detailPageNum"
              :page-size="detailPageSize"
              :page-count="detailPageNumber"
              :page-sizes="detailPageSizes"
              background
              layout="prev, next"
              :total="detailtotalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>

        </div>
        <div v-else>
          <div style="text-align:center;padding-top:17px">
            <img src="@/assets/icon/charts-no-data.png" alt="">
            <div style="padding-top:6px;color:#A8ABB2;font-size: 14px;">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="activeColor == 5" class="push-detail">
      <div class="header-api-info">
        <!-- <svg-icon v-if="status==='active'" icon-class="success-icon" class="icon" /> -->
        <span style="color: #303133;margin-right: 8px" class="status">发布状态：</span>
        <span style="color: #303133;margin-right: 40px" class="status">{{ status }}</span>
        <span style="margin-right: 40px;">API接口访问次数<span style="color: #5e8fff;margin: 0 4px;">{{ publishApiInfo.totalReq }}</span>次</span>
        <span>请求成功次数<span style="color: #46bf97;margin: 0 4px;">{{ publishApiInfo.successReq }}</span>次</span>
        <span>请求失败次数<span style="color: #f24f5d;margin: 0 4px;">{{ publishApiInfo.failReq }}</span>次</span>
      </div>
      <div class="model-box">
        <div class="title">
          API接口信息
        </div>
        <div class="header-btn-box">
          <el-button type="primary" size="small" :loading="editBtnLoading" @click="editApiInfo">{{ isEdit? '保存' :mode ==='add'? '新增' : '编辑' }}</el-button>
        </div>
        <div class="api-info">
          <el-form label-width="100px" :model="form" :rules="rules">
            <el-form-item label="端口">
              <div class="port-box">
                <el-select v-model="currentPort" size="small" placeholder="请选择" @change="changePort">
                  <el-option
                    v-for="item in portOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </div>
            </el-form-item>
            <template v-if="mode==='add'&&!isEdit">
              <div style="text-align:center;padding:150px 0">
                <img src="@/assets/icon/charts-no-data.png" alt="">
                <div style="padding-top:6px;color:#A8ABB2;font-size: 14px;">暂无数据</div>
              </div>
            </template>
            <template v-else>
              <el-form-item label="接口url">
                <div class="url-box">
                  <!-- <div class="value">{{ url }}</div>
                  <div class="input-box">
                    <el-input v-if="isEdit" v-model="form.url" size="small" />
                    <span v-else>{{ form.url }}</span>
                  </div> -->
                  <el-input v-if="isEdit" v-model="form.url" size="small">
                    <template #prepend>{{ url }}</template>
                  </el-input>
                  <a v-else class="value" @click="copyUrl(url + form.url)">{{ url + form.url }}</a>
                </div>

              </el-form-item>
              <el-form-item label="请求方式">
                <el-select v-if="isEdit" v-model="form.method" size="small" placeholder="请选择">
                  <el-option
                    v-for="item in requestMethods"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
                <div v-else>{{ form.method }}</div>
              </el-form-item>
              <el-form-item label="请求参数">
                <div class="table">
                  <el-table
                    :data="form.requestParams"
                    border
                    style="width: 100%"
                    @row-contextmenu="rightParamsTableClick"
                  >
                    <el-table-column
                      prop="name"
                      label="参数名称"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.name" size="small" placeholder="参数名称" />
                        <div v-else>{{ scope.row.name }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="type"
                      label="参数类型"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.type" size="small" placeholder="参数类型" />
                        <div v-else>{{ scope.row.type }}</div>
                      </template>

                    </el-table-column>
                    <el-table-column
                      prop="isRequired"
                      label="是否必填"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.isRequired" size="small" placeholder="是否必填" />
                        <div v-else>{{ scope.row.isRequired }}</div>
                      </template>

                    </el-table-column>
                    <el-table-column
                      prop="description"
                      label="描述"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.description" size="small" placeholder="描述" />
                        <div v-else>{{ scope.row.description }}</div>
                      </template>

                    </el-table-column>
                  </el-table>
                  <div v-if="isEdit" class="btn-box">
                    <span class="" @click="addParam"><el-icon><CirclePlus /></el-icon>
                    </span>
                  </div>
                </div></el-form-item>
              <el-form-item label="请求体">
                <el-radio-group v-model="form.requestBodyType" size="small">
                  <el-radio label="form-data">form-data</el-radio>
                  <el-radio label="json">json</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="form.requestBodyType === 'form-data'" label="">
                <div class="table">
                  <el-table
                    :data="form.requestBody"
                    border
                    style="width: 100%"
                    @row-contextmenu="rightParamsTableClick"
                  >
                    <el-table-column
                      prop="name"
                      label="参数名称"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.name" size="small" placeholder="参数名称" />
                        <div v-else>{{ scope.row.name }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="type"
                      label="参数类型"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.type" size="small" placeholder="参数类型" />
                        <div v-else>{{ scope.row.type }}</div>
                      </template>

                    </el-table-column>
                    <el-table-column
                      prop="isRequired"
                      label="是否必填"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.isRequired" size="small" placeholder="是否必填" />
                        <div v-else>{{ scope.row.isRequired }}</div>
                      </template>

                    </el-table-column>
                    <el-table-column
                      prop="description"
                      label="描述"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.description" size="small" placeholder="描述" />
                        <div v-else>{{ scope.row.description }}</div>
                      </template>

                    </el-table-column>
                  </el-table>
                  <div v-if="isEdit" class="btn-box">
                    <!-- <i class="el-icon-circle-plus-outline add-svg" @click="addBody" /> -->
                    <span @click="addBody"><el-icon><CirclePlus /></el-icon></span>

                  </div>
                </div>
              </el-form-item>
              <el-form-item v-if="form.requestBodyType === 'json'" label="">
                <el-input v-if="isEdit" v-model="form.requestJsonBody" size="small" placeholder="请输入json格式请求体" type="textarea" />
                <div v-else class="response-box">
                  {{ form.requestJsonBody }}
                </div>
              </el-form-item>
              <el-form-item label="请求头">
                <div class="table">
                  <el-table
                    :data="form.requestHeaders"
                    border
                    style="width: 100%"
                    :header-row-style="{backgroundColor: '#ffffff'}"
                    @row-contextmenu="rightParamsTableClick"
                  >
                    <el-table-column
                      prop="key"
                      label="key"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.key" size="small" placeholder="key" />
                        <div v-else>{{ scope.row.key }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="value"
                      label="value"
                    >
                      <template #default="scope">
                        <el-input v-if="isEdit" v-model="scope.row.value" size="small" placeholder="value" />
                        <div v-else>{{ scope.row.value }}</div>
                      </template>

                    </el-table-column>
                  </el-table>
                  <div v-if="isEdit" class="btn-box">
                    <span @click="addHeader"><el-icon><CirclePlus /></el-icon></span>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="响应示例">
                <el-input v-if="isEdit" v-model="form.responseData" size="small" placeholder="请输入json格式请求体" type="textarea" />

                <div v-else class="response-box">
                  {{ form.responseData }}
                </div>
              </el-form-item>
            </template>
          </el-form>
          <!-- 右键 -->
          <div
            v-show="menuVisible"
            id="contextmenu"
            class="menu"
          >
            <div
              class="contextmenu-item"
              @click="delTableColum(currentRow)"
            >
              <i class="el-icon-delete" />
              删除</div>
            <div
              class="contextmenu-item"
              @click="insertTableColum(currentRow,'up')"
            >
              <i class="el-icon-delete" />
              在上方插入一行</div>
            <div
              class="contextmenu-item"
              @click="insertTableColum(currentRow,'down')"
            >
              <i class="el-icon-delete" />
              在下方插入一行</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'xterm/css/xterm.css'
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import { AttachAddon } from 'xterm-addon-attach'
import 'xterm/css/xterm.css'
import 'xterm/lib/xterm.js'
import aiBox from '@/components/AiBox.vue'
// import { getPodEventList, getModelPublishDetail, getModelPublishPod,getModelPublishDetail, getDevlopmentenvSrcDeatail } from '@/api/ai'
import { getModelPublishPod, getModelPublishDetail, getModelPublishSrcDeatail,
  getModelPublishEventList, getModelPublishServicePorts, getModelPublishApiList,
  getModelPublishApiReq, createModelPublishApi, patchModelPublishApi } from '@/api/ai'
import * as echarts from 'echarts'

import AiDevLog from '@/components/AiDevLog.vue'
export default {
  name: 'ModelPushDetailDialog',
  components: { aiBox, AiDevLog },
  props: ['row', 'dialogVisible'],
  data() {
    return {
      isDialogLoading: false,
      // terminal
      waitLoading: false,
      terminalLoading: false,
      vncSwitch: false,
      term: null,
      socket: null,
      fitAddon: '',
      ws: {},
      baseUrl: 'wss://192.168.132.234:9090/slurm/log?path=',
      //
      activeSshColor: 0,
      sshList: [],
      activeColor: 5,
      tabList: [
        {
          name: '模型发布结果',
          id: 5
        },
        {
          name: 'shell',
          id: 0
        },
        {
          name: '容器实例',
          id: 1
        },
        {
          name: '容器日志',
          id: 3
        },
        {
          name: '事件',
          id: 4
        }
      ],
      isTitle: true,
      pageSize: 2,
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [2, 3, 5, 10, 20],
      containerData: [],
      basicForm: {
        name: 'mlk001dev',
        image: '10.31.118.167:5000/tensorflow/tensorflow:20.09-py3-cuda11',
        state: '运行中',
        src: 'emergency',
        cpu: '16核',
        shmSize: '4GB',
        copyNum: '2',
        createTime: '2023-09-04  10:20:25'
      },
      basicRules: {
        name: [{ required: true, trigger: 'blur', message: '请输入环境名称' }],
        createTime: [{ required: true, trigger: 'blur', message: '请输入创建时间' }],
        image: [{ required: true, trigger: 'change', message: '请选择镜像' }],
        state: [{ required: true, trigger: 'change', message: '请选择状态' }],
        src: [{ required: true, trigger: 'change', message: '请选择资源组' }],
        cpu: [{ required: true, trigger: 'change', message: '请选择CPU/GPU' }],
        shmSize: [{ required: true, trigger: 'blur', message: '请输入shm-size' }],
        copyNum: [{ required: true, trigger: 'blur', message: '请输入副本数' }]
      },
      podName: '',
      // 图表
      charts: null,
      charts1: null,
      charts2: null,
      cpuChartData: [],
      memChartData: [],
      dateData: [],
      dateData1: [],
      lineData: [],
      lineData1: [],
      lineData2: [],
      seriesData: [],
      seriesData1: [],
      detailTable: [],
      isLoading: false,
      detailPageNum: 1,
      detailPageSize: 10,
      detailPageSizes: [10, 20, 30, 40, 50],
      detailtotalPage: 0,
      detailSortby: '',
      detailOrderBy: '',
      detailPageNumber: 0,
      activeDetailColor: 0,
      currentName: '',
      activeLogColor: 0,
      apiBaseUrl: '',
      portOptions: [],
      currentPort: '',
      requestBody: '',
      requestMethods: [
        'GET',
        'POST',
        'DELETE',
        'PUT',
        'HEAD',
        'PATCH',
        'OPTIONS'
      ],
      form: {
        url: '',
        method: 'GET',
        requestParams: [
          {
            name: '',
            type: '',
            isRequired: '',
            description: '',
            form: 'requestParams'

          }
        ],
        requestBody: [
          {
            name: '',
            type: '',
            isRequired: '',
            description: '',
            form: 'requestBody'
          }
        ],
        requestHeaders: [
          {
            key: '',
            value: '',
            form: 'requestHeaders'
          }
        ],
        requestBodyType: 'form-data',
        requestJsonBody: '',
        responseData: ''
      },
      rules: {},
      menuVisible: false,
      isEdit: false,
      // 新增还是编辑 add|edit
      mode: '',
      editBtnLoading: false,
      apiBoxLoading: true,
      publishApiInfo: {
        failReq: 0,
        successReq: 0,
        totalReq: 0
      },
      id: '',
      name: '',
      modelVersionName: '',
      status: ''
    }
  },
  computed: {
    url() {
      return this.baseUrl + this.currentPort + '/'
    }
  },
  watch: {
    dialogVisible: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.closeSsh()
        } else {
          this.activeColor = 5
          this.activeSshColor = 0
          this.containerData = []
          this.charts = null
          this.charts1 = null
          this.charts2 = null
          this.cpuChartData = []
          this.memChartData = []
          this.dateData = []
          this.dateData1 = []
          this.lineData = []
          this.lineData1 = []
          this.lineData2 = []
          this.seriesData = []
          this.seriesData1 = []

          this.getData()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getData()
  },
  beforeUnmount() {
    this.closeSsh()
  },
  methods: {
    copyUrl(url) {
      const input = document.createElement('input')
      input.value = url
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$message.success('复制成功')
    },
    // 初始化赋值
    getData() {
      this.basicForm.name = this.row.envName
      this.basicForm.image = this.row.imageName
      this.basicForm.cpu = this.row.cpuLimit
      this.basicForm.createTime = this.row.creationTime
      this.basicForm.copyNum = this.row.replicasTotal
      this.basicForm.state = this.row.status
      this.id = this.row.id
      this.name = this.row.name
      this.modelVersionName = this.row.modelVersionName
      this.status = this.row.status
      this.isEdit = false
      this.getSshList()
      this.getModelPublishServicePorts()
    },
    // 容器实例
    getModelPublishDetail() {
      const data = {
        name: this.row.name,
        pagenum: this.pageNum,
        pagesize: this.pageSize
      }
      getModelPublishDetail(data).then((response) => {
        this.containerData = response.data.content
        this.pagenumber = response.data.totalpages
        this.totalPage = response.data.totalelements
        this.isDialogLoading = false
      }).catch(() => {
        this.isDialogLoading = false
      })
    },
    // ssh
    getSshList() {
      return new Promise((resolve, reject) => {
        this.isDialogLoading = true
        getModelPublishPod(this.row.name).then((response) => {
          this.sshList = response.data
          this.isDialogLoading = false
          if (this.sshList.length !== 0 && this.sshList[0].status == 'true') {
            resolve()
            this.podName = this.sshList[0].podName
          }
        })
      })
    },
    closeSsh() {
      try {
        this.socket.close()
        this.term.dispose()
      } catch (error) {
        console.log(error)
      }
    },
    // 切换ssh
    sshChange(i, item) {
      this.podName = item.podName
      this.isDialogLoading = true
      this.closeSsh()
      this.activeSshColor = i
      this.$nextTick(() => {
        this.connectHandle()
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.isDialogLoading = true
      this.getModelPublishDetail()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.isDialogLoading = true
      this.getModelPublishDetail()
    },
    // 切换tab
    tabsChange(i, item) {
      this.activeColor = i
      this.pageNum = 1
      if (i === 1) {
        this.isDialogLoading = true
        this.getModelPublishDetail()
        this.$nextTick(() => {
          this.getLineData()
        })
      }
      if (i === 0) {
        this.closeSsh()
        this.activeSshColor = i
        this.$nextTick(() => {
          this.getSshList().then(() => {
            this.connectHandle()
          })
        })
      }
      if (i === 3) {
        if (this.sshList.length === 0) {
          return
        }
        this.activeLogColor = 0
        this.currentName = this.sshList[0].podName
        this.$nextTick(() => {
          this.$refs.devLog.clear()
          this.$refs.devLog.initLog()
        })
      }
      if (i == 4) {
        if (this.sshList.length === 0) {
          return
        }
        this.activeDetailColor = 0
        const envName = this.sshList[0].podName
        this.getPodEventList(envName)
      }
      if (i == 5) {
        //
      }
    },
    changeLog(item, i) {
      this.activeLogColor = i
      this.currentName = item.podName
      this.$nextTick(() => {
        this.$refs.devLog.clear()
        this.$refs.devLog.initLog()
      })
    },
    changeDetail(item, i) {
      this.activeDetailColor = i
      this.getPodEventList(item.podName)
    },
    getPodEventList(envName) {
      this.isLoading = true
      getModelPublishEventList(envName, this.detailPageNum, this.detailPageSize, this.detailSortby).then(res => {
        this.isLoading = false
        this.detailTable = res.data.content
        this.detailtotalPage = res.data.totalelements
        this.detailPageNumber = res.data.totalpages
        console.log(this.detailTable, this.detailtotalPage, 'this.detailTable')
      })
    },
    async connectHandle() {
      if (this.vncSwitch) {
        this.socketClose()
        this.term.dispose()
      }
      const Base64 = require('js-base64').Base64
      const connectInfo = {
        name: this.podName,
        namespace: 'ai-model-publish-namespace',
        regionId: window.localStorage.regionId
      }
      const connect = Base64.encode(JSON.stringify(connectInfo))
      const token = sessionStorage.desktopToken
      var url
      var ishttps = document.location.protocol == 'https:'
      if (ishttps) {
        url = 'wss:' + location.host + '/aisocket/ws/container/terminal/?connectInfo=' + connect + '&token=' + token
      } else {
        url = 'ws:' + location.host + '/aisocket/ws/container/terminal/?connectInfo=' + connect + '&token=' + token
      }
      this.default = url
      await this.initSocket()
    },
    initTerm() {
      const term = new Terminal({
        scrollback: 500,
        fontSize: 14,
        row: 500,
        cursorBlink: true,
        rendererType: 'canvas',
        // 启用时，光标将设置为下一行的开头
        convertEol: false,
        theme: {
          cursor: 'help' // 设置光标
        }
      })
      const attachAddon = new AttachAddon(this.socket)
      const fitAddon = new FitAddon()
      term.loadAddon(attachAddon)
      term.loadAddon(fitAddon)
      term.open(document.getElementById('xtermAi'))
      this.fitAddon = fitAddon
      this.fitAddon.fit()
      term.focus()
      term.attachCustomKeyEventHandler(e => {
        // console.log('listen press key ' + e.keyCode)
        if (e.ctrlKey && e.keyCode === 76) {
          term.clear()
        }
      })
      // 鼠标选中 是复制
      term.onSelectionChange(e => {
        if (term.hasSelection()) {
          if (window.isSecureContext) {
            navigator.clipboard.writeText(term.getSelection())
          } else {
            this.textArea = document.createElement('textarea')
            this.textArea.style.position = 'absolute'
            this.textArea.style.opacity = 0
            this.textArea.style.left = '-999999px'
            this.textArea.style.top = '-999999px'
            document.body.appendChild(this.textArea)
            this.textArea.textContent = term.getSelection()
            // 选择
            this.textArea.focus()
            this.textArea.select()
            // 复制
            document.execCommand && document.execCommand('copy')
          }
        }
      })
      term.onData(cmd => {
      })
      // 限制和后端交互，只有输入回车键才显示结果
      term.prompt = () => {
        term.write('\r\n$ ')
      }

      term.prompt()
      this.term = term
    },
    // xterm右键
    rightShow() {
      if (this.socket !== null) {
        if (window.isSecureContext) {
          navigator.clipboard.readText()
            .then(text => {
              this.socket.send(text)
            })
        } else {
          this.socket.send(this.textArea.textContent)
        }
      }
    },
    initSocket() {
      this.socket = new WebSocket(this.default)
      this.$nextTick(() => {
        this.waitLoading = false
        this.isDialogLoading = false
      })
      this.socketOnClose()
      this.socketOnOpen()
      this.socketOnError()
    },
    socketOnMessage() {
      this.socket.onmessage = (res) => {
        console.log(res)
      }
    },
    socketOnOpen() {
      this.socket.onopen = () => {
        // 链接成功后
        this.vncSwitch = true
        this.initTerm()
      }
    },
    socketClose() {
      this.vncSwitch = false
      this.socket.close()
    },
    socketOnClose() {
      this.socket.onclose = () => {
        console.log('close socket')
      }
    },
    socketOnError() {
      this.vncSwitch = false
      this.socket.onerror = () => {
        console.log('socket 连接失败')
      }
    },
    // 折线图表
    async getLineData() {
      this.dateData = []
      this.dateData1 = []
      this.lineData = []
      this.lineData1 = []
      this.seriesData = []
      this.seriesData1 = []
      await getModelPublishSrcDeatail(this.row.name).then(response => {
        this.cpuChartData = response.data.cpuHist
        this.memChartData = response.data.memHist
        if (this.cpuChartData.length !== 0) {
          this.cpuChartData[this.cpuChartData.length - 1].histData.forEach((e, i) => {
            this.dateData.push(this.timestampToTime(e[0]))
          })
        }
        if (this.memChartData.length !== 0) {
          this.memChartData[this.memChartData.length - 1].histData.forEach((e, i) => {
            this.dateData1.push(this.timestampToTime(e[0]))
          })
        }
        this.cpuChartData.forEach((e, i) => {
          e.lineData = []
          e.histData.forEach((item, index) => {
            e.lineData.push(parseFloat(item[1].toFixed(2)))
          })
          if (e.lineData.length < this.dateData.length) {
            for (let i = 0; i < this.dateData.length - e.lineData.length; i++) {
              e.lineData.unshift(null)
            }
          }
          this.$nextTick(() => {
            this.seriesData.push({
              name: e.podName,
              type: 'line',
              // stack: '总量',
              smooth: true,
              symbol: 'none',
              data: e.lineData
            })
          })
        })
        this.memChartData.forEach((e, i) => {
          e.lineData1 = []
          e.histData.forEach((item, index) => {
            e.lineData1.push(parseFloat(item[1].toFixed(2)))
          })
          if (e.lineData1.length < this.dateData1.length) {
            for (let i = 0; i < this.dateData1.length - e.lineData1.length; i++) {
              e.lineData1.unshift(null)
            }
          }
          this.$nextTick(() => {
            this.seriesData1.push({
              name: e.podName,
              type: 'line',
              // stack: '总量',
              smooth: true,
              symbol: 'none',
              data: e.lineData1
            })
          })
        })
        this.$nextTick(() => {
          this.getLine()
        })
      })
    },
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp * 1000)
      var Y = date.getFullYear() + '-'
      var M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes()
      var s = date.getSeconds()
      return M + D + h + m
    },
    // 折线图
    getLine() {
      // 基于准备好的dom，初始化echarts实例
      if (this.cpuChartData.length !== 0) {
        var charts = echarts.init(document.getElementById('lineEcharts'))
      }
      if (this.memChartData.length !== 0) {
        var charts1 = echarts.init(document.getElementById('lineEcharts1'))
      }

      // 绘制图表，this.echarts1_option是数据
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dateData,
          axisLine: {
            lineStyle: {
              color: '#DADADA'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            // x轴文字的配置
            show: true,
            textStyle: {
              color: '#606266'
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            // 网格线
            lineStyle: {
              type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
              width: 1
            },
            show: true // 隐藏或显示
          }
        },
        series: this.seriesData
      }
      const option1 = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dateData1,
          axisLine: {
            lineStyle: {
              color: '#DADADA'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            // x轴文字的配置
            show: true,
            textStyle: {
              color: '#606266'
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            // 网格线
            lineStyle: {
              type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
              width: 1
            },
            show: true // 隐藏或显示
          }
        },
        series: this.seriesData1
      }
      const option2 = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['09-21', '09-22', '09-23', '09-24', '09-25', '09-26'],
          axisLine: {
            lineStyle: {
              color: '#DADADA'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            // x轴文字的配置
            show: true,
            textStyle: {
              color: '#606266'
            }
          }
        },
        color: ['#437AEC', '#33D1C9', '#F8D833'],
        yAxis: {
          type: 'value',
          splitLine: {
            // 网格线
            lineStyle: {
              type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
              width: 1
            },
            show: true // 隐藏或显示
          }
        },
        series: [
          {
            name: 'A',
            type: 'line',
            // stack: '总量',
            smooth: true,
            symbol: 'none',
            data: [3, 54, 125, 43, 24, 5]
          },
          {
            name: 'B',
            type: 'line',
            // stack: '总量',
            smooth: true,
            symbol: 'none',
            data: [43, 5, 33, 23, 54, 53]
          },
          {
            name: 'C',
            type: 'line',
            // stack: '总量',
            smooth: true,
            symbol: 'none',
            data: [2, 45, 42, 23, 34, 3]
          }
        ]
      }
      if (this.cpuChartData.length !== 0) {
        charts.setOption(option)
      }
      if (this.memChartData.length !== 0) {
        charts1.setOption(option1)
      }
    },
    sortChange(val) {
      this.detailSortBy = val.prop
      if (val.order === 'ascending') {
        this.detailOrderBy = 'ASC'
      } else {
        this.detailOrderBy = 'DESC'
      }
      this.getModelPublishDetail()
    },
    detailHandleSizeChange(val) {
      this.detailPageSize = val
      this.detailPageNum = 1
      this.getModelPublishDetail()
    },
    detailHandleCurrentChange(val) {
      this.detailPageNum = val
      this.getModelPublishDetail()
    },
    addParam() {
      this.form.requestParams.push({
        name: '',
        type: '',
        isRequired: '',
        description: '',
        form: 'requestParams'
      })
    },
    addBody() {
      this.form.requestBody.push({
        name: '',
        type: '',
        isRequired: '',
        description: '',
        form: 'requestBody'
      })
    },
    addHeader() {
      this.form.requestHeaders.push({
        key: '',
        value: '',
        form: 'requestHeaders'
      })
    },
    delTableColum(row) {
      var index = this.form[row.form].indexOf(row)
      console.log(index, 'del')
      if (index !== -1) {
        this.form[row.form].splice(index, 1)
      }
    },
    insertTableColum(row, type) {
      var index = this.form[row.form].indexOf(row)
      if (type === 'up') {
        this.form[row.form].splice(index, 0, {
          name: '',
          type: '',
          isRequired: '',
          description: '',
          form: this.currentRow.form
        })
      } else if (type === 'down') {
        this.form[row.form].splice(index + 1, 0, {
          name: '',
          type: '',
          isRequired: '',
          description: '',
          form: this.currentRow.form
        })
      }
    },
    // 获取url和端口列表
    getModelPublishServicePorts() {
      getModelPublishServicePorts(this.id).then(response => {
        this.baseUrl = 'http://' + response.data.url
        this.portOptions = response.data.targetPort
        if (this.portOptions.length > 0) {
          this.currentPort = this.portOptions[0]
        }
        this.getModelPublishApiList()
        this.getModelPublishApiReq()
      })
    },
    // 查询api调用情况
    getModelPublishApiReq() {
      const path = '/' + this.name + this.currentPort + '/'
      getModelPublishApiReq(path).then(response => {
        this.publishApiInfo = response.data
      })
    },
    // 获取模型发布详细信息
    getModelPublishApiList() {
      this.apiBoxLoading = true
      getModelPublishApiList(this.id, this.currentPort).then(response => {
        // 如果没有就是新增，如果有就是编辑
        if (response.data) {
          this.mode = 'edit'
          this.form = {
            id: response.data.id,
            url: response.data.url,
            method: response.data.requestMethod,
            requestParams: JSON.parse(response.data.requestParams),
            requestHeaders: JSON.parse(response.data.requestHeaders),
            requestBodyType: response.data.requestBodyType,
            responseData: response.data.responseData,
            requestBody: []
          }
          try {
            if (response.data.requestBodyType === 'form-data') {
              this.form.requestBody = JSON.parse(response.data.requestBody)
            } else if (response.data.requestBodyType === 'json') {
              this.form.requestJsonBody = response.data.requestBody
            }
          } catch (error) {
            this.form.requestBody = []
          }
        } else {
          this.mode = 'add'
        }
      }).finally(() => {
        this.apiBoxLoading = false
      })
    },
    editApiInfo() {
      // 保存
      if (this.isEdit) {
        const requestBody = this.form.requestBody.filter(item => { return item.name !== '' || item.type !== '' || item.isRequired !== '' || item.description !== '' })
        const requestHeaders = this.form.requestHeaders.filter(item => { return item.key !== '' || item.value !== '' })
        const requestParams = this.form.requestParams.filter(item => { return item.name !== '' || item.type !== '' || item.isRequired !== '' || item.description !== '' })
        const data = {
          modelPublishId: this.id,
          requestBodyType: this.form.requestBodyType,
          requestHeaders: JSON.stringify(requestHeaders),
          requestMethod: this.form.method,
          requestParams: JSON.stringify(requestParams),
          responseData: this.form.responseData,
          targetPort: this.currentPort,
          url: this.form.url
        }
        if (this.form.requestBodyType === 'form-data') {
          data.requestBody = JSON.stringify(requestBody)
        } else if (this.form.requestBodyType === 'json') {
          data.requestBody = this.form.requestJsonBody
        }
        if (this.mode === 'add') {
          this.editBtnLoading = true
          createModelPublishApi({ modelPublishApiEntity: data }).then(response => {
            this.getModelPublishApiList()
          }).finally(() => {
            this.editBtnLoading = false
          })
        } else if (this.mode === 'edit') {
          data.modelPublishId = this.id
          data.id = this.form.id
          patchModelPublishApi({ ids: this.form.id, modelPublishApiEntity: data }).then(response => {
            this.getModelPublishApiList()
          }).finally(() => {
            this.editBtnLoading = false
          })
        }
      }
      this.isEdit = !this.isEdit
    },
    // 改变端口
    changePort() {
      this.getModelPublishApiList()
    },
    rightParamsTableClick(row, column, event) {
      if (!this.isEdit) {
        return
      }
      this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault()
      this.currentRow = row
      var menu = document.querySelector('.menu')
      this.styleMenu(menu, event)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      document.removeEventListener('click', this.foo) // 关掉监听，
      document.querySelector('.dev-environment-dialog').removeEventListener('click', this.foo)
    },
    styleMenu(menu, event) {
      if (event.clientX > 1800) {
        menu.style.left = event.clientX - 180 + 'px'
      } else {
        menu.style.left = event.clientX - 180 + 'px'
      }
      document.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      document.querySelector('.dev-environment-dialog').addEventListener('click', this.foo) // 给弹窗新增监听鼠标事件，点击任何位置执行foo方法
      if (event.clientY > 700) {
        menu.style.top = event.clientY - 100 + 'px'
      } else {
        menu.style.top = event.clientY - 50 + 'px'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.dev-environment-dialog {
  font-family: PingFang SC-Regular, PingFang SC;

  .shell-list {
    margin-right: 8px;
    min-width: 220px;
    max-height: 447px;
    overflow: auto;
    background: #F5F7FA;
    border-radius: 4px 4px 4px 4px;
    padding: 8px;

    .shell-box {
      border-bottom: 1px solid #E7E7E7;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
      color: #606266;
      font-size: 14px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .shell-disabled {
        color: #d6dae4;
        cursor: no-drop;
      }
    }

    .activeSsh {
      color: #437AEC;
    }
  }

  .shell,
  .base-style {
    padding-top: 16px;
    display: flex;
    min-height: 475px;
  }

  .detail {
    .detail-box {
      flex: 1;
      background-color: #f5f7fa;
      padding-bottom: 16px;

      .detail-message {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; //多行在这里修改数字即可
        overflow: hidden;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      }

      .pagination {
        margin-right: 24px;
      }

      ::v-deep .el-table th.el-table__cell {
        background-color: #F5F7FA;
        padding: 6.05px 0;
      }

      ::v-deep .el-table__footer-wrapper,
      .el-table__header-wrapper {
        border-radius: 6px 6px 0 0;
      }

      ::v-deep .el-table .el-table__cell {
        padding: 7.5px 0;
      }

      ::v-deep .el-table .cell {
        color: #909399;
        font-size: 14px;
        font-weight: 400;
      }

      ::v-deep .el-table tbody .cell {
        color: #303133;
      }

      ::v-deep .el-table tr {
        background-color: #F5F7FA;
      }
    }
  }

  .basic-info {
    padding: 24px;

    .left-col {
      margin-right: 70px;
    }

    ::v-deep .el-col-12 {
      max-width: calc(50% - 35px);
    }

    ::v-deep .el-input__inner {
      border: none;
    }
  }

  .tabs {
    display: flex;
    margin-bottom: 4px;

    .tab-box {
      width: 92px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 2px 2px 2px 2px;
      background: #F2F3F5;
      border-left: 1px solid #E5E6EB;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
    }

    .tab-box:first-child {
      border-left: none;
    }

    .active {
      color: #fff;
      background: #437AEC;
    }
  }

  .content-box {
    margin-top: 26px;
  }

  .line-charts {
    // padding: 23px 16px 7px 44px;
  }

  .container {
    padding: 32px 10px 16px 20px;

    .container-box {
      background: #F5F7FA;
      border-radius: 6px;
      margin-right: 10px;
      padding: 11px 26px;
    }

    ::v-deep .el-table th.el-table__cell {
      background-color: #F5F7FA;
      padding: 6.05px 0;
    }

    ::v-deep .el-table__footer-wrapper,
    .el-table__header-wrapper {
      border-radius: 6px 6px 0 0;
    }

    ::v-deep .el-table .el-table__cell {
      padding: 7.5px 0;
    }

    ::v-deep .el-table .cell {
      color: #909399;
      font-size: 14px;
      font-weight: 400;
    }

    ::v-deep .el-table tbody .cell {
      color: #303133;
    }

    ::v-deep .el-table tr {
      background-color: #F5F7FA;
    }
  }

  .push-detail {
    font-family: PingFang SC, PingFang SC;

    ::v-deep input[aria-hidden="true"] {
      display: none !important;
    }

    ::v-deep .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
      box-shadow: none;
    }

    .contextmenu-item {
      display: block;
      line-height: 30px;
      text-align: left;
      padding-left: 10px;
    }

    .contextmenu-item:not(:last-child) {
      border-bottom: 1px solid #b2c2cf;
    }

    .menu {
      position: absolute;
      background-color: #fff;
      width: 160px;
      /*height: 106px;*/
      font-size: 12px;
      color: #313a46;
      border-radius: 4px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 3px;
      border: 1px solid #b2c2cf;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      white-space: nowrap;
      z-index: 10000;
    }

    .contextmenu-item:hover {
      cursor: pointer;
      background: #eff1f7;
      border-color: #eff1f7;
      color: #313a46;
    }

    .header-info {
      height: 77px;
      line-height: 77px;
      background: #FFFFFF;
      color: #303133;
      margin-bottom: 16px;
      display: flex;
      .info-item {
        margin-right: 120px;

        .label {
          font-weight: 400;
          font-size: 14px;
          color: #909399;
          margin-right: 14px;
        }
      }
      .info-item-status {
        display: flex;
        .label{
        font-weight: 400;
        font-size: 14px;
        color: #909399;
        margin-right: 14px;
      }
      .state_circles {
  display: flex;
  align-items: center;

  .circles {
    width: 7px;
    height: 7px;
    // background-color: #fa5c7c;
    border-radius: 50%;
    margin-right: 10px;
  }
}
      }
    }

    .header-api-info {
      height: 77px;
      line-height: 77px;
      background: #f2f4f5;
      margin-top: 14px;
      margin-bottom: 16px;
      padding-left: 90 - 24px;
      font-weight: 400;
      font-size: 14px;
      color: #606266;

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background-image: none;
        transform: translateY(3px);
      }
    }
  }

  .model-box {
    max-height: 400px;
    overflow-y: auto;
    padding: 0 16px;
    :deep(.el-button--primary){
      color: #fff !important;
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      line-height: 22px;

      &::before {
        content: "";
        display: inline-block;
        width: 2px;
        height: 12px;
        margin-right: 8px;
        background-color: #165DFF;
        line-height: 22px;
      }
    }

    .header-btn-box {
      text-align: right
    }

    .api-info {
      font-weight: 400;
      font-size: 14px;
      color: #303133;
      padding-top: 24px;

      .el-select {
        flex: 1;
      }

      .el-input {
        flex: 1;
      }

      .el-button {
        float: right;
      }

      .url-box {
        display: flex;
        flex: 1;

        .input-box {
          flex: 1
        }
      }

      .response-box {
        min-height: 300px;
        background: #42444E;
        color: #fff;
        padding: 10px;
        flex: 1;
      }

      .table {
        flex: 1;

        ::v-deep .el-input__inner {
          border: none;
          box-shadow: none;
        }

        .btn-box {
          margin-top: 8px;
          font-size: 22px;

           .el-icon {
            cursor: pointer;

            &:hover {
              color: #165DFF !important;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.el-popper {
  max-width: 800px !important;
}
</style>
