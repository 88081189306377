<template>
  <div class="ai-choice-file">
    <div class="dialog-container">
      <el-dialog
        v-model="dirFormVisible"
        title="选择目录"
        :close-on-click-modal="false"
        :before-close="handleClose"
        width="600px"
      >
        <div v-loading="loadingDialog" class="ai-choice-dialog-content">
          <div class="left">
            <el-tree
              ref="tree"
              :data="data"
              :load="loadNode"
              lazy
              node-key="relativePath"
              :props="defaultProps"
              icon-class="el-icon-arrow-right"
              @node-click="handleNodeClick"
              @node-contextmenu="dirsRightClick"
            >
              <template #default="{ node }">
                <span class="custom-tree-node">
                  <svg-icon v-if="node.data.type === 'dir'" icon-class="files" style="margin: 0 12px" />
                  <svg-icon v-else-if="node.data.type === 'cmd'" icon-class="cmd" style="margin: 0 12px" />
                  <svg-icon v-else-if="node.data.type === 'md'" icon-class="md" style="margin: 0 12px" />
                  <svg-icon v-else-if="node.data.type === 'text'" icon-class="txt" style="margin: 0 12px" />
                  <svg-icon v-else-if="node.data.type === 'zip'" icon-class="zip" style="margin: 0 12px" />
                  <svg-icon v-else-if="node.data.type === 'image'" icon-class="img" style="margin: 0 12px" />
                  <svg-icon v-else icon-class="other" />
                  <span>{{ node.label }}</span>
                </span>

              </template>
            </el-tree>
            <!-- 右键 左侧文件夹 -->
            <div v-show="dirsMenuVisible" id="dirsContextmenu" class="dirsFileMenu">
              <div v-if="rightType === 'dir'" class="contextmenu-item" @click="createRight(currentContextMenu)">

                新建文件夹
              </div>
              <template v-if="createBy === $store.state.user.name">
                <div class="contextmenu-item" @click="deleteRight(currentContextMenu)">
                  删除
                </div>
                <div class="contextmenu-item" @click="renameRight(currentContextMenu)">
                  重命名
                </div>
              </template>
              <div v-if="rightType !== 'dir'" class="contextmenu-item" @click="downloadRight(currentContextMenu)">
                下载
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              type="primary"
              @click="confirmChoice"
            >确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <div class="create-file">
      <el-dialog v-model="fileNameDialogVisible" :title="fileNameDialogTitle" width="500px" :close-on-click-modal="false" :before-close="handleFileCreateClose">
        <el-form ref="fileNameForm" :model="form" label-width="100px" :rules="rules">
          <el-form-item label="文件名" prop="name">
            <el-input v-model="form.name" placeholder="请输入文件名" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="handleFileCreate">确 定</el-button>
            <el-button @click="handleFileCreateClose">取 消</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import {
  filesList,
  deleteFileOrDir,
  createDirs,
  renameFileOrDir
} from '@/api/ai'
export default {
  name: 'AiChoiceFile',
  props: ['createBy', 'visible', 'type', 'modelId', 'modelVersionName'],
  data() {
    return {
      defaultExpandedKeys: [],
      currentRow: -1,
      loadingDialog: false,
      filesData: [],
      data: [],
      path: '',
      defaultProps: {
        children: 'childDir',
        label: 'name',
        isLeaf: 'leaf'
      },
      dirFormVisible: this.visible,
      choiceType: 'dir',
      directory: '',
      dirsMenuVisible: false,
      currentContextMenu: null,
      row: {},
      showInput: false,
      isEnter: false,
      isBlur: false,
      form: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入文件名', trigger: 'blur' }
        ]
      },
      fileNameDialogVisible: false,
      newCreateDir: '',
      fileNameDialogTitle: '新建文件夹',
      rightType: '',
      node: null
    }
  },
  watch: {
    visible(newValue, oldValue) {
      this.dirFormVisible = newValue
      this.rightType = ''
      this.$nextTick(() => {
        this.refreshTreeNode('')
      })
    }
  },
  mounted() {

  },
  methods: {

    handleClose() {
      if (this.node && this.node.type !== 'dir') {
        return ElMessage.warning('请选择目录！')
      }
      this.$emit('confirmDirs', this.path, this.choiceType)
      this.$nextTick(() => {
        this.path = ''
      })
    },
    // 确定选择目录
    confirmChoice() {
      if (this.node && this.node.type !== 'dir') {
        return ElMessage.warning('请选择目录！')
      }
      this.$emit('confirmDirs', this.path, this.choiceType)
      this.$nextTick(() => {
        this.path = ''
      })
    },
    // 递归列表最后一级为空
    getTreeData(data, child) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].type === 'dir') {
          if (data[i].childDir === undefined) {
          // children若为空数组，则将children设为undefined
            data[i].childDir = []
          } else {
          // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].childDir)
          }
        } else {
          data[i].leaf = true
        }
      }
      return data
    },
    // 数组去重
    unique(arr) {
      const res = new Map()
      return arr.filter((arr) => !res.has(arr) && res.set(arr, 1))
    },
    // loadNode 加载tree节点
    loadNode(node, resolve) {
      this.loadingDialog = true
      if (node.data.relativePath !== undefined) {
        this.path = node.data.relativePath
      }
      filesList(this.modelId, this.modelVersionName, this.path).then((response) => {
        if (node.level === 0) {
          this.loadingDialog = false
          return resolve([
            {
              name: '',
              relativePath: '',
              childDir: [],
              type: 'dir'
            }
          ])
        }
        var list = []
        response.data.forEach((e) => {
          list.push(e)
        })
        list = this.unique(list)
        node.childNodes = []
        if (resolve) {
          resolve(this.getTreeData(list))// 动态加载时
          this.loadingDialog = false
        } else {
          // 更新节点时：
          node.childNodes = []
          node.doCreateChildren(this.getTreeData(list))
          this.loadingDialog = false
        }
      })
    },
    // 点击节点事件
    handleNodeClick(data) {
      this.dirsMenuVisible = false
      this.path = data.relativePath
      this.node = data
    },
    // 树形控件 文件夹右键
    dirsRightClick(MouseEvent, object, Node, element) {
      this.rightType = object.type
      this.fileType = 'dir'
      this.menuVisible = false
      this.spaceMenuVisible = false
      this.dirsMenuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.dirsMenuVisible = true // 显示模态窗口，跳出自定义菜单栏
      MouseEvent.preventDefault() // 关闭浏览器右键默认事件
      this.currentContextMenu = object
      this.path = object.relativePath || ''
      var menu = document.querySelector('.dirsFileMenu')
      this.dirsStyleMenu(menu, MouseEvent)
    },
    dirsFoo() {
      console.log('dirsFoo')
      // 取消鼠标监听事件 菜单栏
      this.dirsMenuVisible = false
      window.removeEventListener('click', this.dirsFoo) // 关掉监听，
      document.querySelector('.ai-choice-dialog-content').removeEventListener('click', this.dirsFoo) // 给当前组件新增监听鼠标事件，点击任何位置执行foo方法
    },
    dirsStyleMenu(menu, event) {
      var y = event.clientY
      var x = event.clientX
      menu.style.display = 'block'
      menu.style.left = x + 'px'
      window.addEventListener('click', this.dirsFoo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      document.querySelector('.ai-choice-dialog-content').addEventListener('click', this.dirsFoo) // 给当前组件新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    // 刷新节点
    refreshTreeNode(path) {
      const node = this.$refs.tree.getNode(path)
      console.log(node, 'refreshTreeNode')
      if (node) {
        node.loaded = false
        node.expand() // 主动调用展开节点方法，重新查询该节点下的所有子节点
      }
    },
    // 删除
    deleteRight(currentContextMenu) {
      if (!currentContextMenu.relativePath) {
        ElMessage.error('不能选择根目录！')
        return
      }
      this.deleteFileOrDir()
    },
    // 新建文件夹
    deleteFileOrDir() {
      const data = {
        modelId: this.modelId,
        modelVersionName: this.modelVersionName,
        dirPath: this.currentContextMenu.relativePath
      }
      this.loadingDialog = true
      deleteFileOrDir(data).then((response) => {
        this.path = this.path.substring(0, this.path.lastIndexOf('/'))
        this.refreshTreeNode(this.path)
      }).finally(() => {
        this.loadingDialog = false
      })
    },
    handleNewDir() {
      this.inputType = 'fileInput'
      this.row = { name: '' }
      this.row.name.replace(/\s*/g, '')
      this.filesData.unshift(this.row)
      this.showInput = true
      this.isBlur = true
    },
    // 新建
    createRight(currentContextMenu) {
      this.fileNameDialogVisible = true
      this.fileNameDialogTitle = '新建文件夹'
      this.form.name = ''
    },
    renameRight(currentContextMenu) {
      this.fileNameDialogVisible = true
      this.fileNameDialogTitle = '重命名'
      this.form.name = currentContextMenu.name.substring(currentContextMenu.name.lastIndexOf('/') + 1)
    },
    handleFileCreateClose() {
      this.fileNameDialogVisible = false
    },
    handleFileCreate() {
      this.$refs.fileNameForm.validate((valid) => {
        if (valid) {
          if (this.fileNameDialogTitle === '新建文件夹') {
            const data = {
              modelId: this.modelId,
              modelVersionName: this.modelVersionName,
              dirPath: this.path ? this.path + '/' + this.form.name : this.form.name
            }
            createDirs(data).then((response) => {
              this.handleFileCreateClose()
              this.refreshTreeNode(this.path)
            })
          } else if (this.fileNameDialogTitle === '重命名') {
            const data = {
              modelId: this.modelId,
              modelVersionName: this.modelVersionName,
              dirPath: this.path,
              newFileName: this.form.name
            }
            // 重命名
            renameFileOrDir(data).then((response) => {
              const path = this.path.substring(0, this.path.lastIndexOf('/'))
              if (response.meta.status === 200) {
                // 如果重命名的不是一级目录则需要拼接父级目录 + 新文件名
                if (path) {
                  this.path = path + '/' + this.form.name
                } else {
                  // 否则直接赋值
                  this.path = this.form.name
                }
                // 去父级目录
                this.handleFileCreateClose()
                this.refreshTreeNode(path)
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-container{
  ::v-deep .el-dialog__footer {
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: 99;
    width: calc(100% - 230px);
    background-color: #fff;
    border-bottom-right-radius: 20px;
  }
  ::v-deep .el-dialog__body{
        padding: 0;
      }
  }

    ::v-deep .el-button--primary{
      padding: 9px 45px;
      color: #fff;
     }
    ::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-icon{
      color: #333;
    }
    ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
  ::v-deep .el-dialog{
    max-height: 600px;
    border-radius: 20px;
    .el-dialog__body{
      max-height: 600px;
    }
  }
  .create-file{
    ::v-deep .el-dialog__body{
      padding:20px 45px 10px 45px
    }
  }
  .ai-choice-dialog-content{
    display: flex;
    font-size: 12px;
    min-height: 500px;
    .left {
      overflow: auto;
      // min-width: 220px;
      width: 100%;
      // height: 80%;
      max-height: calc(600px - 57px);
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      padding: 20px;
      color: #313a46;
      border-right: 1px solid #d8d8d8;
      ::v-deep .el-tree-node.is-expanded > .el-tree-node__children {
        display: inline;
      }
      .dirsFileMenu {
        position: fixed;
    background-color: #fff;
    width: 160px;
    padding: 0 10px;
    font-size: 12px;
    color: #313a46;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e8eef3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    white-space: nowrap;
    z-index: 10000;
    .svg-icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 11px;
    }
    .contextmenu-disabled:hover{
      // color: #eee;
      background-color: #fff;
      cursor:not-allowed;
    }
      }
    }
  }
</style>
