<template>
  <div v-loading="isDialogLoading" class="dev-environment-dialog">
    <div class="tabs">
      <div
        v-for="item in tabList"
        :key="item.id"
        class="tab-box"
        :class="{ active: item.id === activeColor }"
        @click="tabsChange(item.id, item)"
      >{{ item.name }}</div>
    </div>
    <!-- shell -->
    <div v-show="activeColor == 0" class="base-style shell">
      <div class="shell-list">
        <div
          v-for="(item, i) in sshList"
          :key="i"
          class="shell-box"
          :class="{ activeSsh: i === activeSshColor }"
        ><el-tooltip :content="item.podName" placement="top" effect="light">
          <div v-if="item.status=='true'" @click="sshChange(i, item)">{{ item.podName }}</div>
          <div v-else class="shell-disabled">
            {{ item.podName }}
          </div>
        </el-tooltip>
        </div>
      </div>
      <div v-loading="terminalLoading" class="terminal-box">
        <div id="xtermAi" class="xtermAi" style="width:739px;height:447px;" @contextmenu.prevent="rightShow">
          <div v-if="waitLoading" class="wait-loading">
            <div style="text-align:center;padding-top:100px">
              <img src="@/assets/icon/terminal-loading.png" alt="">
              <div style="padding-top:10px">等待连接...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 容器实例 -->
    <div v-show="activeColor == 1" class="content">
      <ai-box :title="'容器列表'" :is-title="isTitle" class="content-box">
        <div class="container">
          <div class="container-box">
            <el-table
              :data="containerData"
              style="width: 100%; height: auto"
            >
              <el-table-column prop="podName" label="名称" show-overflow-tooltip />
              <el-table-column
                prop="status"
                label="状态"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <div
                    v-if="scope.row.status === 'true'"
                    style="color: #165DFF"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                  <div
                    v-else
                    style="color: #00A870"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="hostIP"
                label="节点IP"
                show-overflow-tooltip
              />
              <el-table-column
                prop="podIP"
                label="容器IP"
                show-overflow-tooltip
              />
            </el-table>
            <div class="pagination">
              <div class="total">合计：{{ totalPage }}</div>
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                layout="sizes"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
              <div class="jumper">
                <div>{{ pageNum }}/{{ pagenumber }}</div>
                <div class="jumper-line" />
                <el-pagination
                  v-model:currentPage="pageNum"
                  :page-size="pageSize"
                  :page-count="pagenumber"
                  :page-sizes="pageSizes"
                  background
                  layout="jumper"
                  :total="totalPage"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                />
              </div>
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                background
                layout="prev, next"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
      </ai-box>
      <ai-box :title="'CPU利用率'" :is-title="isTitle" class="content-box line-charts">
        <div v-if="cpuChartData.length !== 0">
          <div id="lineEcharts" style="width:968px;height:200px;" />
        </div>
        <div v-else>
          <div style="text-align:center;padding-top:17px">
            <img src="@/assets/icon/charts-no-data.png" alt="">
            <div style="padding-top:6px;color:#A8ABB2;font-size: 14px;">暂无数据</div>
          </div>
        </div>
      </ai-box>
      <ai-box :title="'内存利用率'" :is-title="isTitle" class="content-box line-charts">
        <div v-if="memChartData.length !== 0">
          <div id="lineEcharts1" style="width:968px;height:200px;" />
        </div>
        <div v-else>
          <div style="text-align:center;padding-top:17px">
            <img src="@/assets/icon/charts-no-data.png" alt="">
            <div style="padding-top:6px;color:#A8ABB2;font-size: 14px;">暂无数据</div>
          </div>
        </div>
      </ai-box>
    </div>
    <!-- 基本信息 -->
    <div v-show="activeColor == 2" class="basic-info">
      <el-form ref="basicForm" :rules="basicRules" :model="basicForm" size="small" label-width="7em">
        <el-row>
          <el-col :span="12" class="left-col">
            <el-form-item prop="name" label="环境名称">
              <el-input v-model="basicForm.name" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="createTime" label="创建时间">
              <el-input v-model="basicForm.createTime" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="left-col">
            <el-form-item prop="image" label="镜像">
              <el-input v-model="basicForm.image" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="shmSize" label="shm_size">
              <el-input v-model="basicForm.shmSize" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="left-col">
            <el-form-item prop="src" label="资源组">
              <el-input v-model="basicForm.src" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="cpu" label="CPU">
              <el-input v-model="basicForm.cpu" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="left-col">
            <el-form-item prop="state" label="状态">
              <el-input v-model="basicForm.state" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="copyNum" label="副本数">
              <el-input v-model="basicForm.copyNum" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 容器日志 -->
    <div v-show="activeColor == 3" class="shell basic-log">
      <div class="shell-list">
        <div
          v-for="(item, i) in sshList"
          :key="i"
          class="shell-box"
          :class="{ activeSsh: i === activeLogColor }"
          @click="changeLog(item,i)"
        ><el-tooltip :content="item.podName" placement="top" effect="light">
          <div>{{ item.podName }}</div>
        </el-tooltip>
        </div>
      </div>
      <div v-loading="terminalLoading" class="terminal-box">

        <AiDevLog ref="devLog" :name-space="pId" :name="currentName" />
      </div>
    </div>
    <!-- 详情 -->
    <div v-show="activeColor == 4" class="base-style detail">
      <div class="shell-list">
        <div
          v-for="(item, i) in sshList"
          :key="i"
          class="shell-box"
          :class="{ activeSsh: i === activeDetailColor }"
          @click="changeDetail(item,i)"
        ><el-tooltip :content="item.podName" placement="top" effect="light">
          <div>{{ item.podName }}</div>
        </el-tooltip>
        </div>
      </div>
      <div class="detail-box">
        <div v-if="detailtotalPage !== 0" class="content">
          <el-table
            v-loading="isLoading"
            :data="detailTable"
            tooltip-effect="dark"
            @sort-change="sortChange"
          >
            <el-table-column
              label="Type"
              prop="type"
              show-overflow-tooltip
            />
            <el-table-column
              label="Reason"
              prop="reason"
              show-overflow-tooltip
            />
            <el-table-column
              label="Age"
              prop="age"
              show-overflow-tooltip
            />
            <el-table-column
              label="Message"
              prop="message"
            >
              <template #default="scope">
                <el-tooltip placement="top">
                  <template #content>  {{ scope.row.message }} </template>
                  <div class="detail-message">
                    {{ scope.row.message }}
                  </div>
                </el-tooltip>
                <!--  -->

              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <div class="total">合计：{{ detailtotalPage }}</div>
            <el-pagination
              v-model:currentPage="detailPageNum"
              :page-size="detailPageSize"
              :page-count="detailPageNumber"
              :page-sizes="detailPageSizes"
              layout="sizes"
              :total="detailtotalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <div class="jumper">
              <div>{{ detailPageNum }}/{{ detailPageNumber }}</div>
              <div class="jumper-line" />
              <el-pagination
                v-model:currentPage="detailPageNum"
                :page-size="detailPageSize"
                :page-count="detailPageNumber"
                :page-sizes="detailPageSizes"
                background
                layout="jumper"
                :total="detailtotalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
            <el-pagination
              v-model:currentPage="detailPageNum"
              :page-size="detailPageSize"
              :page-count="detailPageNumber"
              :page-sizes="detailPageSizes"
              background
              layout="prev, next"
              :total="detailtotalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>

        </div>
        <div v-else>
          <div style="text-align:center;padding-top:17px">
            <img src="@/assets/icon/charts-no-data.png" alt="">
            <div style="padding-top:6px;color:#A8ABB2;font-size: 14px;">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'xterm/css/xterm.css'
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import { AttachAddon } from 'xterm-addon-attach'
import 'xterm/css/xterm.css'
import 'xterm/lib/xterm.js'
import aiBox from '@/components/AiBox.vue'
import { getPodEventList, getDevlopmentenvPod, getDevlopmentenvDetail, getDevlopmentenvSrcDeatail } from '@/api/ai'
import * as echarts from 'echarts'

import AiDevLog from '@/components/AiDevLog.vue'
export default {
  components: { aiBox, AiDevLog },
  props: ['row', 'dialogVisible', 'projectid'],
  data() {
    return {
      isDialogLoading: false,
      pId: null,
      // terminal
      waitLoading: false,
      terminalLoading: false,
      vncSwitch: false,
      term: null,
      socket: null,
      fitAddon: '',
      ws: {},
      baseUrl: 'wss://192.168.132.234:9090/slurm/log?path=',
      //
      activeSshColor: 0,
      sshList: [],
      activeColor: 0,
      tabList: ['shell', '容器实例', '基本信息', '容器日志', '详情'],
      isTitle: true,
      pageSize: 2,
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [2, 3, 5, 10, 20],
      containerData: [],
      basicForm: {
        name: 'mlk001dev',
        image: '10.31.118.167:5000/tensorflow/tensorflow:20.09-py3-cuda11',
        state: '运行中',
        src: 'emergency',
        cpu: '16核',
        shmSize: '4GB',
        copyNum: '2',
        createTime: '2023-09-04  10:20:25'
      },
      basicRules: {
        name: [{ required: true, trigger: 'blur', message: '请输入环境名称' }],
        createTime: [{ required: true, trigger: 'blur', message: '请输入创建时间' }],
        image: [{ required: true, trigger: 'change', message: '请选择镜像' }],
        state: [{ required: true, trigger: 'change', message: '请选择状态' }],
        src: [{ required: true, trigger: 'change', message: '请选择资源组' }],
        cpu: [{ required: true, trigger: 'change', message: '请选择CPU/GPU' }],
        shmSize: [{ required: true, trigger: 'blur', message: '请输入shm-size' }],
        copyNum: [{ required: true, trigger: 'blur', message: '请输入副本数' }]
      },
      podName: '',
      // 图表
      charts: null,
      charts1: null,
      charts2: null,
      cpuChartData: [],
      memChartData: [],
      dateData: [],
      dateData1: [],
      lineData: [],
      lineData1: [],
      lineData2: [],
      seriesData: [],
      seriesData1: [],
      detailTable: [],
      isLoading: false,
      detailPageNum: 1,
      detailPageSize: 10,
      detailPageSizes: [10, 20, 30, 40, 50],
      detailtotalPage: 0,
      detailSortby: '',
      detailOrderBy: '',
      detailPageNumber: 0,
      activeDetailColor: 0,
      currentName: '',
      activeLogColor: 0
    }
  },
  watch: {
    dialogVisible: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.closeSsh()
        } else {
          this.activeColor = 0
          this.activeSshColor = 0
          this.containerData = []
          this.charts = null
          this.charts1 = null
          this.charts2 = null
          this.cpuChartData = []
          this.memChartData = []
          this.dateData = []
          this.dateData1 = []
          this.lineData = []
          this.lineData1 = []
          this.lineData2 = []
          this.seriesData = []
          this.seriesData1 = []
          this.getData()
        }
      },
      deep: true
    },
    projectid: {
      handler(newVal, oldVal) {
        console.log(newVal)
        this.pId = newVal
      },
      deep: true
    },
    'row.status': {
      handler(newVal, oldVal) {
        if (newVal === 'active' || newVal === 'running') {
          this.tabList = [
            {
              name: 'shell',
              id: 0
            },
            {
              name: '容器实例',
              id: 1
            },
            {
              name: '基本信息',
              id: 2
            },
            {
              name: '容器日志',
              id: 3
            },
            {
              name: '详情',
              id: 4
            }
          ]
        } else {
          this.tabList = [
            {
              name: 'shell',
              id: 0
            },
            {
              name: '容器实例',
              id: 1
            },
            {
              name: '基本信息',
              id: 2
            },
            {
              name: '详情',
              id: 4
            }
          ]
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.pId = this.projectid
    this.getData()
  },
  beforeUnmount() {
    this.closeSsh()
  },
  methods: {
    // 初始化赋值
    getData() {
      this.basicForm.name = this.row.envName
      this.basicForm.image = this.row.imageName
      this.basicForm.cpu = this.row.cpuLimit
      this.basicForm.createTime = this.row.creationTime
      this.basicForm.copyNum = this.row.replicasTotal
      this.basicForm.state = this.row.status
      this.getSshList()
    },
    // 容器实例
    getDevlopmentenvDetail() {
      const data = {
        devEnvName: this.row.envName,
        projectId: this.pId,
        pagenum: this.pageNum,
        pagesize: this.pageSize
      }
      getDevlopmentenvDetail(data).then((response) => {
        this.containerData = response.data.content
        this.pagenumber = response.data.totalpages
        this.totalPage = response.data.totalelements
        this.isDialogLoading = false
      }).catch(() => {
        this.isDialogLoading = false
      })
    },
    // ssh
    getSshList() {
      this.isDialogLoading = true
      this.waitLoading = true
      const data = {
        devEnvName: this.row.envName,
        projectId: this.pId
      }
      getDevlopmentenvPod(data).then((response) => {
        this.sshList = response.data
        if (this.sshList.length !== 0 && this.sshList[0].status == 'true') {
          this.podName = this.sshList[0].podName
          this.$nextTick(() => {
            this.connectHandle()
          })
        } else {
          this.isDialogLoading = false
        }
      })
    },
    closeSsh() {
      try {
        this.socket.close()
        this.term.dispose()
      } catch (error) {
        console.log(error)
      }
    },
    // 切换ssh
    sshChange(i, item) {
      this.podName = item.podName
      this.isDialogLoading = true
      this.closeSsh()
      this.activeSshColor = i
      this.$nextTick(() => {
        this.connectHandle()
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.isDialogLoading = true
      this.getDevlopmentenvDetail()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.isDialogLoading = true
      this.getDevlopmentenvDetail()
    },
    // 切换tab
    tabsChange(i, item) {
      this.activeColor = i
      this.pageNum = 1
      if (i === 1) {
        this.isDialogLoading = true
        this.getDevlopmentenvDetail()
        this.$nextTick(() => {
          this.getLineData()
        })
      }
      if (i === 0) {
        this.closeSsh()
        this.activeSshColor = i
        this.$nextTick(() => {
          this.getSshList()
        })
      }
      if (i === 3) {
        if (this.sshList.length === 0) {
          return
        }
        this.activeLogColor = 0
        this.currentName = this.sshList[0].podName
        this.$nextTick(() => {
          this.$refs.devLog.clear()
          this.$refs.devLog.initLog()
        })
      }
      if (i == 4) {
        if (this.sshList.length === 0) {
          return
        }
        this.activeDetailColor = 0
        const envName = this.sshList[0].podName
        this.getPodEventList(envName)
      }
    },
    changeLog(item, i) {
      this.activeLogColor = i
      this.currentName = item.podName
      this.$nextTick(() => {
        this.$refs.devLog.clear()
        this.$refs.devLog.initLog()
      })
    },
    changeDetail(item, i) {
      this.activeDetailColor = i
      this.getPodEventList(item.podName)
    },
    getPodEventList(envName) {
      this.isLoading = true
      getPodEventList(envName, this.pId, this.detailPageNum, this.detailPageSize, this.detailSortby).then(res => {
        this.isLoading = false
        this.detailTable = res.data.content
        this.detailtotalPage = res.data.totalelements
        this.detailPageNumber = res.data.totalpages
      })
    },
    async connectHandle() {
      if (this.vncSwitch) {
        this.socketClose()
        this.term.dispose()
      }
      const Base64 = require('js-base64').Base64
      const connectInfo = {
        name: this.podName,
        namespace: this.pId.toString(),
        regionId: window.localStorage.regionId
      }
      const connect = Base64.encode(JSON.stringify(connectInfo))
      const token = sessionStorage.desktopToken
      var url
      var ishttps = document.location.protocol == 'https:'
      if (ishttps) {
        url = 'wss:' + location.host + '/aisocket/ws/container/terminal?connectInfo=' + connect + '&token=' + token
      } else {
        url = 'ws:' + location.host + '/aisocket/ws/container/terminal?connectInfo=' + connect + '&token=' + token
      }
      this.default = url
      await this.initSocket()
    },
    initTerm() {
      const term = new Terminal({
        scrollback: 500,
        fontSize: 14,
        row: 500,
        cursorBlink: true,
        rendererType: 'canvas',
        // 启用时，光标将设置为下一行的开头
        convertEol: false,
        theme: {
          cursor: 'help' // 设置光标
        }
      })
      const attachAddon = new AttachAddon(this.socket)
      const fitAddon = new FitAddon()
      term.loadAddon(attachAddon)
      term.loadAddon(fitAddon)
      term.open(document.getElementById('xtermAi'))
      this.fitAddon = fitAddon
      term.focus()
      term.attachCustomKeyEventHandler(e => {
        // console.log('listen press key ' + e.keyCode)
        if (e.ctrlKey && e.keyCode === 76) {
          term.clear()
        }
      })
      // 鼠标选中 是复制
      term.onSelectionChange(e => {
        if (term.hasSelection()) {
          if (window.isSecureContext) {
            navigator.clipboard.writeText(term.getSelection())
          } else {
            this.textArea = document.createElement('textarea')
            this.textArea.style.position = 'absolute'
            this.textArea.style.opacity = 0
            this.textArea.style.left = '-999999px'
            this.textArea.style.top = '-999999px'
            document.body.appendChild(this.textArea)
            this.textArea.textContent = term.getSelection()
            // 选择
            this.textArea.focus()
            this.textArea.select()
            // 复制
            document.execCommand && document.execCommand('copy')
          }
        }
      })
      term.onData(cmd => {
      })
      // 限制和后端交互，只有输入回车键才显示结果
      term.prompt = () => {
        term.write('\r\n$ ')
      }

      term.prompt()
      this.term = term
    },
    // xterm右键
    rightShow() {
      if (this.socket !== null) {
        if (window.isSecureContext) {
          navigator.clipboard.readText()
            .then(text => {
              this.socket.send(text)
            })
        } else {
          this.socket.send(this.textArea.textContent)
        }
      }
    },
    initSocket() {
      this.socket = new WebSocket(this.default)
      this.$nextTick(() => {
        this.waitLoading = false
        this.isDialogLoading = false
      })
      this.socketOnClose()
      this.socketOnOpen()
      this.socketOnError()
    },
    socketOnMessage() {
      this.socket.onmessage = (res) => {
        console.log(res)
      }
    },
    socketOnOpen() {
      this.socket.onopen = () => {
        // 链接成功后
        this.vncSwitch = true
        this.initTerm()
      }
    },
    socketClose() {
      this.vncSwitch = false
      this.socket.close()
    },
    socketOnClose() {
      this.socket.onclose = () => {
        console.log('close socket')
      }
    },
    socketOnError() {
      this.vncSwitch = false
      this.socket.onerror = () => {
        console.log('socket 连接失败')
      }
    },
    // 折线图表
    async getLineData() {
      this.dateData = []
      this.dateData1 = []
      this.lineData = []
      this.lineData1 = []
      this.seriesData = []
      this.seriesData1 = []
      const data = {
        devEnvName: this.row.envName,
        projectId: this.pId
      }
      await getDevlopmentenvSrcDeatail(data).then(response => {
        this.cpuChartData = response.data.cpuHist
        this.memChartData = response.data.memHist
        if (this.cpuChartData.length !== 0) {
          this.cpuChartData[this.cpuChartData.length - 1].histData.forEach((e, i) => {
            this.dateData.push(this.timestampToTime(e[0]))
            // this.lineData.push(parseFloat(e[1].toFixed(2)))
          })
        }
        if (this.memChartData.length !== 0) {
          this.memChartData[this.memChartData.length - 1].histData.forEach((e, i) => {
            this.dateData1.push(this.timestampToTime(e[0]))
            // this.lineData1.push(parseFloat(e[1].toFixed(2)))
          })
        }
        this.cpuChartData.forEach((e, i) => {
          e.lineData = []
          e.histData.forEach((item, index) => {
            e.lineData.push(parseFloat(item[1].toFixed(2)))
          })
          if (e.lineData.length < this.dateData.length) {
            for (let i = 0; i < this.dateData.length - e.lineData.length; i++) {
              e.lineData.unshift(null)
            }
          }
          this.$nextTick(() => {
            this.seriesData.push({
              name: e.podName,
              type: 'line',
              // stack: '总量',
              smooth: true,
              symbol: 'none',
              data: e.lineData
            })
          })
        })
        this.memChartData.forEach((e, i) => {
          // this.seriesData1.push({
          //   name: e.podName,
          //   type: 'line',
          //   // stack: '总量',
          //   smooth: true,
          //   symbol: 'none',
          //   data: this.lineData1
          // })
          e.lineData1 = []
          e.histData.forEach((item, index) => {
            e.lineData1.push(parseFloat(item[1].toFixed(2)))
          })
          if (e.lineData1.length < this.dateData1.length) {
            for (let i = 0; i < this.dateData1.length - e.lineData1.length; i++) {
              e.lineData1.unshift(null)
            }
          }
          this.$nextTick(() => {
            this.seriesData1.push({
              name: e.podName,
              type: 'line',
              // stack: '总量',
              smooth: true,
              symbol: 'none',
              data: e.lineData1
            })
          })
        })
        this.$nextTick(() => {
          this.getLine()
        })
      })
    },
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp * 1000)
      var Y = date.getFullYear() + '-'
      var M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes()
      var s = date.getSeconds()
      return M + D + h + m
    },
    // 折线图
    getLine() {
      // 基于准备好的dom，初始化echarts实例
      if (this.cpuChartData.length !== 0) {
        var charts = echarts.init(document.getElementById('lineEcharts'))
      }
      if (this.memChartData.length !== 0) {
        var charts1 = echarts.init(document.getElementById('lineEcharts1'))
      }
      // if (this.cpuChartData.length !== 0) {
      //   var charts2 = echarts.init(document.getElementById('lineEcharts2'))
      // }
      // 绘制图表，this.echarts1_option是数据
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dateData,
          axisLine: {
            lineStyle: {
              color: '#DADADA'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            // x轴文字的配置
            show: true,
            textStyle: {
              color: '#606266'
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            // 网格线
            lineStyle: {
              type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
              width: 1
            },
            show: true // 隐藏或显示
          }
        },
        series: this.seriesData
      }
      const option1 = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dateData1,
          axisLine: {
            lineStyle: {
              color: '#DADADA'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            // x轴文字的配置
            show: true,
            textStyle: {
              color: '#606266'
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            // 网格线
            lineStyle: {
              type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
              width: 1
            },
            show: true // 隐藏或显示
          }
        },
        series: this.seriesData1
      }
      const option2 = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['09-21', '09-22', '09-23', '09-24', '09-25', '09-26'],
          axisLine: {
            lineStyle: {
              color: '#DADADA'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            // x轴文字的配置
            show: true,
            textStyle: {
              color: '#606266'
            }
          }
        },
        color: ['#437AEC', '#33D1C9', '#F8D833'],
        yAxis: {
          type: 'value',
          splitLine: {
            // 网格线
            lineStyle: {
              type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
              width: 1
            },
            show: true // 隐藏或显示
          }
        },
        series: [
          {
            name: 'A',
            type: 'line',
            // stack: '总量',
            smooth: true,
            symbol: 'none',
            data: [3, 54, 125, 43, 24, 5]
          },
          {
            name: 'B',
            type: 'line',
            // stack: '总量',
            smooth: true,
            symbol: 'none',
            data: [43, 5, 33, 23, 54, 53]
          },
          {
            name: 'C',
            type: 'line',
            // stack: '总量',
            smooth: true,
            symbol: 'none',
            data: [2, 45, 42, 23, 34, 3]
          }
        ]
      }
      if (this.cpuChartData.length !== 0) {
        charts.setOption(option)
      }
      if (this.memChartData.length !== 0) {
        charts1.setOption(option1)
      }
      // if (this.cpuChartData.length !== 0) {
      //   charts2.setOption(option2)
      // }
    },
    sortChange(val) {
      this.detailSortBy = val.prop
      if (val.order === 'ascending') {
        this.detailOrderBy = 'ASC'
      } else {
        this.detailOrderBy = 'DESC'
      }
      this.getPodEventList()
    },
    detailHandleSizeChange(val) {
      this.detailPageSize = val
      this.detailPageNum = 1
      this.getPodEventList()
    },
    detailHandleCurrentChange(val) {
      this.detailPageNum = val
      this.getPodEventList()
    }
  }
}
</script>

<style lang="less" scoped>
.dev-environment-dialog{
  font-family: PingFang SC-Regular, PingFang SC;
  ::v-deep .el-popper{
        max-width: 20px !important;
      }
  .shell-list{
      margin-right: 8px;
      width: 221px;
      max-height: 447px;
      overflow: auto;
      background: #F5F7FA;
      border-radius: 4px 4px 4px 4px;
      padding: 8px;
      .shell-box{
        border-bottom: 1px solid #E7E7E7;
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        color: #606266;
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .shell-disabled{
          color: #d6dae4;
          cursor: no-drop;
        }
      }
      .activeSsh{
        color: #437AEC;
      }
    }
  .shell,.base-style{
    padding-top: 16px;
    display: flex;
  }
  .detail{
    .detail-box{
      flex: 1;
      background-color: #f5f7fa;
      padding-bottom: 16px;
      .detail-message{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; //多行在这里修改数字即可
        overflow:hidden;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      }
      .pagination{
        margin-right:24px;
      }

      ::v-deep .el-table th.el-table__cell{
          background-color: #F5F7FA;
          padding: 6.05px 0;
        }
        ::v-deep .el-table__footer-wrapper, .el-table__header-wrapper{
          border-radius: 6px 6px 0 0;
        }
        ::v-deep .el-table .el-table__cell{
          padding: 7.5px 0;
        }
        ::v-deep .el-table .cell{
          color: #909399;
          font-size: 14px;
          font-weight: 400;
        }
        ::v-deep .el-table tbody .cell{
          color: #303133;
        }
        ::v-deep .el-table tr{
          background-color: #F5F7FA;
        }
    }
  }
  .basic-info{
    padding: 24px;
    .left-col{
      margin-right: 70px;
    }
    ::v-deep .el-col-12{
      max-width: calc(50% - 35px);
    }
    ::v-deep .el-input__inner{
        border: none;
      }
  }
  .tabs{
    display: flex;
    margin-bottom: 4px;
    .tab-box{
      width: 92px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 2px 2px 2px 2px;
      background: #F2F3F5;
      border-left: 1px solid #E5E6EB;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
    }
    .tab-box:first-child{
      border-left: none;
    }
    .active{
      color: #fff;
      background: #437AEC;
    }
  }
  .content-box{
    margin-top: 26px;
  }
  .line-charts{
    // padding: 23px 16px 7px 44px;
  }
  .container{
    padding: 32px 10px 16px 20px;
    .container-box{
      background: #F5F7FA;
      border-radius: 6px;
      margin-right: 10px;
      padding: 11px 26px;
    }
    ::v-deep .el-table th.el-table__cell{
          background-color: #F5F7FA;
          padding: 6.05px 0;
        }
        ::v-deep .el-table__footer-wrapper, .el-table__header-wrapper{
          border-radius: 6px 6px 0 0;
        }
        ::v-deep .el-table .el-table__cell{
          padding: 7.5px 0;
        }
        ::v-deep .el-table .cell{
          color: #909399;
          font-size: 14px;
          font-weight: 400;
        }
        ::v-deep .el-table tbody .cell{
          color: #303133;
        }
        ::v-deep .el-table tr{
          background-color: #F5F7FA;
        }
  }
}
</style>
<style>
.el-popper{
  max-width: 800px !important;
}
</style>
