import axios from 'axios'
import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: '/preview-api', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    config.cancelToken = source.token
    store.commit('settings/ADD_CANCEL_TOKEN', source)
    if (store.getters.filePreviewToken) {
      config.headers['x-auth'] = store.getters.filePreviewToken
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // return res
    return response
  },
  error => {

  }
)

export default service
