<template>
  <div v-loading="isLoading" class="dev-environment">
    <div class="btn-box">
      <div>
        <el-select
          ref="filterSelect"
          v-model="projectId"
          :teleported="true"
          @change="projectGroupChange"
          @visible-change="visibleChange"
        >
          <el-option v-for="(item, i) in projectGroupList" :key="i" :label="item.name" :value="item.id" />
        </el-select>
      </div>
      <el-button
        :loading="createBtnLoading"
        @click="createHandle"
      ><svg-icon icon-class="dir-add" />创建</el-button>
    </div>
    <div class="dev-content">
      <div class="left">
        <div class="dev-left-top">
          <div v-for="(item,i) in devList" :key="i" class="dev-left-box">
            <div class="box-content">
              <div>
                <svg-icon :icon-class="item.icon" />
              </div>
              <div>
                <div class="content-title">{{ item.title }}</div>
                <div><span class="number">{{ item.number }}</span><span class="unit">{{ item.unit }}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="dev-left-bottom">
          <el-table
            :data="devData"
            style="width: 100%; height: auto"
          >
            <el-table-column prop="envName" label="环境名称" align="center" show-overflow-tooltip>
              <template #default="scope">
                <div style="color:#437AEC;" @click="rowClick(scope.row)">{{ scope.row.envName }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              align="center"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div
                  v-if="scope.row.status === 'Running' || scope.row.status === 'active'"
                  style="color: #165DFF"
                >
                  <div>{{ scope.row.status }}</div>
                </div>
                <div
                  v-else-if="scope.row.status === 'pending'"
                  style="color: #FD8E3F"
                >
                  <div>{{ scope.row.status }}</div>
                </div>
                <div
                  v-else-if="scope.row.status === 'stop' || scope.row.status === 'pause'"
                  style="color: #909399"
                >
                  <div>{{ scope.row.status }}</div>
                </div>
                <div
                  v-else
                  style="color: #FF1E1E"
                >
                  <div>{{ scope.row.status }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="nodeName"
              label="节点"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column prop="nodeName" label="访问地址" align="center" width="200">
              <template #default="scope">
                <el-popover effect="dark" placement="top-start" :width="250" trigger="hover">
                  <div>
                    <p v-for="(item, key) in scope.row.portList" :key="key" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                      <span
                        style="color:#437AEC;cursor: pointer;"
                        @click="openPortUrl(host + ':' + item.nodePort,item.targetPort === 8888
                          ? scope.row.jupyterPassword
                          : null)"
                      >{{ host + ":" + item.nodePort }}</span>
                      {{ "->" + item.targetPort }}
                    </p>
                  </div>
                  <template #reference>
                    <div v-show="scope.row.portList.length > 0" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                      <span
                        style="color: #437aec; cursor: pointer;"
                        @click="
                          openPortUrl(
                            host + ':' + scope.row.portList[0].nodePort,
                            scope.row.portList[0].targetPort === 8888
                              ? scope.row.jupyterPassword
                              : null
                          )
                        "
                      >{{ host + ":" + scope.row.portList[0].nodePort }}</span>
                      {{ "->" + scope.row.portList[0].targetPort }}
                    </div>
                  </template>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="jupyter" align="center">
              <template #default="scope">
                <el-tooltip
                  v-if="!scope.row.jupyterStatus"
                  class="box-item"
                  effect="dark"
                  placement="top-start"
                >
                  <template #content>
                    <span>未启动</span>
                  </template>

                  <svg-icon icon-class="state-close" />
                </el-tooltip>
                <svg-icon
                  v-if="scope.row.jupyterStatus"
                  icon-class="state-open"
                  style="cursor: pointer;"
                  @click="
                    openPortUrl(
                      host + ':' + (scope.row.portList.find((i) => i.targetPort === 8888) || {}).nodePort,
                      scope.row.jupyterPassword
                    )
                  "
                />
              </template>
            </el-table-column>
            <el-table-column label="ssh" align="center">
              <template #default="scope">
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top-start"
                >
                  <template #content>
                    <span v-show="!scope.row.sshStatus">未启动</span>
                    <span v-show="scope.row.sshStatus">
                      账号:root<br>
                      密码: <span style="cursor: pointer;" @click="copySshPassword(scope.row.sshPassword)">{{ scope.row.sshPassword }}</span>
                    </span>
                  </template>
                  <svg-icon
                    v-if="scope.row.sshStatus"
                    icon-class="state-open"
                  />
                  <svg-icon v-else icon-class="state-close" />
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="creationTime"
              label="创建时间"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              label="操作"
              width="130"
            >
              <template #default="scope">
                <div>
                  <svg-icon v-if="scope.row.status!=='pause'" icon-class="dev-stop" @click="actionHandle(scope.row)" />
                  <svg-icon v-if="scope.row.status==='pause'" icon-class="dev-start" @click="actionHandle(scope.row)" />
                  <svg-icon icon-class="dev-delete" style="margin:0 14px;" @click="deleteHandle(scope.row)" />
                  <svg-icon icon-class="edit" @click="updateHandle(scope.row)" />
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <div class="total">合计：{{ totalPage }}</div>
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              layout="sizes"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <div class="jumper">
              <div>{{ pageNum }}/{{ pagenumber }}</div>
              <div class="jumper-line" />
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                background
                layout="jumper"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              background
              layout="prev, next"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <el-form ref="basicForm" :rules="basicRules" :model="basicForm" size="small">
          <ai-box class="content-box">
            <div class="dev-src">
              <el-form-item prop="name" label="环境名称" label-width="6em">
                <el-input v-model="basicForm.name" />
              </el-form-item>
              <el-form-item prop="namespace" label="命名空间" label-width="6em">
                <el-select v-model="basicForm.namespace" filterable @change="namespaceChange">
                  <el-option
                    v-for="item in namespaceList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-form-item prop="image" label="镜像" label-width="6em">
                    <el-select v-model="basicForm.image" @change="imageChange">
                      <el-option v-for="(item, i) in imageList" :key="i" :label="item.name" :value="item.name" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="version" label="" label-width="0.5em">
                    <el-select v-model="basicForm.version">
                      <el-option v-for="(item, i) in versionList" :key="i" :label="item.name" :value="item.name" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-for="(item, index) in basicForm.port" :key="index">
                <el-col :span="item.portType == 2 ? 7 : 10">
                  <el-form-item
                    prop="localPort"
                    :label="index == 0 ? '容器端口' : ''"
                    label-width="6em"
                    :class="{ 'is-required': index == 0 }"
                  >
                    <el-input v-model="item.localPort" placeholder="容器端口" />
                  </el-form-item>
                </el-col>
                <el-col :span="item.portType == 2 ? 7 : 10">
                  <el-form-item prop="portType" label="" label-width="0.5em">
                    <el-select v-model="item.portType" @change="portTypeChange">
                      <el-option label="动态端口" value="1" />
                      <el-option label="自定义端口" value="2" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col v-show="item.portType == 2" :span="7">
                  <el-form-item prop="targetPort" label="" label-width="0.5em">
                    <el-input
                      v-model="item.targetPort"
                      placeholder="访问端口"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <div class="port-btn">
                    <svg-icon
                      v-show="basicForm.port.length > 1"
                      class="remove-item"
                      icon-class="del-input"
                      @click="removePort(item)"
                    />
                    <svg-icon
                      icon-class="add-input"
                      class="add-item"
                      @click="addPort"
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
          </ai-box>
          <ai-box :title="'资源配置'" :is-title="isTitle" class="content-box src-config">
            <el-form-item prop="src" label="资源组" label-width="6em">
              <el-select v-model="basicForm.src">
                <el-option
                  v-for="(item, i) in resourcesGroup"
                  :key="i"
                  :label="item.resourceName"
                  :value="item.resourceName"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="type" label="CPU/GPU" label-width="6em">
              <!-- <el-radio-group v-model="basicForm.type">
                <el-radio label="16/1" />
                <el-radio label="32/2" />
                <el-radio label="64/4" />
              </el-radio-group> -->
              <el-select v-model="basicForm.type" placeholder="请选择">
                <el-option
                  v-for="item in quotaOption"
                  :key="item.id"
                  :label="item.cpu + '/' + item.gpu"
                  :value="item.id"
                />
              </el-select>

            </el-form-item>
          </ai-box>
          <ai-box :title="'辅助设置'" :is-title="isTitle" class="content-box src-config">
            <el-form-item prop="entrypoint" label="进入点" label-width="6em">
              <el-radio-group
                v-model="basicForm.entrypointRadio"
                @change="entrypointChange"
              >
                <el-radio label="默认镜像" />
                <el-radio label="自定义" />
              </el-radio-group>
            </el-form-item>
            <div v-show="basicForm.entrypointRadio == '自定义'" class="custom">
              <el-form-item prop="entrypoint" label-width="6em">
                <el-input
                  v-model="basicForm.entrypoint"
                  placeholder=""
                  size="small"
                />
              </el-form-item>
            </div>
            <el-form-item prop="command" label="启动命令" label-width="6em">
              <el-radio-group v-model="basicForm.command" @change="cmdChange">
                <el-radio label="默认镜像" />
                <el-radio label="自定义" />
              </el-radio-group>
            </el-form-item>
            <div v-show="basicForm.command=='自定义'" class="custom">
              <el-form-item
                v-for="(item, index) in basicForm.cmdList"
                :key="index"
                label-width="6em"
              >
                <el-input v-model="item.value" placeholder="" size="small" />
                <svg-icon v-show="basicForm.cmdList.length>1" class="remove-item" icon-class="del-input" @click="removeCmd(item)" />
                <svg-icon icon-class="add-input" class="add-item" @click="addCmd" />
              </el-form-item>
            </div>

          </ai-box>
          <ai-box :title="'高级设置'" :is-title="isTitle" class="content-box advanced-setting">
            <el-form-item prop="shmSize" label="shm-size" label-width="7em">
              <el-input v-model="basicForm.shmSize" class="shm-size" />GB
            </el-form-item>
            <el-form-item prop="copyNum" label="副本数" label-width="7em">
              <el-input v-model="basicForm.copyNum" />
            </el-form-item>
          </ai-box>
        </el-form>
      </div>
    </div>
    <div class="dialog-container">
      <el-dialog
        v-model="dialogVisible"
        :title="dialogTitle"
        :close-on-click-modal="false"
        :before-close="handleCloseDialog"
        width="1000px"
      >
        <div class="dialog-content">
          <devEnvironmentDialog :row="rowInfo" :dialog-visible="dialogVisible" :projectid="projectId" />
        </div>
      </el-dialog>
    </div>
    <!-- 修改副本数 -->
    <div class="dialog-container">
      <el-dialog
        v-model="updateDialogVisible"
        title="修改副本数"
        :close-on-click-modal="false"
        :before-close="handleCloseUpdateDialog"
        width="500px"
      >
        <div class="dialog-content">
          <el-form ref="formData" :rules="formDataRules" :model="formData" size="small">
            <div class="dialog-form">
              <el-form-item prop="name" label="环境名称" label-width="7em">
                <el-input v-model="formData.name" disabled placeholder="请输入环境名称" />
              </el-form-item>
              <el-form-item prop="replicasTotal" label="副本数" label-width="7em">
                <el-input v-model="formData.replicasTotal" placeholder="请输入副本数" />
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="dialog-footer">
          <!-- <el-button size="small" @click="handleCancelPhone">取 消</el-button> -->
          <el-button :loading="updateConfirmLoading" type="primary" style="color:#fff" @click="handleUpdateConfirm">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { checkPortAvailable, getAllArtifactByNamespace, getAllNameSpace, getAllImageByNamespace, getResourceList, checkPort, getQuota, createDevlopmentenv, getDevlopmentenv, deleteDevlopmentenv, updateDevlopmentenv, actionDevlopmentenv, getUserProjectGroup } from '@/api/ai'
import aiBox from '../components/AiBox.vue'
import devEnvironmentDialog from '../components/DevEnvironmentDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import validate from '@/utils/baseValidate.js'
export default {
  components: { aiBox, devEnvironmentDialog },
  data() {
    const checkTargetPort = (rule, value, callback) => {
      const reg = /^[1-9]\d*$/
      if (!value) { // 所以当没有值的时候，我们直接callback，让他不校验直接执行下一步
        return callback()
      } else {
        if (reg.test(value)) { // 验证通过也下一步
          checkPort({ port: value }).then((res) => {
            if (res.meta.status === 456) {
              return callback()
            } else {
              callback(new Error(res.meta.msg))
            }
          })
          // return callback()
        } else {
          callback(new Error('请输入正确的数字')) // 验证不通过下一步只是抛出个错误
        }
      }
    }
    // 副本数校验
    const checkReplicasTotal = (rule, value, callback) => {
      const reg = /^[1-9]\d*$/
      if (!value) {
        callback(new Error('请输入副本数'))
      } else {
        if (reg.test(value)) { // 验证通过也下一步
          return callback()
        } else {
          callback(new Error('请输入正确的副本数')) // 验证不通过下一步只是抛出个错误
        }
      }
    }
    return {
      isLoading: false,
      projectGroupList: [],
      projectId: null,
      // 弹窗相关参数
      dialogTitle: '',
      dialogVisible: false,
      //
      isTitle: true,
      pageSize: 10,
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      // 开发环境
      devList: [{
        icon: 'dev-total-bg',
        title: '开发环境数量',
        number: '0',
        unit: '个'
      }, {
        icon: 'dev-active-bg',
        title: '活跃数',
        number: '0',
        unit: '个'
      }, {
        icon: 'cpu-bg',
        title: 'CPU申请',
        number: '0',
        unit: '核'
      }, {
        icon: 'gpu-bg',
        title: 'GPU申请',
        number: '0',
        unit: '卡'
      }],
      devData: [],
      versionList: [],
      imageList: [],
      basicForm: {
        name: '',
        image: '',
        version: '',
        src: '',
        type: '',
        shmSize: '4',
        copyNum: '1',
        portType: '1',
        localPort: '',
        targetPort: '',
        port: [
          {
            portType: '1',
            localPort: '',
            targetPort: ''
          }
        ],
        entrypoint: '',
        command: '默认镜像',
        cmdList: [{
          value: ''
        }],
        namespace: '',
        entrypointRadio: '默认镜像'

      },
      namespaceList: [],
      basicRules: {
        name: [{ required: true, validator: validate.envName, trigger: 'blur' }],
        // entrypoint: [{ required: true, trigger: 'blur', message: '请输入进入点' }],
        command: [{ required: true, trigger: 'change', message: '请选择启动命令' }],
        // localPort: [
        //   { required: true, trigger: 'blur', message: '请输入本地端口' },
        //   { trigger: 'blur', validator: validate.num }
        // ],
        // targetPort: [
        //   { trigger: 'blur', validator: checkTargetPort }
        // ],
        namespace: [{ required: true, trigger: 'change', message: '请选择命名空间' }],
        image: [{ required: true, trigger: ['change', 'blur'], message: '请选择镜像' }],
        version: [{ required: true, trigger: ['change', 'blur'], message: '请选择镜像版本' }],
        src: [{ required: true, trigger: ['change', 'blur'], message: '请选择资源组' }],
        type: [{ required: true, trigger: 'change', message: '请选择CPU/GPU' }],
        shmSize: [
          { required: true, trigger: 'blur', message: '请输入shm-size' },
          { trigger: 'blur', validator: validate.num }
        ],
        copyNum: [{ required: true, trigger: 'blur', message: '请输入副本数' },
          { trigger: 'blur', validator: validate.num }]
      },
      repo: 'airepo',
      createBtnLoading: false,
      cpu: '',
      gpu: '',
      rowInfo: {},
      timer: null,
      // 修改副本数
      updateDialogVisible: false,
      updateConfirmLoading: false,
      formData: {
        name: '',
        replicasTotal: ''
      },
      formDataRules: {
        name: [
          { required: true, message: '请输入环境名称', trigger: 'blur' }
        ],
        replicasTotal: [
          { required: true, validator: checkReplicasTotal, trigger: 'blur' }
        ]
      },
      resourcesGroup: [],
      quotaOption: [],
      host: ''
    }
  },
  mounted() {
    // this.host = window.location.hostname
    // VUE_APP_SECRET
    if (process.env.VUE_APP_SECRET === 'dev') {
      this.host = '192.168.145.100'
    } else {
      this.host = window.location.host
    }
    this.getData()
    const that = this
    this.timer = setInterval(() => {
      that.getRefreshData()
    }, 10000)
    getQuota().then((response) => {
      this.quotaOption = response.data
    })
  },
  beforeUnmount() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    copySshPassword(password) {
      const input = document.createElement('input')
      input.value = password
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      ElMessage.success('复制成功')
    },
    openPortUrl(url, token) {
      // 如果url：后面的端口是8888则加/jupyter/jengcloud/lab否则不加

      url = 'http://' + url
      if (token) {
        url = url + '/jupyter/jengcloud/lab?token=' + token
      }
      console.log(url)
      window.open(url, '_blank')
    },
    addPort() {
      this.basicForm.port.push({
        portType: '1',
        localPort: '',
        targetPort: ''
      })
    },
    removePort(item) {
      var index = this.basicForm.port.indexOf(item)
      if (index !== -1) {
        this.basicForm.port.splice(index, 1)
      }
    },
    // 启动命令
    addCmd() {
      this.basicForm.cmdList.push({
        value: ''
      })
    },
    removeCmd(item) {
      var index = this.basicForm.cmdList.indexOf(item)
      if (index !== -1) {
        this.basicForm.cmdList.splice(index, 1)
      }
    },
    // 网络端口类型改变
    portTypeChange() {
      this.basicForm.targetPort = ''
      this.$refs['basicForm'].clearValidate(['targetPort'])
    },
    // 启动命令改变
    cmdChange() {
      this.basicForm.cmdList = [{
        value: ''
      }]
      this.$refs['basicForm'].clearValidate(['cmdList'])
    },
    openProjectGroup() {
      this.$refs.filterSelect.visible = false
      this.$store.dispatch('core/openApp', 8889162036467499)
    },
    // 项目组固定底部按钮
    visibleChange(visible) {
      // 下拉框显示隐藏
      if (visible) {
        const ref = this.$refs.filterSelect
        // 拿到下拉选项的对象
        let popper = ref.popperPaneRef
        // 在拿到它的DOM元素
        if (popper.$el) popper = popper.$el
        // 判断是否有添加按钮
        if (!Array.from(popper.children).some(v => v.className === 'select-btn-box')) {
          // 插入按钮
          const el = document.createElement('div')
          const confirmBtn = document.createElement('button')
          el.className = 'select-btn-box'
          el.style = `
                       font-size: 14px;
                       font-weight: 600;
                       height: 42px;
                       background: #fff;
                       color: #409eff;
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       cursor: pointer;
                       border-top:1px solid rgb(240 242 245)
                 `
          confirmBtn.className = 'select-confirm-mini-btn'
          confirmBtn.innerText = '项目组管理'
          confirmBtn.style = `background: #fff;`
          el.appendChild(confirmBtn)
          // 调用确认和取消函数
          // cancelBtn.onclick = () => cancelHandle()
          confirmBtn.onclick = () => this.openProjectGroup()
          el.addEventListener('mouseenter', function() {
            el.style.backgroundColor = '#F7F8FA'
            confirmBtn.style = `background: #F7F8FA;`
          })
          el.addEventListener('mouseleave', function() {
            el.style.backgroundColor = '#fff'
            confirmBtn.style = `background: #fff;`
          })
          popper.appendChild(el)
        }
      }
    },
    // 项目组列表改变
    projectGroupChange(val) {
      this.projectId = val
      this.pageNum = 1
      this.$nextTick(() => {
        this.getDevlopmentenv()
      })
    },
    // 获取数据
    getData() {
      this.isLoading = true
      this.projectGroupList = []
      getUserProjectGroup().then((response) => {
        this.projectGroupList = response.data.content
        this.$nextTick(() => {
          this.projectId = parseInt(sessionStorage.projectGroupId)
          this.getDevlopmentenv()
        })
      })
    },
    getDevlopmentenv() {
      this.isLoading = true
      this.getRefreshData()
    },
    namespaceChange(val) {
      // 获取镜像
      this.basicForm.image = ''
      this.basicForm.version = ''
      this.imageList = []
      this.versionList = []
      getAllImageByNamespace(val).then(res => {
        this.imageList = res.data
      })
    },
    getRefreshData() {
      // 镜像
      // this.imageList = []
      // getImagesList(this.repo).then((response) => {
      //   response.data.content.forEach(e => {
      //     e.name = e.name.split('/')[1]
      //     this.imageList.push(e.name)
      //   })
      // })
      // 命名空间
      this.namespaceList = []
      getAllNameSpace().then(res => {
        this.namespaceList = res.data
      })
      // 获取资源组信息
      getResourceList().then((response) => {
        this.resourcesGroup = response.data
      })
      // 获取开发环境列表数据
      const dev = {
        pagenum: this.pageNum,
        pagesize: this.pageSize,
        projectId: this.projectId
      }
      getDevlopmentenv(dev).then((response) => {
        let devData = []
        devData = response.data.content.map((e) => {
          return {
            ...e,
            host:
                this.host +
                ':' +
                (e.portList.find((i) => i.targetPort === 8888) || {}).nodePort,
            sshHost:
                this.host +
                ':' +
                (e.portList.find((i) => i.targetPort === 22) || {}).nodePort
          }
        })
        // 收集需要检查的端口，以及它所对应的devData的索引
        const portList = []
        devData.forEach((e, index) => {
          const jupyterHost = (e.portList.find((i) => i.targetPort === 8888) || {}).nodePort
          const sshHost = (e.portList.find((i) => i.targetPort === 22) || {}).nodePort
          if (sshHost) {
            portList.push({
              port: sshHost,
              index: index,
              type: 'ssh'
            })
          }
          if (jupyterHost) {
            portList.push({
              port: jupyterHost,
              index: index,
              type: 'jupyter'
            })
          }
        })
        // 并发调用检查端口是否可用
        this.concurRequest(portList, 2).then((res) => {
          res.forEach((e) => {
            const index = e.index
            const type = e.type
            if (e.meta.status === 200) {
              devData[index][type + 'Status'] = e.data
            }
          })
          this.devData = devData
          this.isLoading = false
        })
        this.devList[0].number = response.data.devEnvResource.devEnvCount
        this.devList[1].number = response.data.devEnvResource.activeDevEnvCount
        this.devList[2].number = response.data.devEnvResource.cpuUsage
        this.devList[3].number = response.data.devEnvResource.gpuUsage
        this.pagenumber = response.data.totalpages
        this.totalPage = response.data.totalelements
      }).catch(() => {
        this.isLoading = false
      })
    },
    concurRequest(portInfoList, maxNum) {
      return new Promise((resolve) => {
        if (portInfoList.length === 0) {
          resolve([])
          return
        }
        const results = []
        let index = 0
        const request = async() => {
          if (index >= portInfoList.length) {
            return
          }
          const i = index

          const portInfo = portInfoList[index]
          index++
          const resp = await checkPortAvailable(this.host, portInfo.port)
          try {
            results[i] = { ...resp, type: portInfo.type, index: portInfo.index }
          } catch {
            results[i] = { ...resp, type: portInfo.type, index: portInfo.index }
          } finally {
            const r = results.join().split(',')
            if (results.length === portInfoList.length && !r.some(element => element === '')) {
              resolve(
                results
              )
            }
            request()
          }
        }
        for (let index = 0; index < maxNum; index++) {
          request()
        }
      })
    },

    // 选择镜像改变
    imageChange(val) {
      this.versionList = []
      this.basicForm.version = ''
      // this.$refs.basicForm.resetFields()
      // getImagesVersionList(this.repo, val).then((response) => {
      //   response.data.content.forEach(e => {
      //     this.versionList.push(e.version)
      //   })
      // })
      // const imageName
      getAllArtifactByNamespace(this.basicForm.namespace, val).then(res => {
        this.versionList = res.data
      })
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false
    },
    // 创建
    createHandle() {
      const params = {
        projectId: this.projectId
      }
      var cmdList = []
      if (this.basicForm.command === '默认镜像') {
        cmdList = []
      } else {
        if (this.basicForm.cmdList[0].value !== '') {
          this.basicForm.cmdList.forEach((e) => {
            if (e.value !== '') {
              cmdList.push(e.value)
            }
          })
        }
      }
      // var targetPort = ''
      // if (this.basicForm.portType === '2') {
      //   targetPort = this.basicForm.targetPort
      // }
      // const type = this.basicForm.type.split('/')
      // this.cpu = type[0]
      // this.gpu = type[1]

      const data = {
        envName: this.basicForm.name,
        imageName: this.basicForm.namespace + '/' + this.basicForm.image,
        imageVersion: this.basicForm.version,
        shmSize: parseInt(this.basicForm.shmSize),
        mem: 4,
        resourcesGroup: this.basicForm.src,
        // cpu: parseInt(this.cpu),
        // gpu: parseInt(this.gpu),
        resourceLimitId: this.basicForm.type,
        replicas: parseInt(this.basicForm.copyNum),
        entrypoint: this.basicForm.entrypointRadio === '默认镜像' ? '' : this.basicForm.entrypoint,
        cmdList: cmdList,
        servicePortDTOList: this.basicForm.port
          .filter((i) => i.localPort !== '')
          .map((e) => {
            return {
              localPort: parseInt(e.localPort),
              targetPort: e.targetPort ? parseInt(e.targetPort) : ''
            }
          })
      }
      this.$refs.basicForm.validate((valid) => {
        if (valid) {
          if (this.basicForm.command === '自定义' && this.basicForm.cmdList[0].value === '') {
            ElMessage.warning('请填写自定义命令')
            return
          }
          if (data.servicePortDTOList.length === 0) {
            ElMessage.warning('请选择端口')
            return
          }
          if (
            data.servicePortDTOList.findIndex((i) => i.localPort === 8888) ===
            -1
          ) {
            data.servicePortDTOList.push({
              localPort: 8888,
              targetPort: ''
            })
          }
          if (
            data.servicePortDTOList.findIndex((i) => i.localPort === 22) === -1
          ) {
            data.servicePortDTOList.push({
              localPort: 22,
              targetPort: ''
            })
          }
          this.createBtnLoading = true
          createDevlopmentenv(data, params).then((response) => {
            if (response.meta.status === 201) {
              this.createBtnLoading = false
              ElMessage.success(response.meta.msg)
              this.$nextTick(() => {
                this.getDevlopmentenv()
                this.$refs.basicForm.resetFields()
                this.basicForm.port = [
                  {
                    portType: '1',
                    localPort: '',
                    targetPort: ''
                  }
                ]
                this.basicForm.entrypointRadio = '默认镜像'
                this.imageList = []
                this.versionList = []
                setTimeout(() => {
                  this.$refs.basicForm.clearValidate()
                }, 0)
              })
            } else {
              this.createBtnLoading = false
              ElMessage.error(response.meta.msg)
              // this.$refs.basicForm.resetFields()
            }
          }).catch(() => {
            this.createBtnLoading = false
            // this.$refs.basicForm.resetFields()
          })
        }
      })
    },
    // 点击表格某一行
    rowClick(row) {
      // this.rowInfo = row
      // this.dialogTitle = row.envName
      // this.dialogVisible = true

      this.$store.commit('ai/SET_DEV_ENVIRONMENT_ROW', {
        row: { ...row, projectName: this.projectGroupList.find(e => e.id === this.projectId).name
        },
        projectid: this.projectId
      })
      this.$store.dispatch('core/openApp', 2558064662386393)
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getDevlopmentenv()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getDevlopmentenv()
    },
    // 停止挂起
    actionHandle(row) {
      const data = {
        projectId: this.projectId,
        devEnvName: row.envName,
        action: row.relicasActive >= 1 ? 'STOP' : 'START'
      }
      ElMessageBox.confirm(row.relicasActive >= 1 ? '确定要挂起该环境吗？' : '确定要启动该环境吗？', row.relicasActive >= 1 ? '挂起提醒' : '启动提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          actionDevlopmentenv(data).then((response) => {
            if (response.meta.status === 201) {
              ElMessage.success(response.meta.msg)
              if (row.relicasActive >= 1) {
                this.$nextTick(() => {
                  this.getDevlopmentenv()
                })
              } else {
                const that = this
                setTimeout(() => {
                  that.getDevlopmentenv()
                }, 3000)
              }
            }
          })
        })
        .catch(() => {})
    },
    // 删除
    deleteHandle(row) {
      const data = {
        projectId: this.projectId,
        devEnvName: row.envName
      }
      ElMessageBox.confirm('确定要删除该环境吗？', '删除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteDevlopmentenv(data).then((response) => {
            if (response.meta.status === 204) {
              ElMessage.success(response.meta.msg)
              this.$nextTick(() => {
                this.getDevlopmentenv()
              })
            }
          })
        })
        .catch(() => {
          // ElMessage({
          //   type: "info",
          //   message: "已取消删除",
          // });
        })
    },
    // 修改副本数
    updateHandle(row) {
      this.updateDialogVisible = true
      this.formData.name = row.envName
      this.formData.replicasTotal = row.replicasTotal
    },
    handleCloseUpdateDialog() {
      this.updateDialogVisible = false
    },
    handleUpdateConfirm() {
      this.updateConfirmLoading = true
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const data = {
            projectId: this.projectId,
            replicase: this.formData.replicasTotal,
            envName: this.formData.name
          }
          updateDevlopmentenv(data).then(res => {
            if (res.meta.status === 201) {
              this.getDevlopmentenv()
              ElMessage.success(res.meta.msg)
              this.updateConfirmLoading = false
              this.updateDialogVisible = false
            } else {
              ElMessage.error(res.meta.msg)
              this.updateConfirmLoading = false
            }
          })
        } else {
          this.updateConfirmLoading = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.dev-environment{
  padding: 16px 24px 25px;
  height: 100%;
  width: 100%;
  overflow: auto;
  .port-btn {
    line-height: 32px;
    .remove-item,
    .add-item {
      margin-left: 10px;
    }
  }
  ::v-deep .el-loading-mask{
    z-index: 9;
  }
  .dialog-container .dialog-footer{
    text-align: right;
    padding-top: 10px;
    ::v-deep .el-icon{
      color: #fff;
    }
  }
  .dialog-container{
    ::v-deep .el-icon{
      font-size: 16px;
      color: #595F67;
    }
    ::v-deep .el-dialog__title{
      color: #595F67;
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
    }
    ::v-deep .el-dialog__header{
      padding-left: 45px;
    }
    ::v-deep .el-dialog__body{
      padding: 16px 16px 24px 16px;
    }
    ::v-deep .el-dialog .el-dialog__body{
      overflow-x: auto;
      max-height: 540px;
    }
  }
  .btn-box{
    // text-align: right;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
    min-width: 1465px;
    ::v-deep .el-input__wrapper{
      height: 32px;
      line-height: 32px;
      background-color: #F7F8FA;
      border: none;
      box-shadow: none;
    }
    ::v-deep .el-select{
      width: 300px;
      .el-icon{
        color: #4E5969;
        font-size: 12px;
      }
      .el-input__wrapper.is-focus{
        box-shadow: none !important;
      }
    }
    ::v-deep .el-select .el-input__inner{
      box-shadow: none !important;
      background-color: #F7F8FA !important;
    }
    ::v-deep .el-select .el-select__wrapper.is-focus{
      box-shadow: none !important;
    }
    ::v-deep .el-select .el-input.is-focus .el-input__inner{
      box-shadow: none !important;
      background-color: #F7F8FA !important;
    }
    ::v-deep .el-button {
      background-color: #437aec;
      color: #fff;
      padding: 7px 16px;
      border-radius: 2px;
      min-height: 34px;
      height: 34px;
    }
    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
  }
  .dev-content{
    display: flex;
    width: 100%;
    min-width: 1465px;
    .left{
      width: 56%;
      margin-right: 16px;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #CFD3E3;
      padding: 36px 10px 38px 12px;
      ::v-deep .el-table{
        z-index: 0;
      }
      .dev-left-top{
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;
        .dev-left-box{
          margin-right: 30px;
          margin-bottom: 16px;
        }
        .box-content{
          display: flex;
          .svg-icon{
            width: 48px;
            height: 48px;
            margin-right: 8px;
          }
        }
        .dev-left-box{
          font-family: PingFang SC-Regular, PingFang SC;
          font-size: 14px;
          font-weight: 500;
          color: #303133;
          .content-title{
            height: 22px;
            font-weight: 400;
            color: #909399;
            line-height: 22px;
          }
          .number{
            font-size: 16px;
          }
          .unit{
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
    .right{
      width: calc(44% - 16px);
      color: #606266;
      ::v-deep .el-input__wrapper{
        border: none;
        box-shadow: none;
        height: 32px;
        line-height: 32px;
      }
      ::v-deep .el-form-item--small .el-form-item__label{
        height: 32px;
        line-height: 32px;
      }
      ::v-deep .el-radio__input.is-checked .el-radio__inner{
        background: #3686FF;
        border-color: #437AEC;
      }
      ::v-deep .el-radio__input.is-checked+.el-radio__label{
        color: #1D2129;
      }
      ::v-deep .el-checkbox{
        color: #1D2129;
      }
      ::v-deep .el-form-item__label{
        color: #4E5969;
      }
      .dev-src{
        padding: 20px 52px;
        padding-bottom: 2px;
        .el-select--small{
          width: 100%;
        }
      }
      .content-box{
        margin-bottom: 30px;
        .custom{
          .remove-item,
          .add-item{
            margin-left: 10px;
          }
         ::v-deep .el-input,
         .el-input__inner{
            width: calc(100% - 50px);
          }
        }
      }
      .content-box:last-child{
        margin-bottom: 0;
      }
      .src-config{
        padding: 61px 52px 7px 41px;
      }
      .advanced-setting{
        padding: 36px 0 2px 46px;
        .el-input{
          max-width: 112px;
        }
        .shm-size{
          width: 57px;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
