import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
// import { resetRouter } from '@/router'
// import { ElMessage } from 'element-plus'

const getDefaultState = () => {
  return {
    // token: sessionStorage.desktopToken,
    token: getToken(),
    name: '',
    avatar: '',
    uId: '',
    email: '',
    mobile: '',
    auth: '',
    filePreviewToken: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, account) => {
    state.name = account
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_UID: (state, uId) => {
    state.uId = uId
  },
  SET_AUTH: (state, auth) => {
    state.auth = auth
  },
  GET_EMAIL: (state, email) => {
    state.email = email
  },
  GET_MOBILE: (state, mobile) => {
    state.mobile = mobile
  },
  SET_FILE_PREVIEW_TOKEN: (state, token) => {
    state.filePreviewToken = token
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { account, password, codeKey, code, phoneCode } = userInfo
    return new Promise((resolve, reject) => {
      login({ account: account.trim(), password: password, codeKey: codeKey, code: code, phoneCode: phoneCode }).then(response => {
        if (response.meta.status === 201) {
          const { data } = response
          // sessionStorage.desktopToken = data.token
          commit('SET_TOKEN', data.token)
          setToken(data.token)
        }
        resolve(response)
      }).catch(error => {
        // ElMessage.error(error.message)
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response
        if (!data) {
          return reject('认证失败，请重新登录.')
        }

        const { account, avatar, uId, email, mobile, auth } = data

        commit('SET_NAME', account)
        commit('SET_AVATAR', avatar)
        commit('SET_UID', uId)
        commit('SET_AUTH', auth)
        commit('GET_EMAIL', email)
        commit('GET_MOBILE', mobile)
        localStorage.uId = data.uId
        resolve(data)
      }).catch(error => {
        // ElMessage.error(error.message)
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        removeToken() // must remove  token  first
        // sessionStorage.removeItem('desktopToken')
        // resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        // ElMessage.error(error.message)
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      // sessionStorage.removeItem('desktopToken')
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

