<template>
  <div class="ai-log-container">
    <div id="aiLogContent" ref="aiLogContent" style="width:100%;height:100%;" />
  </div>
</template>

<script>
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import 'xterm/css/xterm.css'
import 'xterm/lib/xterm.js'
export default {
  props: {
    nameSpace: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      baseUrl: 'wss://192.168.132.234:9090/slurm/log?path=',
      init: true,
      token: '',
      style: {
        height: '',
        width: ''
      },
      windowHeight: '',
      windowWidth: '',
      fitAddon: '',
      logRadio: '1',
      logSearchInput: 500
    }
  },
  beforeUnmount() {
    this.clear()
  },
  mounted() {
    const that = this
    const elementResizeDetectorMaker = require('element-resize-detector')// 导入
    // 创建实例
    const erd = elementResizeDetectorMaker()
    const i = document.getElementsByClassName('ai-log-container').length - 1
    erd.listenTo(document.getElementsByClassName('ai-log-container')[i], function(element) {
      try {
        setTimeout(() => {
          if (that.fitAddon !== '') {
            that.fitAddon.fit()
          }
        }, 100)
      } catch (error) {
        console.log(error)
      }
    })
  },
  methods: {
    initLog() {
      // 调查询日志接口
      this.$nextTick(() => {
        if (this.init) {
          this.initXterm()
          this.init = false
        }
      })
      this.getLogs()
    },
    initXterm() {
      // const cols = document.getElementsByClassName('window-body')[1].offsetWidth / 9;
      this.term = new Terminal({
        // 渲染类型
        rendererType: 'canvas',
        // cols: parseInt(cols),
        convertEol: true,
        cursorStyle: 'bar',
        row: 500,
        // 终端中的回滚量
        scrollback: 100000,
        disableStdin: true
        // theme: {
        //   foreground: '#00ff00',
        //   background: '#060101',
        //   cursor: 'help'
        // }
      })
      const fitAddon = new FitAddon()
      this.fitAddon = fitAddon
      this.term.loadAddon(fitAddon)
      this.term.open(this.$refs.aiLogContent)
      const that = this
      setTimeout(() => {
        fitAddon.fit()
        that.fitAddon = fitAddon
      }, 500)
      this.term.focus()
      this.term.attachCustomKeyEventHandler(e => {
        if (e.ctrlKey && e.keyCode === 76) {
          this.term.clear()
          fitAddon.fit()
        }
      })
    },
    getLogs() {
      this.token = sessionStorage.desktopToken
      var ishttps = document.location.protocol == 'https:'
      let url = ''
      if (ishttps) {
        url = 'wss:' + location.host + '/aisocket/ws/container/log?name=' + this.name + '&namespace=' + this.nameSpace +
        '&regionId=' + localStorage.regionId + '&token=' + this.token
      } else {
        url = 'ws:' + location.host + '/aisocket/ws/container/log?name=' + this.name + '&namespace=' + this.nameSpace +
        '&regionId=' + localStorage.regionId + '&token=' + this.token
      }

      this.ws = new WebSocket(url)
      this.ws.onopen = () => {
        console.log('open ws success')
      }
      this.ws.onmessage = (res) => {
        this.term.write(res.data)
      }
      this.ws.onerror = () => {
        console.log('failed to open ws')
      }
    },
    clear() {
      if (this.ws) {
        this.ws.close()
        this.ws = {}
      }
      if (this.term) {
        this.term.reset()
      }
      this.row = ''
    }
  }
}
</script>

<style lang="less" scoped>
  .ai-log-container{
    width: 100%;
    height: 100%;
    background-color: #000;
  }

</style>
