<template>
  <div :id="name" ref="chart" style="width: 100%; height: 100%;" />
</template>

<script>
const echarts = require('echarts')
const option = {
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '10%',
    containLabel: true
  },
  legend: {
    bottom: '2%',
    left: 'center',
    icon: 'circle'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['10.05', '10.10', '10.15', '10.20', '10.25', '10.30', '10.35'],
    axisTick: {
      show: false
    }
  },
  yAxis: {
    name: '%',
    nameLocation: 'end',
    type: 'value',
    min: 0,
    max: 100,
    splitNumber: 5,
    axisTick: {
      show: false
    }
  },
  series: [
    {
      name: 'node41',
      type: 'line',
      smooth: true,
      data: [80, 82, 79, 85, 82, 85, 90],
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(100, 255, 236,0.12)'
          },
          {
            offset: 1,
            color: 'rgba(52, 255, 253,0)'
          }
        ])
      },
      itemStyle: {
        color: '#37E2E2'
      },
      symbol: 'none'
    },
    {
      name: 'node42',
      type: 'line',
      smooth: true,
      symbol: 'none',
      data: [75, 70, 76, 82, 82, 89, 85],
      itemStyle: {
        color: '#165DFF'
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(100, 255, 236,0.12)'
          },
          {
            offset: 1,
            color: 'rgba(52, 255, 253,0)'
          }
        ])
      }
    }
  ]
}
export default {
  name: 'TaskEchartsLine1',
  props: {
    isShowNodeDetail: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    xData: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    }

  },
  data() {
    return {
      chart: null

    }
  },
  computed: {
    seriesData() {
      return {
        isShowNodeDetail: this.isShowNodeDetail,
        data: this.data,
        xData: this.xData
      }
    }
  },
  watch: {
    seriesData: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.initChart()
          })
        }
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    initChart() {
      if (!this.chart) {
        this.chart = echarts.init(document.getElementById(this.name))
        const elementResizeDetectorMaker = require('element-resize-detector')// 导入
        const erd = elementResizeDetectorMaker()
        // 创建实例
        erd.listenTo(document.getElementById(this.name), (element) => {
          try {
            setTimeout(() => {
          this.chart?.resize()
            }, 100)
          } catch (error) {
            console.log(error)
          }
        })
      }
      option.series = this.data.map(item => {
        return {
          name: item.nodeName,
          type: 'line',
          smooth: true,
          data: item.data,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(100, 255, 236,0.12)'
              },
              {
                offset: 1,
                color: 'rgba(52, 255, 253,0)'
              }
            ])
          },
          itemStyle: {
            color: '#37E2E2'
          },
          symbol: 'none'
        }
      })
      option.xAxis.data = this.xData
      this.chart.setOption(option)
    }
  }
}
</script>

<style>

</style>
