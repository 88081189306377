import request from '@/utils/requestPreview'
export function login() {
  return request({
    url: 'api/login',
    method: 'post',
    data: {
      'username': 'jengcloud',
      'password': 'jengcloud@0199',
      'recaptcha': ''
    }
  })
}
