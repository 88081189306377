<template>
  <div v-loading="isLoading" class="training-management">
    <div class="btn-box">
      <div>
        <el-select ref="selectRef" v-model="projectId" @change="projectGroupChange" @visible-change="visibleChange">
          <el-option v-for="(item, i) in projectGroupList" :key="i" :label="item.name" :value="item.id" />
        </el-select>
      </div>
      <el-button
        @click="createHandle"
      ><svg-icon icon-class="start-train" />开始训练</el-button>
    </div>
    <div class="train-content">
      <div class="left">
        <div class="train-left-top">
          <div v-for="(item,i) in trainList" :key="i" class="train-left-box">
            <div class="box-content">
              <div>
                <svg-icon :icon-class="item.icon" />
              </div>
              <div>
                <div class="content-title">{{ item.title }}</div>
                <div><span class="number">{{ item.number }}</span><span class="unit">{{ item.unit }}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="train-left-bottom">
          <el-table
            :data="trainData"
            style="width: 100%; height: auto"
          >
            <el-table-column prop="taskName" label="任务名称" align="center" show-overflow-tooltip />
            <el-table-column
              prop="status"
              label="状态"
              align="center"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div
                  v-if="scope.row.status === 'Running'"
                  style="color: #165DFF"
                >
                  <div>{{ scope.row.status }}</div>
                </div>
                <div
                  v-else-if="scope.row.status === 'Pending'"
                  style="color: #FD8E3F"
                >
                  <div>{{ scope.row.status }}</div>
                </div>
                <div
                  v-else-if="scope.row.status === 'stop' || scope.row.status === 'pause'"
                  style="color: #909399"
                >
                  <div>{{ scope.row.status }}</div>
                </div>
                <div
                  v-else
                  style="color: #FF1E1E"
                >
                  <div>{{ scope.row.status }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="runningCost"
              align="center"
              label="运行时长(m)"
              show-overflow-tooltip
            />
            <el-table-column
              prop="nodeName"
              label="节点"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="srcConfig"
              label="资源配置"
              align="center"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div>{{ scope.row.cpuCout }}/{{ scope.row.gpuCount }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="image"
              label="镜像"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="submitTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              label="操作"
              width="80"
              align="center"
            >
              <template #default="scope">
                <div>
                  <svg-icon icon-class="dev-stop" @click="stopHandle(scope.row)" />
                  <!-- <svg-icon icon-class="dev-delete" @click="deleteHandle(scope.row)" /> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <div class="total">合计：{{ totalPage }}</div>
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              layout="sizes"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <div class="jumper">
              <div>{{ pageNum }}/{{ pagenumber }}</div>
              <div class="jumper-line" />
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                background
                layout="jumper"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              background
              layout="prev, next"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <el-form ref="basicForm" :rules="basicRules" :model="basicForm" size="small">
          <ai-box class="content-box">
            <div class="train-src">
              <el-form-item prop="name" label="任务名称" label-width="6em">
                <el-input v-model="basicForm.name" />
              </el-form-item>
              <el-form-item prop="namespace" label="命名空间" label-width="6em">
                <el-select v-model="basicForm.namespace" filterable @change="namespaceChange">
                  <el-option
                    v-for="item in namespaceList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-form-item prop="image" label="镜像" label-width="6em">
                    <el-select v-model="basicForm.image" @change="imageChange">
                      <el-option v-for="(item, i) in imageList" :key="i" :label="item.name" :value="item.name" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="version" label="" label-width="0.5em">
                    <el-select v-model="basicForm.version">
                      <el-option v-for="(item, i) in versionList" :key="i" :label="item.name" :value="item.name" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </ai-box>
          <ai-box :title="'资源配置'" :is-title="isTitle" class="content-box src-config">
            <el-form-item prop="src" label="资源组" label-width="6em">
              <el-select v-model="basicForm.src">
                <el-option
                  v-for="(item, i) in resourcesGroup"
                  :key="i"
                  :label="item.resourceName"
                  :value="item.resourceName"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="type" label="CPU/GPU" label-width="6em">
              <!-- <el-radio-group v-model="basicForm.type">
                <el-radio label="16/1" />
                <el-radio label="32/2" />
                <el-radio label="64/4" />
              </el-radio-group> -->
              <el-select v-model="basicForm.type" placeholder="请选择">
                <el-option
                  v-for="item in quotaOption"
                  :key="item.id"
                  :label="item.cpu + '/' + item.gpu"
                  :value="item.id"
                />
              </el-select>

            </el-form-item>
          </ai-box>
          <ai-box :title="'启动配置'" :is-title="isTitle" class="content-box start-config">
            <el-form-item prop="python" label="python脚本" label-width="7em">
              <el-input v-model="basicForm.python">
                <template #append><svg-icon icon-class="choice-file" /></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="workDir" label="工作目录" label-width="7em">
              <el-input v-model="basicForm.workDir">
                <template #append><svg-icon icon-class="choice-file" /></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="dataSet" label="数据集" label-width="7em">
              <el-input v-model="basicForm.dataSet">
                <template #append><svg-icon icon-class="choice-file" /></template>
              </el-input>
            </el-form-item>
          </ai-box>
          <ai-box :title="'高级设置'" :is-title="isTitle" class="content-box advanced-setting">
            <el-row>
              <el-col :span="12">
                <el-form-item prop="shmSize" label="shm-size" label-width="8em">
                  <el-input v-model="basicForm.shmSize" class="shm-size" />GB
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="trainType" label="训练类型" label-width="8em">
                  <el-radio-group v-model="basicForm.trainType">
                    <el-radio label="单机" />
                    <el-radio label="分布式" />
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="basicForm.trainType==='分布式'">
              <el-col :span="12">
                <el-form-item prop="master" label="Master个数" label-width="8em">
                  <el-input v-model="basicForm.master" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="worker" label="Worker个数" label-width="8em">
                  <el-input v-model="basicForm.worker" />
                </el-form-item>
              </el-col>
            </el-row>
          </ai-box>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllArtifactByNamespace, getAllNameSpace, getAllImageByNamespace, getJob, getQuota, createJob, deleteJob, getUserProjectGroup, getResourceList } from '@/api/ai'
import aiBox from '../components/AiBox.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import validate from '@/utils/baseValidate.js'
export default {
  components: { aiBox },
  data() {
    const checkNum = (rule, value, callback) => {
      const reg = /^[1-9]\d*$/
      if (!value) {
        callback(new Error('请输入'))
      } else {
        if (reg.test(value)) { // 验证通过也下一步
          return callback()
        } else {
          callback(new Error('只能输入数字')) // 验证不通过下一步只是抛出个错误
        }
      }
    }
    return {
      repo: 'airepo',
      isLoading: false,
      projectGroupList: [],
      projectId: null,
      isTitle: true,
      pageSize: 10,
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      // 开发环境
      trainList: [{
        icon: 'task-total',
        title: '任务总量',
        number: '0',
        unit: '个'
      }, {
        icon: 'running',
        title: '运行中',
        number: '0',
        unit: '个'
      }, {
        icon: 'waiting',
        title: '等待中',
        number: '0',
        unit: '个'
      }, {
        icon: 'cpu-bg',
        title: 'CPU申请',
        number: '0',
        unit: '核'
      }, {
        icon: 'gpu-bg',
        title: 'GPU申请',
        number: '0',
        unit: '卡'
      }],
      trainData: [],
      versionList: [],
      imageList: [],
      basicForm: {
        name: '',
        image: '',
        version: '',
        src: '',
        type: '',
        trainType: '单机',
        shmSize: '4',
        master: '1',
        worker: '',
        python: '/usr/local/bin/stress -c 2',
        workDir: '/root',
        dataSet: '',
        namespace: ''
      },
      basicRules: {
        namespace: [{ required: true, trigger: 'change', message: '请选择命名空间' }],
        name: [{ required: true, validator: validate.envName, trigger: 'blur' }],
        image: [{ required: true, trigger: 'change', message: '请选择镜像' }],
        version: [{ required: true, trigger: 'change', message: '请选择镜像版本' }],
        src: [{ required: true, trigger: 'change', message: '请选择资源组' }],
        type: [{ required: true, trigger: 'change', message: '请选择CPU/GPU' }],
        trainType: [{ required: true, trigger: 'change', message: '请选择训练类型' }],
        python: [{ required: true, trigger: 'change', message: '请选择python脚本' }],
        workDir: [{ required: true, trigger: 'change', message: '请选择工作目录' }],
        shmSize: [{ required: true, trigger: 'blur', validator: checkNum }],
        master: [{ required: true, trigger: 'blur', validator: checkNum }],
        worker: [{ required: true, trigger: 'blur', validator: checkNum }]
      },
      timerJob: null,
      resourcesGroup: [],
      quotaOption: [],
      namespaceList: []
    }
  },
  mounted() {
    this.getData()
    const that = this
    this.timerJob = setInterval(() => {
      that.getRefreshData()
    }, 10000)
    getQuota().then((response) => {
      this.quotaOption = response.data
    })
  },
  beforeUnmount() {
    clearInterval(this.timerJob)
    this.timerJob = null
  },
  methods: {
    // 创建训练任务
    // 项目组固定底部按钮
    visibleChange(visible) {
      // 下拉框显示隐藏
      if (visible) {
        const ref = this.$refs.selectRef
        // 拿到下拉选项的对象
        let popper = ref.popperPaneRef
        // 在拿到它的DOM元素
        if (popper.$el) popper = popper.$el
        // 判断是否有添加按钮
        if (!Array.from(popper.children).some(v => v.className === 'select-btn-box')) {
          // 插入按钮
          const el = document.createElement('div')
          const confirmBtn = document.createElement('button')
          el.className = 'select-btn-box'
          el.style = `
                       font-size: 14px;
                       font-weight: 600;
                       height: 42px;
                       background: #fff;
                       color: #409eff;
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       cursor: pointer;
                       border-top:1px solid rgb(240 242 245)
                 `
          confirmBtn.className = 'select-confirm-mini-btn'
          confirmBtn.innerText = '项目组管理'
          confirmBtn.style = `background: #fff;`
          el.appendChild(confirmBtn)
          // 调用确认和取消函数
          // cancelBtn.onclick = () => cancelHandle()
          confirmBtn.onclick = () => this.openProjectGroup()
          el.addEventListener('mouseenter', function() {
            el.style.backgroundColor = '#F7F8FA'
            confirmBtn.style = `background: #F7F8FA;`
          })
          el.addEventListener('mouseleave', function() {
            el.style.backgroundColor = '#fff'
            confirmBtn.style = `background: #fff;`
          })
          popper.appendChild(el)
        }
      }
    },
    openProjectGroup() {
      this.$refs.selectRef.visible = false
      this.$store.dispatch('core/openApp', 8889162036467499)
    },
    // 项目组列表改变
    projectGroupChange(val) {
      this.projectId = val
      this.pageNum = 1
      this.$nextTick(() => {
        this.getTrainingData()
      })
    },
    getData() {
      this.isLoading = true
      this.projectGroupList = []
      getUserProjectGroup().then((response) => {
        this.projectGroupList = response.data.content
        // this.projectId = this.projectGroupList[0].id
        this.$nextTick(() => {
          this.projectId = parseInt(sessionStorage.projectGroupId)
          this.getTrainingData()
        })
      })
    },
    getTrainingData() {
      this.isLoading = true
      this.getRefreshData()
    },
    namespaceChange(val) {
      // 获取镜像
      this.basicForm.image = ''
      this.basicForm.version = ''
      this.imageList = []
      this.versionList = []
      getAllImageByNamespace(val).then(res => {
        this.imageList = res.data
      })
    },
    getRefreshData() {
      // 镜像
      // this.imageList = []
      // getImagesList(this.repo).then((response) => {
      //   response.data.content.forEach(e => {
      //     e.name = e.name.split('/')[1]
      //     this.imageList.push(e.name)
      //   })
      // })
      // 命名空间
      this.namespaceList = []
      getAllNameSpace().then(res => {
        this.namespaceList = res.data
      })
      // 获取资源组信息
      getResourceList().then((response) => {
        this.resourcesGroup = response.data
      })
      // 获取列表数据
      const dev = {
        pagenum: this.pageNum,
        pagesize: this.pageSize,
        projectId: this.projectId
      }
      getJob(dev).then((response) => {
        this.trainData = response.data.content
        this.trainList[0].number = response.data.jobResourceVO.taskCount
        this.trainList[1].number = response.data.jobResourceVO.runningCount
        this.trainList[2].number = response.data.jobResourceVO.pendingCount
        this.trainList[3].number = response.data.jobResourceVO.cpuCount
        this.trainList[4].number = response.data.jobResourceVO.gpuCount
        this.pagenumber = response.data.totalpages
        this.totalPage = response.data.totalelements
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    // 选择镜像改变
    imageChange(val) {
      this.versionList = []
      this.basicForm.version = ''
      // this.$refs.basicForm.resetFields()
      getAllArtifactByNamespace(this.basicForm.namespace, val).then(res => {
        this.versionList = res.data
      })
    },
    createHandle() {
      // const type = this.basicForm.type.split('/')
      // this.cpu = type[0]
      // this.gpu = type[1]
      const params = {
        projectId: this.projectId
      }
      const data = {
        envName: this.basicForm.name,
        imageName: this.basicForm.namespace + '/' + this.basicForm.image,
        imageVersion: this.basicForm.version,
        shmSize: parseInt(this.basicForm.shmSize),
        mem: 4,
        resourcesGroup: this.basicForm.src,
        // cpu: parseInt(this.cpu),
        // gpu: parseInt(this.gpu),
        resourceLimitId: this.basicForm.type,
        master: this.basicForm.trainType === '单机' ? null : parseInt(this.basicForm.master),
        worker: this.basicForm.trainType === '单机' ? null : parseInt(this.basicForm.worker),
        pyScript: this.basicForm.python,
        workDir: this.basicForm.workDir
      }
      this.$refs.basicForm.validate((valid) => {
        if (valid) {
          this.createBtnLoading = true
          createJob(data, params).then((response) => {
            console.log(JSON.stringify(response))
            if (response.meta.status === 201) {
              this.createBtnLoading = false
              ElMessage.success(response.meta.msg)
              this.$nextTick(() => {
                this.getTrainingData()
                this.$refs.basicForm.resetFields()
                setTimeout(() => {
                  this.$refs.basicForm.clearValidate()
                }, 0)
              })
            } else {
              this.createBtnLoading = false
              ElMessage.error(response.meta.msg)
              // this.$refs.basicForm.resetFields()
            }
          }).catch(() => {
            this.createBtnLoading = false
            // this.$refs.basicForm.resetFields()
          })
        }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getTrainingData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getTrainingData()
    },
    // 停止
    stopHandle(row) {
      const data = {
        projectId: this.projectId,
        taskNamep: row.taskName
      }
      ElMessageBox.confirm('确定要停止该条数据的训练吗？', '停止提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteJob(data).then((response) => {
            console.log(JSON.stringify(response))
            if (response.meta.status === 204) {
              ElMessage.success(response.meta.msg)
              this.$nextTick(() => {
                this.getTrainingData()
              })
            }
          })
        })
        .catch(() => {
          // ElMessage({
          //   type: "info",
          //   message: "已取消删除",
          // });
        })
    }
  }
}
</script>

<style lang="less" scoped>
.training-management{
  padding: 16px 24px 25px;
  height: 100%;
  width: 100%;
  overflow: auto;
  ::v-deep .el-loading-mask{
    z-index: 9;
  }
  .btn-box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
    min-width: 1315px;
    ::v-deep .el-input__wrapper{
      height: 32px;
      line-height: 32px;
      background-color: #F7F8FA;
      border: none;
      box-shadow: none;
    }
    ::v-deep .el-select{
      width: 300px;
      .el-icon{
        color: #4E5969;
        font-size: 12px;
      }
      .el-input__wrapper.is-focus{
        box-shadow: none !important;
      }
    }
    ::v-deep .el-select .el-input__inner{
      box-shadow: none !important;
      background-color: #F7F8FA !important;
    }
    ::v-deep .el-select .el-select__wrapper.is-focus{
      box-shadow: none !important;
    }
    ::v-deep .el-select .el-input.is-focus .el-input__inner{
      box-shadow: none !important;
      background-color: #F7F8FA !important;
    }
    ::v-deep .el-button {
      background-color: #437aec;
      color: #fff;
      padding: 7px 16px;
      border-radius: 2px;
      min-height: 34px;
      height: 34px;
    }
    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
  }
  .train-content{
    display: flex;
    width: 100%;
    min-width: 1315px;
    .left{
      width: 56%;
      margin-right: 16px;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #CFD3E3;
      padding: 36px 10px 38px 12px;
      ::v-deep .el-table{
        z-index: 0;
      }
      .train-left-top{
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;
        .train-left-box{
          margin-right: 30px;
          margin-bottom: 16px;
        }
        .box-content{
          display: flex;
          .svg-icon{
            width: 48px;
            height: 48px;
            margin-right: 8px;
          }
        }
        .train-left-box{
          font-family: PingFang SC-Regular, PingFang SC;
          font-size: 14px;
          font-weight: 500;
          color: #303133;
          .content-title{
            height: 22px;
            font-weight: 400;
            color: #909399;
            line-height: 22px;
          }
          .number{
            font-size: 16px;
          }
          .unit{
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
    .right{
      width: calc(44% - 16px);
      color: #606266;
      ::v-deep .el-input__wrapper{
        border: none;
        box-shadow: none;
        height: 32px;
        line-height: 32px;
      }
      ::v-deep .el-form-item--small .el-form-item__label{
        height: 32px;
        line-height: 32px;
      }
      ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
        background: #3686FF;
        border-color: #3686FF;
      }
      ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
        color: #1D2129;
      }
      ::v-deep .el-checkbox{
        color: #1D2129;
      }
      ::v-deep .el-form-item__label{
        color: #4E5969;
      }
      .train-src{
        padding: 20px 52px;
        padding-bottom: 2px;
        .el-select--small{
          width: 100%;
        }
      }
      .content-box{
        margin-bottom: 30px;
        ::v-deep .el-input-group__append, .el-input-group__prepend{
          background-color: #E7E7E7;
          border: none;
          padding: 0 13px;
        }
      }
      .content-box:last-child{
        margin-bottom: 0;
      }
      .src-config{
        padding: 61px 52px 7px 41px;
      }
      .start-config{
        padding: 40px 50px 20px 50px;
        .svg-icon{
          width: 16px;
          height: 16px;
        }
      }
      .advanced-setting{
        padding: 36px 22px 2px 46px;
        .el-input{
          max-width: 112px;
        }
        .shm-size{
          width: 57px;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
