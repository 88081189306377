const state = {
  devEnvironmentRow: {
    row: '',
    projectid: ''
  },
  uploadProjectId: '',
  modelCreate: 0
}
const mutations = {
  SET_DEV_ENVIRONMENT_ROW(state, row) {
    state.devEnvironmentRow = row
  },
  SET_UPLOAD_PROJECT_ID(state, projectId) {
    state.uploadProjectId = projectId
  },
  SET_MODEL_CREATE(state) {
    state.modelCreate++
  }
}
export default {
  state,
  mutations,
  namespaced: true
}
