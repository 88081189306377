import request from '@/utils/requestAi'
import requestAuth from '@/utils/requestAuth'
const auth = '/service-auth-provider/v2'
const authV1 = '/service-auth-provider/v1'
const k8s = '/service-kubernetes-provider/v1'
const harbor = '/service-harbor-provider'
// 获取首页数据
export function getDashboard(data) {
  return request({
    url: k8s + '/dashboard',
    method: 'get',
    params: data
  })
}
// 资源组
export function getDashboardNode(data) {
  return request({
    url: k8s + '/node/resource',
    method: 'get',
    params: data
  })
}
// 按gpu类型统计
export function getDashboardGpuType() {
  return request({
    url: k8s + '/node/gpu/type',
    method: 'get'
  })
}
// 配额
export function getDashboardQuota(data) {
  return request({
    url: k8s + '/quota',
    method: 'get',
    params: data
  })
}

// 镜像
// 获取所有镜像
export function getImagesList(repo, data) {
  return request({
    url: harbor + '/harbor/' + repo + '/images',
    method: 'get',
    params: data
  })
}

// 获取镜像版本信息
export function getImagesVersionList(repo, image, data) {
  return request({
    url: harbor + '/harbor/' + repo + '/' + image + '/versions',
    method: 'get',
    params: data
  })
}

// 开发环境
// 查询开发环境
export function getDevlopmentenv(data) {
  return request({
    url: k8s + '/devlopmentenv',
    method: 'get',
    params: data
  })
}

// 查询端口占用情况
export function checkPort(data) {
  return request({
    url: k8s + '/devlopmentenv/port/duplicate',
    method: 'get',
    params: data
  })
}

// 创建开发环境
export function createDevlopmentenv(data, params) {
  return request({
    url: k8s + '/devlopmentenv',
    method: 'post',
    data,
    params: params
  })
}
// 删除开发环境
export function deleteDevlopmentenv(data) {
  return request({
    url: k8s + '/devlopmentenv',
    method: 'delete',
    params: data
  })
}

// 挂起、启动开发环境
export function actionDevlopmentenv(data) {
  return request({
    url: k8s + '/devlopmentenv/action',
    method: 'patch',
    params: data
  })
}
// 修改副本数
export function updateDevlopmentenv(data) {
  return request({
    url: k8s + '/devlopmentenv/replicase',
    method: 'patch',
    params: data
  })
}
// 获取开发环境pod
export function getDevlopmentenvPod(data) {
  return request({
    url: k8s + '/devlopmentenv/pods',
    method: 'get',
    params: data
  })
}

// 获取开发环境资源利用率
export function getDevlopmentenvSrcDeatail(data) {
  return request({
    url: k8s + '/devlopmentenv/resource/usage/hist',
    method: 'get',
    params: data
  })
}

// 开发环境详情
export function getDevlopmentenvDetail(data) {
  return request({
    url: k8s + '/devlopmentenv/detail',
    method: 'get',
    params: data
  })
}

// 训练管理
// 查询训练任务
export function getJob(data) {
  return request({
    url: k8s + '/job',
    method: 'get',
    params: data
  })
}

// 创建训练任务
export function createJob(data, params) {
  return request({
    url: k8s + '/job',
    method: 'post',
    data,
    params: params
  })
}
// 删除
export function deleteJob(data) {
  return request({
    url: k8s + '/job',
    method: 'delete',
    params: data
  })
}
// 获取资源组列表
export function getResourceList() {
  return request({
    url: k8s + '/resource/name',
    method: 'get'
  })
}
// 项目组
// 查询所有项目组列表
export function getAllProjectGroup() {
  return requestAuth({
    url: auth + '/projectGroup/all',
    method: 'get'
  })
}
// 查询用户项目组列表
export function getUserProjectGroup(data) {
  return requestAuth({
    url: auth + '/projectGroup/self',
    method: 'get',
    params: data
  })
}
// 查询用户项目组列表创建的
export function getUserOwnerProjectGroup(data) {
  return requestAuth({
    url: auth + '/projectGroup/self/owner',
    method: 'get',
    params: data
  })
}
// 查询用户项目组列表成员的
export function getUserMemberProjectGroup(data) {
  return requestAuth({
    url: auth + '/projectGroup/self/member',
    method: 'get',
    params: data
  })
}
// 创建项目组
export function addProjectGroup(data) {
  return requestAuth({
    url: auth + '/projectGroup/new',
    method: 'post',
    data
  })
}
// 项目组信息
export function queryProjectGroupInfo(data) {
  return requestAuth({
    url: auth + '/projectGroup/info',
    method: 'get',
    params: data
  })
}

// 查询活跃用户
export function queryActiveUser(keyWord) {
  return requestAuth({
    url: authV1 + '/users?state=ACTIVE&keyWord=' + keyWord,
    method: 'get'
  })
}

// 更新项目组
export function updateProjectGroup(data, params) {
  return requestAuth({
    url: auth + '/projectGroup/info',
    method: 'patch',
    data,
    params: params
  })
}
// 获取cpu和Gpu配额
export function getQuota() {
  return request({
    url: k8s + '/resourceLimit/all',
    method: 'get'
  })
}

// 查询所有命名空间
export function getAllNamespaces() {
  return request({
    url: harbor + '/v1/namespace/client/selfAll',
    method: 'get'
  })
}
// 分页查询命名空间
export function getPagedNamespaces(page, pageSize, sort, name) {
  return request({
    url: harbor + '/v1/namespace/client/page',
    method: 'get',
    params: {
      page, pageSize, sort, name
    }
  })
}
// 查询命名空间数量
export function getNamespaceCount() {
  return request({
    url: harbor + '/v1/namespace/client/count',
    method: 'get'
  })
}
// 删除命名空间
export function deleteNamespace(projectNameOrId) {
  return request({
    url: harbor + '/v1/namespace/client/' + projectNameOrId,
    method: 'delete'
  })
}
// 获取镜像列表
export function getImageList(pageNum, pageSize, name, projectId, qName, sort) {
  return request({
    url: harbor + `/v1/image/ordinary/image`,
    method: 'get',
    params: {
      pageNum, pageSize, projectId, name, qName, sort
    }
  })
}
// 查询镜像列表总数
export function getImageListCount(projectId) {
  return request({
    url: harbor + `/v1/image/ordinary/imageCount`,
    method: 'get',
    params: {
      projectId
    }
  })
}

// harbor查询用户信息
export function getHarborUserInfo() {
  return request({
    url: harbor + '/v1/harborUser/userInfo',
    method: 'get'
  })
}
// 修改harbor用户密码
export function updateHarborUserPassword(data) {
  return request({
    url: harbor + '/v1/harborUser/updatePassword',
    method: 'put',
    data
  })
}
// 根据镜像名称删除镜像
export function deleteImage(name) {
  return request({
    url: harbor + '/v1/image/ordinary/image',
    method: 'delete',
    params: {
      name
    }
  })
}
// 获取手机验证码
export function getPhoneVerificationCode() {
  return request({
    url: 'service-harbor-provider/v1/harborUser/getPhoneVerificationCode',
    method: 'get'
  })
}
// 客户端创建命名空间
export function createNamespace(data) {
  return request({
    url: harbor + '/v1/namespace/client/create',
    method: 'post',
    data
  })
}
// 根据镜像名称查询版本信息
export function getArtifactVersions(pageNum, pageSize, name) {
  return request({
    url: harbor + '/v1/image/ordinary/artifacts',
    method: 'get',
    params: {
      pageNum,
      pageSize,
      name
    }
  })
}
// 根据命名空间查询所有镜像
export function getAllImageByNamespace(name) {
  return request({
    url: harbor + '/v1/image/all/image',
    method: 'get',
    params: {
      name
    }
  })
}
// /v1/image/all/artifacts
export function getAllArtifactByNamespace(imageName, artifactsName) {
  return request({
    url: harbor + '/v1/image/all/artifacts',
    method: 'get',
    params: {
      artifactsName, imageName
    }
  })
}
// /v1/devlopmentenv/details/list
// 获取Pod中EVENT详情列表
export function getPodEventList(envName, projectId, pagenum, pagesize, orderBy, sortBy) {
  return request({
    url: k8s + '/devlopmentenv/details/list',
    method: 'get',
    params: {
      envName, pagenum, pagesize, projectId, orderBy, sortBy
    }
  })
}
// 客户端查询所有的命名空间
// /v1/namespace/client/all
export function getAllNameSpace() {
  return request({
    url: harbor + '/v1/namespace/client/all',
    method: 'get'
  })
}

/** 模型 */
// 查询模型格式
export function getModelFormat() {
  return request({
    url: `${k8s}/model/modelFormatPop`,
    method: 'get'
  })
}
// 上传模型
export function uploadModelFile(data, params) {
  return request({
    url: `${k8s}/model`,
    method: 'post',
    data,
    params
  })
}
// 删除模型
export function deleteModel(id) {
  return request({
    url: `${k8s}/model?id=${id}`,
    method: 'delete'
  })
}
// 修改模型
export function updateModel(data, params) {
  return request({
    url: `${k8s}/model`,
    method: 'PATCH',
    data,
    params
  })
}
// 查询模型
export function getModelList(pagenum, pagesize, fuzzyQuery, projectGroupId, openState) {
  return request({
    url: `${k8s}/model`,
    method: 'get',
    params: {
      pagenum,
      pagesize,
      fuzzyQuery,
      projectGroupId,
      openState
    }
  })
}
// 模型发布列表
export function getModelPublishList(pagenum, pagesize, sortBy, orderBy, keyWord, status) {
  return request({
    url: `${k8s}/modelPublish/client`,
    method: 'get',
    params: {
      pagenum,
      pagesize,
      sortBy,
      orderBy,
      keyWord,
      status
    }
  })
}
// 删除模型发布
export function deleteModelPublish(id) {
  return request({
    url: `${k8s}/modelPublish`,
    method: 'delete',
    params: {
      id
    }
  })
}
// 发布/停止模型
export function publishModel(modelPublishId, status) {
  return request({
    url: `${k8s}/modelPublish/startOrStop`,
    method: 'put',
    data: {
      modelPublishId,
      status
    }
  })
}
// 修改副本数
export function updateModelReplicas(id, replicaCount) {
  return request({
    url: `${k8s}/modelPublish/replicaCount`,
    method: 'patch',
    params: {
      id,
      replicaCount
    }
  })
}
// 查询模型版本
export function getModelVersion(modelId) {
  return request({
    url: `${k8s}/model/selectVersionPop`,
    params: {
      modelId
    }
  })
}
// 发布模型
export function publishModelVersion(data) {
  return request({
    url: `${k8s}/modelPublish/create`,
    method: 'post',
    data
  })
}
// 查询模型详情
export function getModelDetail(id) {
  return request({
    url: `${k8s}/model/selectModelById`,
    method: 'get',
    params: {
      id
    }
  })
}
// /v1/model/patchOpenState
// 修改模型开放状态
export function patchOpenState(id, openState) {
  return request({
    url: `${k8s}/model/patchOpenState`,
    method: 'patch',
    params: {
      id,
      openState
    }
  })
}
// 获取文件夹列表
export function filesList(modelId, modelVersionName, dirPath) {
  return request({
    url: `${k8s}/modelFile/getDirsAndFileList`,
    method: 'get',
    params: {
      modelId,
      modelVersionName,
      dirPath
    }
  })
}
// /v1/modelFile/deleteFileOrDir
// 删除文件或文件夹
export function deleteFileOrDir(params) {
  return request({
    url: `${k8s}/modelFile/deleteFileOrDir`,
    method: 'delete',
    params
  })
}
// 创建文件夹
// /v1/modelFile/createDirs
export function createDirs(data) {
  return request({
    url: `${k8s}/modelFile/createDirs`,
    method: 'post',
    data
  })
}
// 上传文件并文件夹
export function uploadFile(data, params) {
  return request({
    url: `${k8s}/modelFile/createDirsAndUploadFile`,
    method: 'post',
    data,
    params
  })
}
// 重名名
// /v1/modelFile/renameFileOrDir
export function renameFileOrDir(data) {
  return request({
    url: `${k8s}/modelFile/renameFileOrDir`,
    method: 'put',
    data
  })
}
// /v1/modelPublish/detail
// 模型发布详情
export function getModelPublishDetail(data) {
  return request({
    url: `${k8s}/modelPublish/detail`,
    method: 'get',
    params: data
  })
}
// /v1/modelPublish/pods
// 获取模型发布环境pod
export function getModelPublishPod(name) {
  return request({
    url: `${k8s}/modelPublish/pods`,
    method: 'get',
    params: {
      name
    }
  })
}
// /v1/modelPublish/resource/usage/hist
// 获取模型发布资源利用率
export function getModelPublishSrcDeatail(name) {
  return request({
    url: `${k8s}/modelPublish/resource/usage/hist`,
    method: 'get',
    params: {
      name
    }
  })
}
// /v1/modelPublish/event/list
// 获取模型发布事件列表
export function getModelPublishEventList(name, pagenum, pagesize, orderBy, sortBy) {
  return request({
    url: `${k8s}/modelPublish/event/list`,
    method: 'get',
    params: {
      name,
      pagenum,
      pagesize,
      orderBy,
      sortBy
    }
  })
}
// 根据模型发布id获取端口
export function getModelPublishServicePorts(id) {
  return request({
    url: `${k8s}/modelPublish/queryServicePorts`,
    method: 'get',
    params: {
      id
    }
  })
}
// /v1/modelPublish/selectModelPublishApiById
// 根据模型发布id获取模型发布详细信息API
export function getModelPublishApiList(modelPublishId, targetPort) {
  return request({
    url: `${k8s}/modelPublish/selectModelPublishApiById`,
    method: 'get',
    params: {
      modelPublishId, targetPort
    }
  })
}
// /v1/modelPublish/queryApiReq
// 根据模型发布id和端口获取API请求信息
export function getModelPublishApiReq(path) {
  return request({
    url: `${k8s}/modelPublish/queryApiReq`,
    method: 'get',
    params: {
      path
    }
  })
}
// /v1/modelPublish/createModelPublishApi
// 创建模型发布API
export function createModelPublishApi(data) {
  return request({
    url: `${k8s}/modelPublish/createModelPublishApi`,
    method: 'post',
    data
  })
}
// /v1/modelPublish/patchModelPublishApi
// 修改模型发布API
export function patchModelPublishApi(data) {
  return request({
    url: `${k8s}/modelPublish/patchModelPublishApi`,
    method: 'patch',
    data
  })
}
// /v1/model/createModelVersion
// 创建模型版本
export function createModelVersion(data) {
  return request({
    url: `${k8s}/model/createModelVersion`,
    method: 'post',
    data
  })
}
// 选择模型弹窗
// /v1/model/selectModelPop

export function selectModelPop(pagenum,
  pagesize,
  fuzzyQuery,
  openState) {
  return request({
    url: `${k8s}/model/selectModelPop`,
    method: 'get',
    params: {
      pagenum,
      pagesize,
      fuzzyQuery,
      openState
    }
  })
}
// /v1/model/selectShareById
// 根据模型id获取共享信息
export function getModelShareInfo(id) {
  return request({
    url: `${k8s}/model/selectShareById`,
    method: 'get',
    params: {
      id
    }
  })
}
// 计算文件夹大小
export function calculateFileSize(modelId, modelVersionName) {
  return request({
    url: `${k8s}/modelFile/calculateDirectorySize`,
    method: 'get',
    params: {
      modelId,
      modelVersionName
    }
  })
}
// /v1/model/patchModelByName
// 修改模型名称
export function patchModelByName(id, name) {
  return request({
    url: `${k8s}/model/patchModelByName`,
    method: 'patch',
    params: {
      id,
      name
    }
  })
}
// /v1/model/deleteModelVersion
// 删除模型版本
export function deleteModelVersion(id, modelId) {
  return request({
    url: `${k8s}/model/deleteModelVersion`,
    method: 'delete',
    params: {
      id,
      modelId
    }
  })
}
// 查询文件或文件夹是否存在

export function queryFileOrDirExist(data) {
  return request({
    url: `${k8s}/modelFile/queryFileOrDirExist`,
    method: 'get',
    params: data
  })
}
// 查看端口是否可用
export function checkPortAvailable(ip, port) {
  return request({
    url: `${k8s}/devlopmentenv/portCheckExist`,
    method: 'get',
    params: {
      port,
      ip
    }
  })
}
// 生产随机密码
export function generateRandomPassword() {
  return request({
    url: harbor + '/v1/harborUser/randomPassword',
    method: 'post'
  })
}
