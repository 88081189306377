<template>
  <div class="choice-file">
    <div class="dialog-container">
      <div v-loading="loadingDialog" class="choice-dialog-content">
        <div class="left">
          <el-tree
            ref="tree"
            :data="data"
            :load="loadNode"
            lazy
            node-key="relativePath"
            :props="defaultProps"
            icon-class="el-icon-arrow-right"
            @node-click="handleNodeClick"
            @node-contextmenu="dirsRightClick"
          >
            <template #default="{ node }">
              <span class="custom-tree-node">
                <svg-icon v-if="node.data.type === 'dir'" icon-class="files" style="margin: 0 12px" />
                <svg-icon v-else-if="node.data.type === 'cmd'" icon-class="cmd" style="margin: 0 12px" />
                <svg-icon v-else-if="node.data.type === 'md'" icon-class="md" style="margin: 0 12px" />
                <svg-icon v-else-if="node.data.type === 'text'" icon-class="txt" style="margin: 0 12px" />
                <svg-icon v-else-if="node.data.type === 'zip'" icon-class="zip" style="margin: 0 12px" />
                <svg-icon v-else-if="node.data.type === 'image'" icon-class="img" style="margin: 0 12px" />
                <svg-icon v-else icon-class="other" />
                <span>{{ node.label }}</span>
              </span>
            </template>
          </el-tree>
          <teleport to="body">
            <!-- 右键 左侧文件夹 -->
            <div v-show="dirsMenuVisible" id="dirsContextmenu" class="menu">
              <div v-if="rightType === 'dir'" class="contextmenu-item" @click="createRight(currentContextMenu)">
                新建文件夹
              </div>
              <div class="contextmenu-item" @click="deleteRight(currentContextMenu)">
                删除
              </div>
              <div class="contextmenu-item" @click="renameRight(currentContextMenu)">
                重命名
              </div>
              <div v-if="rightType !== 'dir'" class="contextmenu-item" @click="downloadRight(currentContextMenu)">
                下载
              </div>
            </div>
          </teleport>
        </div>
      </div>
    </div>
    <div class="create-file">
      <el-dialog v-model="fileNameDialogVisible" :title="fileNameDialogTitle" width="500px" :close-on-click-modal="false" :before-close="handleFileCreateClose">
        <el-form ref="fileNameForm" :model="form" label-width="100px" :rules="rules">
          <el-form-item label="文件名" prop="name">
            <el-input v-model="form.name" placeholder="请输入文件名" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="handleFileCreate">确 定</el-button>
            <el-button @click="handleFileCreateClose">取 消</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  filesList,
  deleteFileOrDir,
  createDirs,
  renameFileOrDir
} from '@/api/ai'
export default {
  name: 'AiChoiceFile',
  props: ['type', 'modelId', 'modelVersionName'],
  data() {
    return {
      defaultExpandedKeys: [],
      currentRow: -1,
      loadingDialog: false,
      filesData: [],
      data: [],
      path: '',
      defaultProps: {
        children: 'childDir',
        label: 'name',
        isLeaf: 'leaf'
      },
      choiceType: 'dir',
      directory: '',
      choiceDir: '',
      choiceFile: '',
      dirsMenuVisible: false,
      currentContextMenu: null,
      row: {},
      showInput: false,
      isEnter: false,
      isBlur: false,
      form: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入文件名', trigger: 'blur' }
        ]
      },
      fileNameDialogVisible: false,
      newCreateDir: '',
      fileNameDialogTitle: '新建文件夹',
      rightType: ''
    }
  },
  watch: {
    modelVersionName() {
      this.refreshTreeNode('')
    }
  },
  methods: {
    // 递归列表最后一级为空
    getTreeData(data, child) {
      if (!data) {
        return []
      }
      for (var i = 0; i < data.length; i++) {
        if (data[i].type !== 'dir') {
          data[i].leaf = true
        } else {
          if (data[i].childDir === undefined) {
          // children若为空数组，则将children设为undefined
            data[i].childDir = []
          } else {
          // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].childDir)
          }
        }
      }
      return data
    },
    // 数组去重
    unique(arr) {
      const res = new Map()
      return arr.filter((arr) => !res.has(arr) && res.set(arr, 1))
    },
    // loadNode 加载tree节点
    loadNode(node, resolve) {
      this.loadingDialog = true
      if (node.data.relativePath !== undefined) {
        this.path = node.data.relativePath
      }
      filesList(this.modelId, this.modelVersionName, this.path).then((response) => {
        if (node.level === 0) {
          this.loadingDialog = false
          return resolve([
            {
              name: '根目录',
              relativePath: '',
              childDir: [],
              type: 'dir'
            }
          ])
        }
        var list = []
        node.childNodes = []
        response.data.forEach((e) => {
          list.push(e)
        })
        list = this.unique(list)
        if (resolve) {
          const nodeList = this.getTreeData(list)
          resolve(nodeList)// 动态加载时
          this.loadingDialog = false
        } else {
          // 更新节点时：
          node.childNodes = []
          node.doCreateChildren(this.getTreeData(list))
          this.loadingDialog = false
        }
      })
    },
    // 点击节点事件
    handleNodeClick(data) {
      this.dirsMenuVisible = false
      this.path = data.relativePath
      this.choiceDir = this.path
      this.$nextTick(() => {
        if (data.type === this.type) {
          this.$emit('confirmDirs', this.choiceDir, this.choiceType)
        }
      })
    },
    // 树形控件 文件夹右键
    dirsRightClick(MouseEvent, object, Node, element) {
      this.rightType = object.type
      this.menuVisible = false
      this.dirsMenuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.dirsMenuVisible = true // 显示模态窗口，跳出自定义菜单栏
      MouseEvent.preventDefault() // 关闭浏览器右键默认事件
      this.currentContextMenu = object
      this.path = object.relativePath || ''
      var menu = document.querySelector('.menu')
      this.dirsStyleMenu(menu, MouseEvent)
    },
    dirsFoo() {
      // 取消鼠标监听事件 菜单栏
      this.dirsMenuVisible = false
      window.removeEventListener('click', this.dirsFoo) // 关掉监听，
    },
    dirsStyleMenu(menu, event) {
      menu.style.left = event.clientX + 'px'
      menu.style.top = event.clientY + 'px'
      console.log(event.clientX, event.clientY)
      window.addEventListener('click', this.dirsFoo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
    },
    // 刷新节点
    refreshTreeNode(path) {
      const node = this.$refs.tree.getNode(path)
      if (node) {
        node.loaded = false
        node.expand() // 主动调用展开节点方法，重新查询该节点下的所有子节点
      }
    },
    // 删除
    deleteRight(currentContextMenu) {
      if (!currentContextMenu.relativePath) {
        this.$message.error('不能选择根目录！')
        return
      }
      this.deleteFileOrDir()
    },
    // 新建文件夹
    deleteFileOrDir() {
      const data = {
        modelId: this.modelId,
        modelVersionName: this.modelVersionName,
        dirPath: this.currentContextMenu.relativePath
      }
      this.loadingDialog = true
      deleteFileOrDir(data).then((response) => {
        this.path = this.path.substring(0, this.path.lastIndexOf('/'))
        this.refreshTreeNode(this.path)
      }).finally(() => {
        this.loadingDialog = false
      })
    },
    handleNewDir() {
      this.inputType = 'fileInput'
      this.row = { name: '' }
      this.row.name.replace(/\s*/g, '')
      this.filesData.unshift(this.row)
      this.showInput = true
      this.isBlur = true
    },
    // 新建
    createRight(currentContextMenu) {
      this.fileNameDialogVisible = true
      this.fileNameDialogTitle = '新建文件夹'
      this.form.name = ''
    },
    renameRight(currentContextMenu) {
      this.fileNameDialogVisible = true
      this.fileNameDialogTitle = '重命名'
      this.form.name = currentContextMenu.name.substring(currentContextMenu.name.lastIndexOf('/') + 1)
    },
    downloadRight(currentContextMenu) {
      const links = 'http://' + location.host + '/ai-api/' + 'service-kubernetes-provider/v1/modelFile/downloadFile?modelId=' + this.modelId + '&modelVersionName=' + this.modelVersionName + '&dirPath=' + currentContextMenu.relativePath
      const a = document.createElement('a')
      a.href = links
      a.download = currentContextMenu.name
      document.body.appendChild(a) // 确保链接在 DOM 中
      a.click()
      // 下载后移除该链接
      document.body.removeChild(a)
    },
    handleFileCreateClose() {
      this.fileNameDialogVisible = false
    },
    handleFileCreate() {
      this.$refs.fileNameForm.validate((valid) => {
        if (valid) {
          if (this.fileNameDialogTitle === '新建文件夹') {
            const data = {
              modelId: this.modelId,
              modelVersionName: this.modelVersionName,
              dirPath: this.path ? this.path + '/' + this.form.name : this.form.name
            }
            createDirs(data).then((response) => {
              this.handleFileCreateClose()
              this.refreshTreeNode(this.path)
            })
          } else if (this.fileNameDialogTitle === '重命名') {
            const data = {
              modelId: this.modelId,
              modelVersionName: this.modelVersionName,
              dirPath: this.path,
              newFileName: this.form.name
            }
            // 重命名
            renameFileOrDir(data).then((response) => {
              const path = this.path.substring(0, this.path.lastIndexOf('/'))
              if (response.meta.status === 200) {
                // 如果重命名的不是一级目录则需要拼接父级目录 + 新文件名
                if (path) {
                  this.path = path + '/' + this.form.name
                } else {
                  // 否则直接赋值
                  this.path = this.form.name
                }
                // 去父级目录
                this.handleFileCreateClose()
                this.refreshTreeNode(path)
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.contextmenu-item {
    display: block;
    line-height: 30px;
    text-align: left;
    padding-left: 10px;
  }
  .contextmenu-item:not(:last-child) {
    border-bottom: 1px solid #b2c2cf;
  }
  .menu {
    position: absolute;
    background-color: #fff;
    width: 160px;
    /*height: 106px;*/
    font-size: 12px;
    color: #313a46;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #b2c2cf;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    white-space: nowrap;
    z-index: 10000;
  }
  .contextmenu-item:hover {
    cursor: pointer;
    background: #eff1f7;
    border-color: #eff1f7;
    color: #313a46;
  }
.dialog-container{
  ::v-deep .el-dialog__footer {
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: 99;
    width: calc(100% - 230px);
    background-color: #fff;
    border-bottom-right-radius: 20px;
  }
  ::v-deep .el-dialog__body{
        padding: 0;
      }
  }

    ::v-deep .el-button--primary{
      padding: 9px 45px;
      color: #fff;
     }
    ::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-icon{
      color: #333;
    }
    ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
  ::v-deep .el-dialog{
    max-height: 600px;
    border-radius: 20px;
    .el-dialog__body{
      max-height: 600px;
    }
  }
  .create-file{
    ::v-deep .el-dialog__body{
      padding:20px 45px 10px 45px
    }
  }
  .choice-dialog-content{
    display: flex;
    font-size: 12px;
    .left {
      overflow: auto;
      width: 100%;
      height: 480px;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      padding: 20px;
      color: #313a46;
      ::v-deep .el-tree-node.is-expanded > .el-tree-node__children {
        display: inline;
      }
      .dirsFileMenu {
        height: 130px;
      }
    }
  }
</style>
