import request from '@/utils/requestEhpc'
const slurm = '/service-slurm-provider/v1'
const queue = '/service-queue-provider/v1'

// 文件列表
export function filesList(path, hidden) {
  return request({
    url: slurm + '/files',
    method: 'post',
    data: {
      path: path,
      hidden: hidden
    }
  })
}
// 压缩文件
export function compressFiles(data) {
  return request({
    url: slurm + '/files/compress',
    method: 'post',
    data
  })
}
// 解压文件
export function decompressFiles(data) {
  return request({
    url: slurm + '/files/decompress',
    method: 'post',
    data
  })
}
// 下载文件 返回链接
export function fileLinkDownload(path) {
  return request({
    url: slurm + '/files/donwloadEncrypt',
    method: 'post',
    data: {
      path: path
    }
  })
}
// 用链接 下载
// export function byLinkDownload(path) {
//   return axios({
//     url: '/download' + slurm + '/admin/files/donwload/file?path=' + path,
//     method: 'get'
//   })
// }
// 下载文件
export function filesDownload(path) {
  return request({
    url: slurm + '/files/download',
    responseType: 'blob',
    method: 'post',
    data: {
      path: path
    }
  })
}
// 创建文件夹
export function addFiles(path) {
  return request({
    url: slurm + '/files/dirs',
    method: 'post',
    data: {
      path: path
    }
  })
}
// 创建文件
export function addFile(path, name) {
  return request({
    url: slurm + '/files/create',
    method: 'post',
    data: {
      fileName: name,
      path: path
    }
  })
}
// 上传文件
export function uploadFile(path, file) {
  return request({
    url: slurm + '/files/upload?path=' + path,
    method: 'post',
    data: file
  })
}
// 删除文件
export function deleteFile(path) {
  return request({
    url: slurm + '/files',
    method: 'delete',
    data: {
      path: path
    }
  })
}
// 移动重命名
export function moveOrRenameFiles(dest, src) {
  return request({
    url: slurm + '/files/move',
    method: 'patch',
    data: {
      dest: dest,
      src: src
    }
  })
}
// 复制文件
export function copyFiles(dest, src) {
  return request({
    url: slurm + '/files/copy',
    method: 'patch',
    data: {
      dest: dest,
      src: src
    }
  })
}
// 遍历目录
export function dirsList(path, hidden) {
  return request({
    url: slurm + '/files/list',
    method: 'post',
    data: {
      path: path,
      hidden: hidden
    }
  })
}
// 遍历队列信息
export function queueList() {
  return request({
    url: queue + '/queue/',
    method: 'get'
  })
}
// 遍历个人队列信息
export function queuePersonList(uid) {
  return request({
    url: queue + '/queue/person?uid=' + uid,
    method: 'get'
  })
}
export function addJobs(data) {
  return request({
    url: slurm + '/jobs',
    method: 'post',
    data
  })
}

// 断点续传
// 获取分片上传列表
export function getChunkedList() {
  return request({
    url: slurm + '/files/chunked',
    method: 'get'
  })
}
// 分片上传
export function uploadChunked(obj, file) {
  return request({
    url: slurm + '/files/chunked',
    method: 'post',
    params: obj,
    data: file
  })
}
// 删除分片上传
export function deleteChunked(data) {
  return request({
    url: slurm + '/files/chunked',
    method: 'delete',
    params: data
  })
}
