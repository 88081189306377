<template>
  <div class="log-container">
    <div class="tab">
      <!--  -->
      <el-radio-group v-model="logRadio" @change="changeLogRadio">
        <el-radio label="1">
          查看倒数
          <el-input v-model="logSearchInput" @keydown.enter="keydownHandel" @input="inputChange" />
          行
        </el-radio>
        <el-radio label="2">查看全部</el-radio>
      </el-radio-group>
    </div>
    <div id="logContent" ref="logContent" style="width:100%;height:calc(100% - 40px);" />
  </div>
</template>

<script>
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import 'xterm/css/xterm.css'
import 'xterm/lib/xterm.js'
import { ElMessage } from 'element-plus'
import elementResizeDetectorMaker from 'element-resize-detector'
export default {
  data() {
    return {
      baseUrl: 'wss://192.168.132.234:9090/slurm/log?path=',
      init: true,
      token: '',
      style: {
        height: '',
        width: ''
      },
      windowHeight: '',
      windowWidth: '',
      fitAddon: '',
      logRadio: '1',
      logSearchInput: 500
    }
  },
  mounted() {
    const that = this
    const elementResizeDetectorMaker = require('element-resize-detector')// 导入
    // 创建实例
    const erd = elementResizeDetectorMaker()
    // 创建实例带参
    const erdUltraFast = elementResizeDetectorMaker({
      strategy: 'scroll', // <- For ultra performance.
      callOnAdd: true,
      debug: true
    })
    // 监听id为test的元素 大小变化
    const i = document.getElementsByClassName('log-container').length - 1
    erd.listenTo(document.getElementsByClassName('log-container')[i], function(element) {
      try {
        setTimeout(() => {
          if (that.fitAddon !== '') {
            that.fitAddon.fit()
          }
        }, 100)
      } catch (error) {
        console.log(error)
      }
    })
    // 调查询日志接口
    this.$nextTick(() => {
      if (this.init) {
        this.initXterm()
        this.init = false
        setTimeout(() => {
          that.fitAddon.fit()
        }, 500)
      }
    })
    // this.logs = 'Welcome to use Superman. ' + new Date()
    this.getLogs()
  },
  beforeUnmount() {
    this.clear()
  },
  methods: {
    initXterm() {
      // const cols = document.getElementsByClassName('window-body')[1].offsetWidth / 9;
      this.term = new Terminal({
        // 渲染类型
        rendererType: 'canvas',
        // cols: parseInt(cols),
        convertEol: true,
        cursorStyle: 'bar',
        // 终端中的回滚量
        scrollback: 1000000,
        disableStdin: true,
        theme: {
          foreground: '#00ff00',
          background: '#060101',
          cursor: 'help'
        }
      })
      const fitAddon = new FitAddon()
      this.fitAddon = fitAddon
      this.term.loadAddon(fitAddon)
      this.term.open(this.$refs.logContent)
      const that = this
      setTimeout(() => {
        fitAddon.fit()
        that.fitAddon = fitAddon
      }, 500)
      this.term.focus()
      this.term.attachCustomKeyEventHandler(e => {
        // console.log('listen press key ' + e.keyCode)
        if (e.ctrlKey && e.keyCode === 76) {
          this.term.clear()
          fitAddon.fit()
        }
      })
    },
    getLogs() {
      this.token = sessionStorage.desktopToken
      var ishttps = document.location.protocol == 'https:'
      let worhDir = localStorage.workDir
      // 将worhDir转为base64
      const idJob = localStorage.idJob
      // worhDir = worhDir + '/slurm-' + idJob + '.out'
      worhDir = encodeURIComponent(worhDir + '/slurm-' + idJob + '.out')
      console.log(worhDir)
      let curl = ''
      if (this.logRadio === '1') {
        curl = location.host + '/agent/' + localStorage.regionName + '/slurm/log?linesSize=' + this.logSearchInput + '&path='
      } else {
        curl = location.host + '/agent/' + localStorage.regionName + '/slurm/log?linesSize=0&path='
      }
      if (ishttps) {
        this.baseUrl = 'wss:' + curl
      } else {
        this.baseUrl = 'ws:' + curl
      }
      const url =
        this.baseUrl + worhDir + '&token=' + this.token
      this.ws = new WebSocket(url)
      this.ws.onopen = () => {
        console.log('open ws success')
      }
      this.ws.onmessage = (res) => {
        this.term.write(res.data)
      }
      this.ws.onerror = () => {
        console.log('failed to open ws')
      }
    },
    changeLogRadio(e) {
      this.clear()
      this.getLogs()
    },
    clear() {
      this.ws.close()
      this.ws = {}
      this.term.reset()
      this.row = ''
    },
    inputChange(e) {
      this.logSearchInput = e.replace(/[^\d]/g, '')
      if (Number(this.logSearchInput) > 10000) {
        this.logSearchInput = '10000'
      }
    },
    keydownHandel() {
      if (!this.logSearchInput || this.logSearchInput == 0) {
        this.logSearchInput = 1
      }
      this.logRadio = '1'
      this.changeLogRadio()
    }
  }
}
</script>

<style lang="less" scoped>
.log-container {
  width: 100%;
  height: 100%;
  background-color: #000;
  .tab {
    height: 36px;
    width: 100%;
    background-color: #F5F5F5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
    .el-input {
      width: 80px;

      ::v-deep .el-input__inner {
        height: 24px;
        line-height: 24px;
      }
    }
  }
  :deep(::-webkit-scrollbar-thumb) {
    border-radius: 10px;
    background: rgba(248, 248, 248, 0.5) !important;
  }
}
</style>
